/* eslint-disable no-mixed-spaces-and-tabs */
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';
import { useMSALHelper } from './useMSALHelper';

const GRAPH_URL = 'https://graph.microsoft.com/v1.0/';
const msGraphAxios = axios.create({ baseURL: GRAPH_URL });

const MAX_SIMPLE_UPLOAD_SIZE = 4 * 1024 * 1024; // 4MB

// Custom hook to interact with Microsoft graph
export const useMsGraph = (userId) => {
	// Using MSAL hook to get auth instance and accounts
	const { instance } = useMsal();
	const account = useSelector((state) => state.msalAccount.account);

	const endpointPrefix = userId ? `users/${userId}` : '/me';

	const { getAccessToken } = useMSALHelper();

	const scopes = [
		'User.Read',
		'Calendars.ReadWrite',
		'OnlineMeetings.ReadWrite',
		'Calendars.Read',
		'User.Read.All',
		'Files.ReadWrite.All', //'Files.Read.All', 'Files.ReadWrite',
	];

	const getGraphAccessToken = async () => {
		return await getAccessToken(scopes);
		// 	const currentAccount = instance.getAccountByHomeId(account.homeAccountId);
		// 	const request = {
		// 		scopes,
		// 		account: currentAccount,
		// 	};
		// 	return await instance
		// 		.acquireTokenSilent(request)
		// 		.then((response) => {
		// 			console.log('🔓 ~ Access token retrieved silently');
		// 			return response.accessToken;
		// 		})
		// 		.catch(async (error) => {
		// 			console.error('🙅 ~ Error retrieving access token silently', error);
		// 			// Redirect user to login screen if there is an issue getting access token

		//             return instance
		// 				.acquireTokenRedirect(request)
		// 				.then((response) => {
		// 					console.log('🔓 ~ Access token retrieved via redirect');
		// 					return response.accessToken;
		// 				})
		// 				.catch((e) => {
		// 					console.error('🙅 ~ Token redirect error', e);
		// 					return Promise.reject(e);
		// 				});
		// 		});
	};

	const createOnlineMeetingEvent = async (eventDetails) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.post(`${endpointPrefix}/events`, eventDetails, {
				headers,
			});
			console.log('🚀 ~ Event created successfully', response.data);
			return response.data;
		} catch (error) {
			console.error('🙅 ~ Error creating event', error);
			throw error;
		}
	};

	const getGraphEventData = async (taskType, graphEventId) => {
		let endpoint;
		if (taskType === 'MEETING') {
			endpoint = `${endpointPrefix}/calendar/events/${graphEventId}`;
		} else {
			endpoint = `${endpointPrefix}/events/${graphEventId}`;
		}

		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			const response = await msGraphAxios.get(endpoint, { headers });
			return response.data;
		} catch (error) {
			console.error('❌ ~ Error fetching graph event data:', error);
			throw error;
		}
	};

	const updateGraphEventData = async (taskType, graphEventId, eventData) => {
		let endpoint;
		if (taskType === 'MEETING') {
			endpoint = `${endpointPrefix}/calendar/events/${graphEventId}`;
		} else {
			endpoint = `${endpointPrefix}/events/${graphEventId}`;
		}

		console.log(
			`Params for updateGraphEventData for graph ID ${graphEventId} event type ${taskType}`,
			eventData
		);
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.patch(endpoint, eventData, { headers });
			console.log(
				`RESPONSE for updateGraphEventData for graph ID ${graphEventId} event type ${taskType}`,
				response.data
			);
			return response.data;
		} catch (error) {
			console.error('❌ ~ Error updating graph event data:', error);
			throw error;
		}
	};

	const createEventTask = async (eventDetails) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.post(
				`${endpointPrefix}/calendar/events`,
				eventDetails,
				{
					headers,
				}
			);
			console.log('🚀 ~ Event created successfully', response.data);
			return response.data;
		} catch (error) {
			console.error('🙅 ~ Error creating event', error);
			throw error;
		}
	};

	const getUserCalendars = async (email) => {
		const userIdFromEmail = await getUserIdByEmail(email);
		const accessToken = await getGraphAccessToken();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
			'Content-Type': 'application/json',
		};
		try {
			const response = await msGraphAxios.get(`/users/${userIdFromEmail}/calendars`, {
				headers,
			});
			console.log('✅ ~ Calendars retrieved successfully:', response.data.value);
			return response.data.value;
		} catch (error) {
			console.error('❌ ~ Error retrieving calendars:', error);
			throw error;
		}
	};

	const getUserIdByEmail = async (email) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.get(`/users/${email}`, { headers });
			console.log('✅ ~ User retrieved successfully', response.data);
			return response.data.id;
		} catch (error) {
			console.error('❌ ~ Error retrieving user', error);
			throw error;
		}
	};

	const getDriveItem = async (driveId, itemId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.get(`/drives/${driveId}/items/${itemId}`, {
				headers,
			});
			console.log('✅ ~ [getDriveItem] retrieved successfully', response.data);
			return response.data;
		} catch (error) {
			console.error('❌ ~ [getDriveItem] Error retrieving item', error);
			throw error;
		}
	};

	const getSharepointListItemChildren = async (siteId, listId, itemId) => {
		try {
			const items = await getSharepointListItems(siteId, listId);
			const parent = items.find((i) => i.id === itemId);
			const filteredList = items.filter((i) => i.webUrl.startsWith(parent.webUrl));
			console.log('✅ ~ ITEMS', filteredList);
			return filteredList;
		} catch (error) {
			console.error('❌ ~ Error retrieving SharePoint list items', error);
			throw error;
		}
	};

	// 	const list = items.find(l => decodeURI(l.fields.FileLeafRef.normalize()) == decodeURI(replacedCompanyName.normalize()));
	const getSharepointListItems = async (siteId, listId, filterFunction) => {
		let items = [];
		let nextPageUrl = `/sites/${siteId}/lists/${listId}/items?expand=fields`;
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			while (nextPageUrl) {
				// Fetch the list items and expand fields to get custom metadata
				const response = await msGraphAxios.get(nextPageUrl, { headers });
				const { value, '@odata.nextLink': nextLink } = response.data;

				items = [...items, ...value];
				if (filterFunction) {
					console.log('ITEMS prior to filtering', items);
					const result = filterFunction(items);
					console.log('FILTER LIST', result);
					if (result) {
						items = [result];
						nextPageUrl = null;
						break;
					}
				}
				nextPageUrl = nextLink;
			}
			console.log('✅ ~ SharePoint list items with metadata:', items);
			return items;
		} catch (error) {
			console.error('❌ ~ Error retrieving SharePoint list items', error);
			throw error;
		}
	};

	const getSharepointLists = async (siteId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			// Fetch the list items and expand fields to get custom metadata
			const response = await msGraphAxios.get(`/sites/${siteId}/lists`, { headers });
			console.log('✅ ~ SharePoint lists:', response.data?.value);
			return response.data?.value;
		} catch (error) {
			console.error('❌ ~ Error retrieving SharePoint list items', error);
			throw error;
		}
	};

	const searchClientMsGraphFolders = async (driveId, searchText) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			// /drives/{drive-id}/root/search(q='{search-text}')
			const response = await msGraphAxios.get(
				`/drives/${driveId}/root/search(q='${decodeURI(searchText.normalize())}')`,
				{
					headers,
				}
			);
			console.log(
				'✅ ~ Client Folders searched successfully',
				response.data,
				{
					driveId,
					searchText,
					decodedSearchText: decodeURI(searchText.normalize()),
				}
			);
			return response.data?.value;
		} catch (error) {
			console.error('❌ ~ Error searching client folders', error);
			throw error;
		}
	};

	const searchClientMsGraphFoldersByItemId = async (driveId, itemId, searchText) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			// /drives/{drive-id}/root/search(q='{search-text}')
			const response = await msGraphAxios.get(
				`/drives/${driveId}/items/${itemId}/search(q='${searchText}')`,
				{
					headers,
				}
			);
			console.log(
				'✅ ~ [searchClientMsGraphFoldersByItemId]',
				response.data,
				{
					driveId,
					itemId,
					searchText,
				}
			);
			return response.data?.value;
		} catch (error) {
			console.error('❌ ~ Error searchClientMsGraphFoldersByItemId', error);
			throw error;
		}
	};

	const getClientMsGraphFolders = async (driveId, itemId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.get(`/drives/${driveId}/items/${itemId}/children`, {
				headers,
			});
			console.log('✅ ~ [getClientMsGraphFolders] success', response.data, driveId, itemId);
			return response.data?.value;
		} catch (error) {
			console.error('❌ ~ Error retrieving client folders', error);
			throw error;
		}
	};

	const getClientFolderDrive = async (driveId, filterFunction) => {
		let items = [];
		let nextPageUrl = `/drives/${driveId}/root/children`;
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			while (nextPageUrl) {
				// Fetch the list items and expand fields to get custom metadata
				const response = await msGraphAxios.get(nextPageUrl, { headers });
				const { value, '@odata.nextLink': nextLink } = response.data;

				items = [...items, ...value];
				if (filterFunction) {
					const result = filterFunction(items);
					console.log('FILTER LIST', result);
					if (result) {
						items = [result];
						nextPageUrl = null;
						break;
					}
				}
				nextPageUrl = nextLink;
			}
			console.log('✅ ~ [getClientFolderDrive] items', items);
			return items;
		} catch (error) {
			console.error('❌ ~Error retrieving SharePoint list items', error);
			throw error;
		}
		// try {
		// 	const accessToken = await getGraphAccessToken();
		// 	const headers = {
		// 		Authorization: `Bearer ${accessToken}`,
		// 		'Content-Type': 'application/json',
		// 	};
		// 	const response = await msGraphAxios.get(`/drives/${driveId}/root/children`, {
		// 		headers,
		// 	});
		// 	console.log('✅ ~ Client Folder Drive retrieved successfully', response.data);
		// 	return response.data?.value;
		// } catch (error) {
		// 	console.error('❌ ~ Error retrieving client folder drive', error);
		// 	throw error;
		// }
	};

	const folderExists = async (driveId, itemId, folderName) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
	
			const encodedFolderName = encodeURIComponent(folderName);
	
			const response = await msGraphAxios.get(
				`/drives/${driveId}/items/${itemId}/children?$filter=name eq '${encodedFolderName}'`,
				{ headers }
			);
	
		
			const existingFolder = response.data?.value?.find(
				(item) => item?.name === folderName || decodeURIComponent(item?.name ?? '').normalize() === decodeURIComponent(folderName ?? '').normalize()
			);
	
			return existingFolder;
		} catch (error) {
			console.error(`❌ Error checking if folder exists: ${folderName}`, error);
			throw error;
		}
	};
	

	const createSharepointFolder = async (driveId, itemId, folderName, checkIfExists = false) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			const body = {
				name: folderName,
				folder: {}, 
			};

			// const existingFolder = checkIfExists ? await folderExists(driveId, itemId, folderName) : false;
			const existingFolder = await folderExists(driveId, itemId, folderName);
			console.log('EXISTING FOLDER', existingFolder);
            
			if (existingFolder) {
				return existingFolder;
			}
			const response = await msGraphAxios.post(
				`/drives/${driveId}/items/${itemId}/children`,
				body,
				{ headers }
			);
			console.log('✅ ~ Folder created successfully:', response.data);

			return response.data;
		} catch (error) {
			console.error('❌ ~ Error creating folder:', { error, folderName, driveId, itemId });
			throw error;
		}
	};

	const getListIdFromDrive = async (driveId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			const response = await msGraphAxios.get(`/drives/${driveId}/list`, { headers });
			console.log('✅ List information retrieved:', response.data);

			return response.data.id; // This is your listId
		} catch (error) {
			console.error(
				'❌ Error retrieving listId from driveId:',
				error.response?.data || error.message
			);
			throw error;
		}
	};

	const getListItemId = async (driveId, itemId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};

			const response = await msGraphAxios.get(`/drives/${driveId}/items/${itemId}/listItem`, {
				headers,
			});

			console.log('✅ List item retrieved:', response.data);
			return response.data.id; // This is the SharePoint list item ID
		} catch (error) {
			console.error(
				'❌ Error retrieving list item ID:',
				error.response?.data || error.message
			);
			throw error;
		}
	};

	const getSiteAndListId = async (driveId, itemId) => {
		try {
			const item = await getListItem(driveId, itemId);

			console.log('✅ ~ getListItem data:', item);
			const { parentReference } = item;
			const listId = await getListIdFromDrive(driveId);

			return {
				siteId: parentReference.siteId,
				listId,
				// listId: parentReference.id,
			};
		} catch (error) {
			console.error('❌ Error retrieving siteId or listId', error);
			throw error;
		}
	};

	const addFileToSharepointMsGraph = async (driveId, itemId, file, metadata = {}) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			var fileContent;

			if (file?.content instanceof Blob) {
				fileContent = file.content;
			} else {
				console.log('file: ', file);
				fileContent = await file.arrayBuffer();
				// fileContent = new ArrayBuffer(file);
				// console.log('buffer', fileContent);
			}

			if (file.size <= MAX_SIMPLE_UPLOAD_SIZE) {
				// Use simple upload for files <= 4MB
				const response = await msGraphAxios.put(
					`drives/${driveId}/items/${itemId}:/${file.name}:/content`,
					fileContent,
					{ headers }
				);
				console.log('✅ ~ File added successfully:', response.data);

				// Add metadata after file upload
				if (Object.keys(metadata).length > 0) {
					await addMetadataToFile({ driveId, itemId: response.data.id, metadata });
				}
				return response.data;
			} else {
				return await addFileMsGraphSession(driveId, itemId, file, metadata);
			}
		} catch (error) {
			console.error('❌ ~ Error adding file:', error, file);
			throw error;
		}
	};

	const getMetadataColumns = async (siteId, listId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			
			const response = await msGraphAxios.get(`/sites/${siteId}/lists/${listId}/columns`, {
				headers,
			});

			console.log('✅ ~ List fields:', response.data.value);

			return response.data.value;
		} catch (error) {
			console.error('❌ ~ Error getting metadata columns:', error);
		}
	};

	const addMetadataToFile = async ({ driveId, itemId, metadata }) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			
			const { listId } = await getSiteAndListId(driveId, itemId);
			const item = await getListItem(driveId, itemId);

			const metadataResponse = await msGraphAxios.patch(
				`/sites/${item.parentReference.siteId}/lists/${listId}/items/${item.id}/fields`,
				metadata,
				{ headers }
			);

			console.log('✅ ~ Metadata added successfully:', metadataResponse.data);
		} catch (error) {
			console.error('❌ ~ Error adding metadata to file:', error);
		}
	};

	const addFileMsGraphSession = async (driveId, itemId, file, metadata = {}) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const sessionResponse = await msGraphAxios.post(
				`/drives/${driveId}/items/${itemId}:/${file.name}:/createUploadSession`,
				{
					item: {
						'@microsoft.graph.conflictBehavior': 'rename', // Ensure no file overwrites
						name: file.name,
					},
				},
				{ headers }
			);

			const uploadUrl = sessionResponse.data.uploadUrl;
			console.log('✅ ~ Upload session created:', uploadUrl);

			//Uploads the file in chunks
			// const fileContent = file instanceof Blob ? file : await file.arrayBuffer();
			var fileContent;

			if (file?.content instanceof Blob) {
				fileContent = file.content;
			} else {
				console.log('file: ', file);
				fileContent = await file.arrayBuffer();
			}
			const fileSize = fileContent.byteLength ?? fileContent.size ?? file.size;
			const chunkSize = 8 * 1024 * 1024;
			let start = 0;

			let fileRes;
			console.log('Upload file session data', { fileSize, chunkSize, start });
			while (start < fileSize) {
				const end = Math.min(start + chunkSize, fileSize);
				const chunk = fileContent.slice(start, end);

				fileRes = await msGraphAxios.put(uploadUrl, chunk, {
					headers: {
						...headers,
						'Content-Range': `bytes ${start}-${end - 1}/${fileSize}`,
					},
				});
				console.log('Upload file session data', { end, chunk, fileRes });
				start = end;
			}

			console.log('✅ ~ File uploaded successfully!', fileRes);
			if (Object.keys(metadata).length > 0 && fileRes?.data?.id) {
				await addMetadataToFile({ driveId, itemId: fileRes?.data?.id, metadata });
			}
			return fileRes?.data;
		} catch (error) {
			console.error('❌ ~ Error uploading file via session:', error, file);
			throw error;
		}
	};

	const deleteSharepointItem = async (driveId, itemId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			const response = await msGraphAxios.delete(`/drives/${driveId}/items/${itemId}`, {
				headers,
			});
			console.log(`✅ ~ Item ${itemId} deleted successfully:`, response.data);

			return response.data;
		} catch (error) {
			console.error('❌ ~ Error deleting item:', error);
			throw error;
		}
	};

	const moveSharepointItem = async (driveId, itemId, newParentId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			const body = {
				parentReference: {
					id: newParentId,
				},
				'@microsoft.graph.conflictBehavior': 'rename',
			};

			const response = await msGraphAxios.patch(`/drives/${driveId}/items/${itemId}`, body, {
				headers,
			});

			console.log(`✅ ~ Item ${itemId} moved successfully:`, response.data);
			return response.data;
		} catch (error) {
			console.error('❌ ~ Error moving item:', error);
			throw error;
		}
	};

	const renameSharepointItem = async (driveId, itemId, newName) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			const body = {
				name: newName,
			};

			const response = await msGraphAxios.patch(`/drives/${driveId}/items/${itemId}`, body, {
				headers,
			});

			console.log(`✅ ~ Item ${itemId} renamed successfully to ${newName}:`, response.data);
			return response.data;
		} catch (error) {
			console.error('❌ ~ Error renaming item:', error);
			throw error;
		}
	};

	const getSharepointSiteDrives = async (siteId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.get(`/sites/${siteId}/drives`, { headers });
			console.log('✅  ~ Drive retrieved successfully', response.data);
			return response.data?.value;
		} catch (error) {
			console.error('❌ ~ Error retrieving drive', error);
			throw error;
		}
	};

	const getListItem = async (driveId, fileId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			// Fetch the associated list item
			const response = await msGraphAxios.get(`/drives/${driveId}/items/${fileId}/listItem`, {
				headers,
			});

			console.log('✅ ~ List item:', response.data);
			return response.data;
		} catch (error) {
			console.error('❌ ~ Error retrieving list item', error);
			throw error;
		}
	};

	const getFileMetadata = async (siteId, listId, listItemId) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};

			const propertiesResponse = await msGraphAxios.get(
				`/sites/${siteId}/lists/${listId}/items/${listItemId}?expand=fields(select='OData__x005f_ExtendedDescription')`,
				{
					headers,
				}
			);

			console.log('✅ ~ File metadata (ListItemAllFields):', propertiesResponse.data);
			return propertiesResponse.data;
		} catch (error) {
			console.error('❌ ~ Error retrieving file metadata', error);
			throw error;
		}
	};

	const querySharepointSites = async (query) => {
		try {
			const accessToken = await getGraphAccessToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			};
			const response = await msGraphAxios.get(`/sites?search=${query}`, { headers });
			console.log('✅ ~ [querySharepointSites] retrieved successfully', response.data);
			return response.data?.value?.[0]?.id;
		} catch (error) {
			console.error('❌ ~ Error [querySharepointSites]', error);
			throw error;
		}
	};

	// Returning the functions to be used elsewhere in the app
	return {
		getSharepointLists,
		getSharepointListItems,
		getSharepointListItemChildren,
		getListItem,
		getFileMetadata,
		getDriveItem,
		getSharepointSiteDrives,
		querySharepointSites,
		getClientFolderDrive,
		getClientMsGraphFolders,
		searchClientMsGraphFoldersByItemId,
		searchClientMsGraphFolders,
		getGraphAccessToken,
		createOnlineMeetingEvent,
		createEventTask,
		getUserIdByEmail,
		getUserCalendars,
		getGraphEventData,
		updateGraphEventData,
		addMetadataToFile,
		createSharepointFolder,
		deleteSharepointItem,
		moveSharepointItem,
		renameSharepointItem,
		addFileToSharepointMsGraph,
		addFileMsGraphSession,
	};
};
