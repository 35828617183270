/* eslint-disable no-empty */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import FileUpload from '../FileUpload';
import { useAttachmentsContext } from '@/context/attachmentsContext';
import { createUniqueFileName } from '@/utils/folders';
import { FileExistsDialog } from '../dialogs/FileExistsDialog';

export const FileUploadMSGraph = () => {
	
	const [filesToUpload, setFilesToUpload] = useState([]);
	const [currentFile, setCurrentFile] = useState();
	const [dialogOpen, setDialogOpen] = useState(false);

	const [uploadLoading, setUploadLoading] = useState(false);

	const { client, canCreate, files, handleUploadFile } = useAttachmentsContext();
	
	const {
		itemId,
		loading,
		searchValue,
	} = useSelector((state) => state.attachmentsTable);

	const isLoading = uploadLoading || loading;
	const disableUpload = !itemId || !canCreate || searchValue.length > 0;

	const checkFileExists = (file) => {
		return files.some((f) => f.name === file.name && !f.isFolder);
	};

	// Handle the upload
	const handleUploadFiles = async (files) => {
		setUploadLoading(true);
		for (const file of files) {
			if (checkFileExists(file)) {
				setCurrentFile(file);
				setDialogOpen(true);
				return;
			}
			await handleUploadFile(file);
		}
		setUploadLoading(false);
	};

	// Handle dialog actions
	const handleCreateCopy = async () => {
		if (!currentFile) return;
		const fileName = createUniqueFileName(currentFile.name);
		const renamedFile = new File([currentFile], fileName, {
			type: currentFile.type,
			lastModified: currentFile.lastModified,
		});

		await handleUploadFile(renamedFile, itemId);
		handleNextFile();
	};

	const handleNextFile = () => {
		setDialogOpen(false);
		setCurrentFile(null);
		handleUploadFiles(filesToUpload.slice(1));
	};

	return (
		<>
			<Box width='100%' pb={'1em'}>
				{Object.keys(client ?? {}).length > 0 && !disableUpload && (
					<FileUpload
						files={[]}
						setFiles={(files) => {
							if (!disableUpload) {
								setFilesToUpload(files);
								handleUploadFiles(files);
							}
						}}
						loading={isLoading}
					/>
				)}
			</Box>
			<FileExistsDialog
				open={dialogOpen}
				fileName={currentFile?.name}
				onClose={handleNextFile}
				onCreateCopy={handleCreateCopy}
			/>
		</>
	);
};
