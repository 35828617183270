import React, { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	Box,
} from '@mui/material';

export const ConfirmDialog = ({
	openDialog,
	handleClose,
	handleConfirm,
	actionName,
	canBeUndone,
	additionalContent = null,
}) => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (openDialog) {
			setLoading(false);
		}
	}, [openDialog]);

	return (
		<Dialog
			open={openDialog}
			onClose={handleClose}
			hideBackdrop={true}
			sx={{ minWidth: '25%', padding: '1em' }}
		>
			<DialogTitle>{`Confirm ${actionName}`}</DialogTitle>
			<DialogContent sx={{ paddingBottom: '2em' }}>
				{`Are you sure that you want to ${actionName}? ${
					additionalContent
						? additionalContent
						: canBeUndone
							? ''
							: ' This cannot be undone.'
				}`}
			</DialogContent>
			<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
				<Button
					onClick={() => {
						handleClose();
					}}
					disabled={loading}
					color='primary'
				>
					Cancel
				</Button>
				<Button
					color='primary'
					type='submit'
					variant='contained'
					disabled={loading}
					onClick={() => {
						setLoading(true);
						handleConfirm();
					}}
				>
					{loading ? (
						<Box sx={{ padding: '0 2em' }}>
							<CircularProgress size='1em' color='white' />
						</Box>
					) : (
						'Confirm'
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
