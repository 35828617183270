/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useCallback } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	FormHelperText,
	TextField,
	FormLabel,
	Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { showToast } from '@/features/toast/toastSlice';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import validationSchema, { FOLDER_NAME, ERROR_MESSAGE } from './validationSchema';
import { determineContext } from '@/hooks/determineContext';
import { clientsApi } from '@/features/clients/clientsApi';
import { dealsApi } from '@/features/deals/dealsApi';
import { engagementsApi } from '@/features/engagements/engagementsApi';
import { triggerRefresh } from '@/features/table/attachmentsTableSlice';
import { useAttachmentsContext } from '@/context/attachmentsContext';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';

export const AddFolderSharepointDialog = () => {
	const { deal, client, files } = useAttachmentsContext();

	const existingFolders = useMemo(
		() => files.filter((f) => f.isFolder).map((f) => f.name),
		[files]
	);
	const ownerId = deal?.hubspot_owner_id ?? client?.hubspot_owner_id;

	const dispatch = useDispatch();

	const open = useSelector((state) => state.dialog.open['createFolder']);

	const userQuery = useGetUserDetailsQuery({ hubspotId: ownerId }, { skip: !ownerId });

	const { createClientSharepointFolder } = useClientStorageHooks(userQuery.data);

	const { objectType } = determineContext();

	const isClient = useMemo(() => objectType == 'client', [objectType]);

	const { itemId } = useSelector((state) => state.attachmentsTable);

	const handleClose = () => {
		formik.resetForm();
		dispatch(closeDialog('createFolder'));
	};

	const handleCreateFolder = async () => {
		if (!itemId) return;
		await createClientSharepointFolder(
			userQuery.data?.clientFolderDriveId,
			itemId,
			formik.values[FOLDER_NAME].trim()
		);
	};

	const formik = useFormik({
		initialValues: {
			[FOLDER_NAME]: '',
			[ERROR_MESSAGE]: '',
		},
		validationSchema: validationSchema(existingFolders),
		validateOnBlur: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			try {
				await handleCreateFolder();
				dispatch(showToast({ message: 'Folder created successfully', type: 'success' }));
				setTimeout(async () => {
					try {
						const type = (objectType ?? '').toUpperCase();
						const objectId = isClient ? client.hs_object_id : deal.hs_object_id;
						dispatch(
							isClient
								? clientsApi.util.invalidateTags([
									{ type: `${type}S`, id: 'LIST' },
									{ type, id: objectId },
									{ type: 'CLIENT_ROW', id: objectId },
								  ])
								: dealsApi.util.invalidateTags([
									{ type: `${type}S`, id: 'LIST' },
									{ type, id: objectId },
									{ type: 'DEAL_ROW', id: objectId },
								  ])
						);
						dispatch(
							engagementsApi.util.invalidateTags([
								{ type: 'ENGAGEMENTS', id: objectId },
							])
						);
						dispatch(triggerRefresh());
					} catch (error) {
						console.error('Error invalidating API:', error);
					}
				}, 2000);
				resetForm();
				handleClose();
			} catch (error) {
				const message =
					error?.response?.data?.error?.message ??
					error?.message ??
					'There was an error. Please try again.';
				setFieldValue(ERROR_MESSAGE, `Error creating folder. ${message}`);
				dispatch(showToast({ message, type: 'error' }));
			} finally {
				setSubmitting(false);
			}
		},
	});

	const { getError, getErrorMessage } = useFormikHelper(formik);
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			sx={{ minWidth: '50%', padding: '1em' }}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle>Create Folder</DialogTitle>
				<DialogContent sx={{ paddingBottom: '2em' }}>
					<FormLabel required>Folder Name</FormLabel>
					<TextField
						fullWidth
						name={FOLDER_NAME}
						{...formik.getFieldProps(FOLDER_NAME)}
						error={getError(FOLDER_NAME)}
						helperText={getErrorMessage(FOLDER_NAME)}
						disabled={formik.isSubmitting}
						required
					/>

					{formik.values[ERROR_MESSAGE] && (
						<FormHelperText error>{formik.values[ERROR_MESSAGE]}</FormHelperText>
					)}
					<Typography
						variant='caption'
						sx={{
							display: 'block',
							mt: '1.5em',
							color: 'text.secondary',
						}}
					>
						<span>
							{'Folders cannot contain the following characters:'}
							<span style={{ display: 'block' }}>{'< > : " \' /\\ | ? % *'}</span>
							{'or end in a .'}
						</span>
					</Typography>
				</DialogContent>
				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button onClick={handleClose} color='primary'>
						Cancel
					</Button>
					<Button
						type='submit'
						variant='contained'
						color='primary'
						disabled={formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<CircularProgress size='2em' sx={{ color: '#FFFFFF' }} />
						) : (
							'Create'
						)}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
