import { createTheme } from '@mui/material/styles';

export const backgroundColour = 'rgba(250, 253, 255, 1.0)';
const folioBlue = '#505afc';

export const theme = createTheme({
	palette: {
		primary: {
			main: folioBlue
		}
	},
	typography: {
		fontFamily: 'Poppins',
		body1: {
			fontWeight: 400,
			fontSize: '14px',
		},
		broker_header: {
			fontWeight: 700,
			fontSize: '1em',
			color: '#000000'
		},
		deal_header: {
			fontWeight: 700,
			fontSize: '24px',
			color: 'rgba(17, 25, 39, 1)'
		},
		card_header: {
			fontWeight: 400,
			fontSize: '14px',
			color: '#6C737F'
		},
		card_h4: {
			fontWeight: 700,
			fontSize: '32px',
			color: '#000000'
		},
		form_label: {
			fontWeight: 600,
			fontSize: '14px',
			color: '#343434'
		},
		avatar_title: {
			fontWeight: 500,
			fontSize: '14px',
			color: '#000000'
		},
		avatar_subtitle: {
			fontWeight: 500,
			fontSize: '10px',
			color: '#5F5F5F'
		},
		task_body: {
			fontWeight: 400,
			fontSize: '14px',
			color: 'rgba(52, 73, 94, 1)'
		},
		task_updated: {
			fontWeight: 400,
			fontSize: '12px',
			color: 'rgba(52, 73, 94, 0.5)'
		},
		details_header: {
			fontWeight: 500,
			fontSize: '18px',
			color: 'rgba(17, 25, 39, 1)'
		},
		details_body: {
			fontWeight: 400,
			fontSize: '14px',
			color: 'rgba(108, 115, 127, 1)'
		},
		blue_text: {
			fontWeight: 400,
			fontSize: '14px',
			color: folioBlue,
		},
		client_subheader: {
			fontWeight: 400,
			fontSize: '12px',
			color: 'rgba(52, 52, 52, 1)'
		},
		client_claims_table: {
			fontSize: '14px', 
			fontWeight: 400,
		}
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
                body {
                    font-family: 'Poppins', sans-serif;
                }
            `,
		},
		MuiButton: {
			styleOverrides: {
				containedPrimary: {
					borderRadius: '15px',
					'&:hover': {
						backgroundColor: 'rgba(80, 90, 252, 0.75)', // 75% transparency
					},
				}
			},
			variants: [
				{
					props: { variant: 'table_action' },
					style: ({theme}) => ({
						...theme.components.MuiButton.styleOverrides.contained,
						borderRadius: '20px',
						backgroundColor: '#DADCFF',
						color: '#505AFC',
						fontWeight: 'bold',
						boxShadow: theme.shadows[4], // Apply elevation shadow
						'&:hover': {
							backgroundColor: 'rgba(218, 220, 255, 0.5)', // 50% transparency
							boxShadow: theme.shadows[6], // Elevated shadow on hover
						},
					}),
				},
				{
					props: { variant: 'tab_header' },
					style: ({theme}) => ({
						...theme.components.MuiButton.styleOverrides.contained,
						borderRadius: '10px',
						backgroundColor: '#34495E',
						color: '#FFFFFF',
						boxShadow: theme.shadows[4], // Apply elevation shadow
						'&:hover': {
							backgroundColor: '#000000',
							boxShadow: theme.shadows[6], // Elevated shadow on hover
						},
					}),
				},
				{
					props: { variant: 'tab_header_outlined' },
					style: ({theme}) => ({
						...theme.components.MuiButton.styleOverrides.outlined,
						borderRadius: '10px',
						border: '1px solid #34495E',
						color: '#34495E',
						'&:hover': {
							backgroundColor: '#ffffff',
							boxShadow: theme.shadows[1], // Elevated shadow on hover
						},
					}),
				},
				{
					props: { variant: 'edit_button' },
					style: ({theme}) => ({
						...theme.components.MuiButton.styleOverrides.contained,
						borderRadius: '8px',
						backgroundColor: folioBlue,
						color: '#FFFFFF',
						padding: '2px 24px',
						boxShadow: theme.shadows[4], // Apply elevation shadow
						'&:hover': {
							backgroundColor: 'rgba(80, 90, 252, 0.5)',
							boxShadow: theme.shadows[6], // Elevated shadow on hover
						},
					}),
				}
			]
		},
		MuiLink: {
			styleOverrides: {
				root: {
					fontFamily: 'Poppins'
				}
			}
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					backgroundColor: '#f5f8fa',
					borderRadius: '5px',
					'& .Mui-disabled': {
						backgroundColor: 'rgba(224, 224, 224, 1)',
					}
				},
				minimal: {
					backgroundColor: 'rgba(0,0,0,0)',
					color: folioBlue,
					border: 'none',                 // Removes border
					'& .MuiInputBase-input': {
						fontWeight: 600,           // Ensures input text is bold
					},
					'& .MuiSvgIcon-root': {
						color: folioBlue,             // Sets icon color
					},
					'&:focus': {
						backgroundColor: 'rgba(0,0,0,0)',
						borderColor: 'rgba(0,0,0,0)',   // Removes border on focus
					},
				}
			}
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontWeight: 600,
					fontSize: '14px',
					color: '#343434'
				},
				minimal: {
					color: 'rgba(108, 115, 127, 1)',
					fontSize: '12px',
					fontWeight: 500
				}
			}
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					marginLeft: '14px',
				},
			},
		},
		MuiDataGrid: {
			styleOverrides: {
				row: {
					'&.Mui-selected': {
						color: folioBlue
					}
				}
			}
		}
	}
});