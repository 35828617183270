import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Tab, Tabs, Stack, Skeleton, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { firstTwoCapsOrNumbers, folioBlue } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';
import { AssociatedClient } from '@/components/cards/DealCardValues/AssociatedClient';

import { DealNameUpdateField } from '@/components/cards/DealCardValues/DealChangeNameField';
import { useDispatch } from 'react-redux';
import {
	setBreadcrumbs,
	setItemId,
} from '@/features/table/attachmentsTableSlice';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

export const TabView = ({ tabs, title, subtitle, icon }) => {
	const location = useLocation();
	const dispatch = useDispatch();

	const { loading, deal, dealRow, client } = determineContext();

	const rowId = useMemo(
		() => (deal ? dealRow?.driveId : client?.clientRow?.driveId),
		[deal, dealRow?.driveId, client?.clientRow?.driveId]
	);
	const isLoading = loading.client || loading.deal;

	const [currentTab, setCurrentTab] = useState(() => {
		if (location.state?.task) {
			return 'tasks';
		}
		return tabs[0].value;
	});

	useEffect(() => {
		console.log('LOCATION', location.state?.task);
		if (location.state?.task) {
			setCurrentTab('tasks');
		}
	}, [location?.state?.task]);

	const handleTabChange = (event, value) => {
		event.preventDefault();
		dispatch(setBreadcrumbs([]));
		dispatch(setItemId(rowId));
		setCurrentTab(value);
	};

	useEffect(() => window.scrollTo(0, 0), [currentTab]);

	const featureFlagQuery =useGetFeatureFlagQuery({ feature: 'folderView' });
		const showNewFoldersFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const activeTabComponents = useMemo(() => {
		return tabs.map((tab) => {
			const tabComponent = (
				<Tab
					sx={{ fontWeight: 'bold', color: 'rgba(108, 115, 127, 1)' }}
					key={tab.value}
					label={
						['factfind', 'quotes', 'risk'].includes(tab.value) ? (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									position: 'relative',
									height: '100%',
								}}
							>
								{tab.label}
								<Box
									sx={{
										position: 'absolute',
										top: '30%',
										right: '-3.25em',
										transform: 'translateY(-75%) rotate(45deg)',
										backgroundColor: '#34495E',
										color: 'white',
										padding: '2px 20px',
										fontSize: '0.7em',
										whiteSpace: 'nowrap',
									}}
								>
									{'BETA'}
								</Box>
							</Box>
						) : (tab.value === 'attachments' && showNewFoldersFeature === true) ? (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									position: 'relative',
									height: '100%',
								}}
							>
								{tab.label}
								<Box
									sx={{
										position: 'absolute',
										top: '30%',
										right: '-3.25em',
										transform: 'translateY(-75%) rotate(45deg)',
										backgroundColor: folioBlue,
										color: 'white',
										padding: '2px 20px',
										fontSize: '0.7em',
										whiteSpace: 'nowrap',
									}}
								>
									{'NEW'}
								</Box>
							</Box>
						) : (
							tab.label
						)
					}
					value={tab.value}
				/>
			);
			return tabComponent;
		});
	}, [tabs]);

	const CurrentTabContent = tabs.find(
		(tab) => tab.value === currentTab
	)?.ContentComponent;
	const CurrentHeaderComponent = tabs.find(
		(tab) => tab.value === currentTab
	)?.HeaderComponent;

	const LoadingComponentHeader = () => (
		<Box display="flex" sx={{ justifyContent: 'right' }}>
			<Skeleton animation="wave" variant="rounded" height="3em" width="8em" />
		</Box>
	);

	const LoadingHeader = () => (
		<Box display="flex" width="100%" sx={{ alignItems: 'center' }}>
			<Skeleton
				animation="wave"
				variant="rounded"
				height="55px"
				width="55px"
				sx={{ mr: '10px' }}
			/>
			<Stack width="100%">
				<Skeleton animation="wave" variant="text" width="70%" height="2em" />
				<Skeleton animation="wave" variant="text" width="32%" />
			</Stack>
		</Box>
	);

	return (
		<Box
			className="tab-view"
			sx={{
				backgroundColor: (theme) =>
					theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100',
				mt: '1em',
			}}
		>
			<Box display="flex" px={3} width="100%" sx={{ alignItems: 'center' }}>
				{isLoading ? (
					<LoadingHeader />
				) : (
					<>
						<Stack direction="row" alignItems="center" width="100%" spacing={1}>
							{icon && icon}
							<Stack width="100%">
								<DealNameUpdateField title={title ?? 'Unknown'} />

								{deal ? (
									<AssociatedClient hideHeader={true} />
								) : (
									<Typography variant="body1">{subtitle}</Typography>
								)}
							</Stack>
						</Stack>
					</>
				)}
			</Box>
			<Tabs
				indicatorColor="primary"
				scrollButtons="auto"
				textColor="primary"
				value={currentTab}
				sx={{ mt: 3, mb: 2, borderBottom: 'solid 1px #d3d3d3' }}
				variant="scrollable"
				onChange={handleTabChange}
			>
				{activeTabComponents}
			</Tabs>
			<Box px={3}>
				{CurrentHeaderComponent && (
					<Stack
						spacing={1}
						direction="row"
						justifyContent={'flex-end'}
						alignItems={'center'}
					>
						{isLoading ? (
							<LoadingComponentHeader />
						) : (
							<CurrentHeaderComponent />
						)}
					</Stack>
				)}
				{CurrentTabContent && <CurrentTabContent />}
			</Box>
		</Box>
	);
};
