/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo } from 'react';
import {
	Card,
	Typography,
	Link,
	Button,
	Stack,
	Box,
	Skeleton,
	useTheme,
	useMediaQuery,
} from '@mui/material';
import '@/styles/index.scss';
import { folioBlue, formatDate } from '@/utils/constants';
import { useGetMsgFileHtmlQuery } from '@/features/files/filesApi';
import EngagementsAccordion from '../EngagementsAccordion';
import PreviewIcon from '@mui/icons-material/Preview';

export const EmailCard = ({ email }) => {
	const {
		data: emailMsgData,
		isLoading,
		isError,
	} = useGetMsgFileHtmlQuery(
		{ id: email?.data?.id, downloadUrl: email?.data['@microsoft.graph.downloadUrl'] },
		{ skip: !email?.data }
	);

	useEffect(
		() => console.log('EMAIL DOC DATA', email, 'EMAIL MSG DATA', emailMsgData),
		[email, emailMsgData]
	);

	const emailHtml = useMemo(() => {
		const body = (emailMsgData?.body ?? '').split(/\r\n|\n/g).map((r) => r.trim());

		// Array to hold the processed lines
		const processedLines = [];
		let emptyLineStreak = 0;

		for (const line of body) {
			if (line === '') {
				emptyLineStreak++;
				if (emptyLineStreak <= 3) {
					processedLines.push('\n');
				}
			} else {
				emptyLineStreak = 0;
				processedLines.push(line);
			}
		}

		return processedLines.join('').trim();
		// const body = (emailData?.body ?? '').split(/\r\n|\n/g).map(r => r.trim()).map(r => r.length > 0 ? r : '\n');
		// console.log('BODY', body);
		// return body.join('\n').trim();//.replace(/\n{3,}/g, '\n\n\n').trim();
	}, [emailMsgData?.body]);

	const subject = useMemo(() => emailMsgData?.subject ?? '', [emailMsgData?.subject]);
	const recipients = useMemo(
		() => (emailMsgData?.recipients ?? []).map((r) => r?.smtpAddress ?? r?.email ?? ''),
		[emailMsgData?.recipients]
	);

	const sender = useMemo(() => {
		const senderEmail =
			emailMsgData?.senderAddressType == 'EX'
				? emailMsgData?.senderSmtpAddress ??
				  emailMsgData?.creatorSMTPAddress ??
				  emailMsgData?.lastModifierSMTPAddress ??
				  emailMsgData?.sentRepresentingSmtpAddress ??
                  emailMsgData?.inetAcctName ??
				  emailMsgData?.senderEmail ??
				  ''
				: emailMsgData?.senderEmail ??
				  emailMsgData?.senderSmtpAddress ??
				  emailMsgData?.creatorSMTPAddress ??
				  emailMsgData?.sentRepresentingSmtpAddress ??
				  emailMsgData?.lastModifierSMTPAddress ??
				  '';
		return senderEmail;
	}, [
		emailMsgData?.inetAcctName,
		emailMsgData?.senderSmtpAddress,
		emailMsgData?.creatorSMTPAddress,
		emailMsgData?.lastModifierSMTPAddress,
		emailMsgData?.sentRepresentingSmtpAddress,
		emailMsgData?.senderEmail,
		emailMsgData?.senderAddressType,
	]);

	const date = useMemo(
		() =>
			new Date(
				emailMsgData?.messageDeliveryTime ??
					emailMsgData?.creationTime ??
					email.data?.createdDateTime
			),
		[emailMsgData?.creationTime, email.data?.createdDateTime]
	);

	const lastUpdated = useMemo(
		() =>
			new Date(
				email.data?.lastModifiedDateTime ?? emailMsgData?.lastModificationTime ?? new Date()
			),
		[emailMsgData?.lastModificationTime, email.data?.lastModifiedDateTime]
	);

	return (
		<EngagementsAccordion
			title={
				<Stack>
					<Typography>
						<span style={{ fontWeight: 'bold' }}>Email - {subject}</span>
						<span>
							<span style={{ margin: '0 0.5em' }}>{' | '}</span>
							<span style={{ fontWeight: '500' }}> {formatDate(date, true)} </span>
						</span>
					</Typography>
					<Typography variant='task_updated'>
						<span style={{ fontWeight: 'bold', marginRight: '0.25em' }}>
							{'From: '}
						</span>
						<span>{sender}</span>
						<span
							style={{
								fontWeight: 'bold',
								marginRight: '0.25em',
								marginLeft: '0.25em',
							}}
						>
							{' to: '}
						</span>
						<span>{recipients.join(', ')}</span>
					</Typography>
				</Stack>
			}
			engagement={email}
			lastUpdated={lastUpdated}
			footerContent={
				<Link
					href={`${email?.data?.webUrl}?web=1`}
					target='_blank'
					rel='noopener noreferrer'
					sx={{ textDecorationLine: 'none' }}
				>
					<Stack direction='row' alignContent='center'>
						<PreviewIcon sx={{ color: folioBlue, mr: '0.25em' }} />
						{email.data.name}
					</Stack>
				</Link>
			}
			expandedContent={
				<Box width='100%'>
					{isLoading ? (
						<Skeleton variant='text' width='100%' />
					) : isError ? (
						<Typography>Error loading email content</Typography>
					) : (
						<Stack>
							<Typography sx={{ whiteSpace: 'pre-line' }}>{emailHtml}</Typography>
							{/* {formattedText.map((text, i) => (
								<Typography key={`email-${email?.data?.id}-text-${i}`} sx={{ whiteSpace: 'pre-line'}}>
									{text}
								</Typography>
							))} */}
							{/* parse(formattedText, {
							replace({ name, children }) {
								if (name == 'html') {
									const body = children.find((n) => n.name == 'body');
									if (body) {
										return <Box width='100%'>{domToReact(body.children)}</Box>;
									} else {
										return <></>;
									}
								}
							},
						}) */}
						</Stack>
					)}
				</Box>
			}
		/>
	);
};
