import React, { useEffect } from 'react';
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { useSortBy } from 'react-instantsearch';

export function FolioTableHead({ order, orderBy, onRequestSort, headCells, onSelectAllCheck, CustomCheckbox }) {
	const createSortHandler = (property) => (event) => {
		if (onRequestSort) {
			onRequestSort(event, property);
		}
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={
							headCell.align ??
							(headCell.numeric ? 'right' : headCell.id === 'id' ? 'center' : 'left')
						}
						padding={headCell.label == null ? 'checkbox' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
						style={{ fontWeight: 'bold' }}
						width={
							headCell.width ?? (headCell.numeric || headCell.date ? '80px' : 'auto')
						}
					>
						{/*Added a thing that checks if label is null make checkbox */}
						{headCell.label == null ? (
							CustomCheckbox ? CustomCheckbox : <Checkbox
								disabled={onSelectAllCheck == null}
								onChange={onSelectAllCheck}
							/>
						) : headCell.sortable !== false ? (
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label.toUpperCase()}
								{orderBy === headCell.id ? (
									<span hidden={true}>
										{order === 'desc'
											? 'sorted descending'
											: 'sorted ascending'}
									</span>
								) : null}
							</TableSortLabel>
						) : (
							<span>{(headCell.label ?? '').toUpperCase()}</span>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

export const SearchFolioHead = ({
	order,
	orderBy,
	onRequestSort,
	headCells,
	onSelectAllCheck,
	CustomCheckbox,
	...props
}) => {
	const { initialIndex, currentRefinement, options, refine, canRefine } = useSortBy(props);

	useEffect(() => {
		const sortItem = options.find((item) => item.value.includes(`${orderBy}:${order}`));
		if (sortItem) {
			refine(sortItem.value);
		}
	}, [order, orderBy, options]);

	const handleRequestSort = (event, property) => {
		onRequestSort(event, property);
		// const sortItem = options.find(item => item.value.includes(`${property}:${order}`));
		// if (sortItem) {
		// 	refine(sortItem.value);
		// }
	};

	return (
		<FolioTableHead
			order={order}
			orderBy={orderBy}
			onRequestSort={handleRequestSort}
			headCells={headCells}
			onSelectAllCheck={onSelectAllCheck}
			{...(CustomCheckbox && { CustomCheckbox })}
		/>
	);
};
