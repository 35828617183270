import { createSlice } from '@reduxjs/toolkit';

export const toastSlice = createSlice({
	name: 'toast',
	initialState: {
		open: false,
		duration: 6000,
		message: '',
		action: null, 
		autohide: true,
		loading: true,
		/** action is an object with:
         * { 
         *      path: '/url',               // path for useNavigate()
         *      label: 'GO TO TASK',        // label for button
         *      params: { state: {  } },    // optional params for useNavigate()
         * }
         * */
		//6000
		error: false,
		success: false,
	},
	reducers: {
		showToast: (state, action) => {
			state.open = true;
			state.message = action.payload.message;
			state.action = action.payload.action;
			state.error = action.payload.error ?? false;
			state.success = action.payload.success ?? false;
			state.duration = action.payload.duration ?? 6000;
			state.autohide = action.payload.autohide ?? true;
			state.loading = action.payload.loading ?? false;
		},
		hideToast: (state) => {
			state.open = false;
			state.message = '';
			state.action = null;
			state.error = false;
			state.success = false;
			state.duration = 6000;
			state.autohide = true;
			state.loading = false;
		},
	},
});

export const { 
	showToast, 
	hideToast 
} = toastSlice.actions;
export default toastSlice.reducer;