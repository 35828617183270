/* eslint-disable no-mixed-spaces-and-tabs */
// DetailsTab.jsx
import React, { useState, useEffect } from 'react';
import { Box, Typography, FormLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ClientDetailField } from '@/components/ClientDetailEntry/clientDetailEntry';
import {
	useUpdateClientMutation,
	useUpdateInsightClientMutation,
} from '@/features/clients/clientsApi';
import {
	addressSubtitles,
	clientHeaders,
	contactHeaders,
} from '@/components/ClientDetailEntry/clientDetailData';
import { ClientCategory } from '@/components/cards/DealCardValues/ClientCategory';
import GooglePlacesAutocomplete from '@/components/selects/GoogleAddressSelect';
import { determineContext } from '@/hooks/determineContext';
import { useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';

export const DetailsTab = ({ displayContactDetails = true, clientData }) => {
	// Initialize address state with values from clientData or empty strings
	const [address] = useState({
		address: clientData?.address || '',
		city: clientData?.city || '',
		suburb: clientData?.suburb || '',
		country: clientData?.country || '',
		postcode: clientData?.postcode || '',
	});

	const [updateClient, clientState] = useUpdateClientMutation();
	const [updateInsightClient, clientInsightState] =
    useUpdateInsightClientMutation();
	const { client } = determineContext();
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const {
		data: userDetails,
	} = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);

	const hubspotId = userDetails?.hubspotId;
	const loading = clientState.isLoading || clientInsightState.isLoading;

	const handleUpdateClient = async (
		address,
		city,
		suburb,
		country,
		postcode
	) => {
		const properties = {
			AddressLine1: address,
			Suburb: suburb,
			City: city,
			Postcode: postcode,
			Country: country,
		};

		console.log('propeties', properties);

		try {
			await updateClient({
				clientId: client.hs_object_id,
				properties: { address, city },
			}).unwrap();
			await updateInsightClient({
				properties,
				ownerId: hubspotId,
				clientId: client?.id ?? client?.hs_object_id,
				insightId: client?.insight?.Id,
			}).unwrap();
		} catch (error) {console.log(error);
    
		}
	};

	// Update form fields when a new address is selected in Google Places
	const handleAddressSelect = async(place) => {
		const addressComponents = place?.address_components || [];

		const streetNumber =
      addressComponents.find((comp) => comp.types.includes('street_number'))
      	?.long_name || '';
		const streetAddress =
      addressComponents.find((comp) => comp.types.includes('route'))
      	?.long_name || '';
		const combinedAddress = `${streetNumber} ${streetAddress}`.trim();

		const city =
      addressComponents.find((comp) => comp.types.includes('locality'))
      	?.long_name || '';
		const suburb =
      addressComponents.find((comp) =>
      	comp.types.includes('sublocality_level_1')
      )?.long_name || '';
		const country =
      addressComponents.find((comp) => comp.types.includes('country'))
      	?.long_name || '';
		const postcode =
      addressComponents.find((comp) => comp.types.includes('postal_code'))
      	?.long_name || '';

		await handleUpdateClient(combinedAddress, city, suburb, country, postcode);
	};

 

	const contactSection = () => (
		<Box style={{ marginBottom: '18px', padding: '12px' }}>
			<Typography variant="details_header">Contact Details</Typography>
			{Object.entries(contactHeaders).map(([key, header]) => (
				<Grid container padding={'1em 0 0.5em'} key={`detail-entry-${key}`}>
					<Grid size={12}>
						{key === 'fullname' ? (
							<Grid container columns={2} spacing={1}>
								<Grid size={1}>
									<ClientDetailField
										type="contact"
										title={header}
										header="firstname"
										value={clientData?.firstname || ''}
									/>
								</Grid>
								<Grid size={1}>
									<ClientDetailField
										type="contact"
										title={header}
										header="lastname"
										value={clientData?.lastname || ''}
									/>
								</Grid>
							</Grid>
						) : (
							<ClientDetailField
								title={header}
								type="contact"
								header={key}
								value={clientData?.[key] || ''}
								readOnly={key === 'email'}
							/>
						)}
					</Grid>
				</Grid>
			))}
		</Box>
	);

	const clientSection = () => (
		<Box
			style={{
				marginBottom: '18px',
				padding: displayContactDetails ? '12px' : '0',
			}}
		>
			{displayContactDetails && (
				<Typography variant="details_header">Client Details</Typography>
			)}
			<ClientCategory />
			{Object.entries(clientHeaders).map(([key, header]) => (
				<Grid container padding={'1em 0 0.5em'} key={`detail-entry-${key}`}>
					<Grid size={12}>
						{key === 'address' ? (
							<Grid container columns={{ xs: 1, sm: 2 }} spacing={1}>
								<Grid size={12} key="google-places-autocomplete">
									<div>
										<FormLabel
											component="legend"
											style={{
												fontSize: '0.875rem',
												fontWeight: 'semibold',
												marginBottom: '0.2rem',
											}}
										>
                      Find and update Address
										</FormLabel>
										<GooglePlacesAutocomplete onSelect={handleAddressSelect} />
									</div>
								</Grid>
								{/* Render address fields dynamically */}
								{Object.entries(addressSubtitles).map(([k, h]) => (
									<Grid size={1} key={`detail-entry-address-${k}`}>
										<ClientDetailField
											type="client"
											header={k}
											title={h}
											value={address[k]}
											readOnly={false}
											isLoading={loading}
										/>
									</Grid>
								))}
							</Grid>
						) : (
							<ClientDetailField
								type="client"
								header={key}
								title={header}
								value={clientData?.[key] || ''}
								readOnly={['name', 'email'].includes(key)}
								isLoading={loading}
							/>
						)}
					</Grid>
				</Grid>
			))}
		</Box>
	);

	return (
		<Box>
			{displayContactDetails && contactSection()}
			{clientSection()}
		</Box>
	);
};
