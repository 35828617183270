import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	FormHelperText,
	TextField,
	FormLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { CLIENT_NAME, EMAIL, FIRST_NAME, LAST_NAME, OTHER_INFO, PHONE } from '@/components/forms/CreateNewBusinessForm/validationSchema';
import { FormControl } from '@mui/base';
import { NumericFormat } from 'react-number-format';
import { showToast } from '@/features/toast/toastSlice';
import { contactsApi, useCreateContactMutation } from '@/features/contacts/contactsApi';
import validationSchema from './validationSchema';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import DevJSONView from '@/components/DevJSONView';

export const AddContactDialog = () => {
	const dispatch = useDispatch();
	const account = useSelector((state) => state.msalAccount.account);

	const open = useSelector((state) => state.dialog.open['contact']);

	const email = account?.username;
	const {
		data: userDetails,
	} = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});
	const hubspotId = userDetails?.hubspotId;

	const initialValues = {
		[FIRST_NAME]: '',
		[LAST_NAME]: '',
		[PHONE]: '',
		[EMAIL]: '',
		[CLIENT_NAME]: '',
		[OTHER_INFO]: '',
		errorMessage: ''
	};
    
	const [createContact] = useCreateContactMutation();

	const handleClose = () => {
		dispatch(closeDialog('contact'));
	};

	const handleCreateContact = async () => {
		const phone = formik.values[PHONE] ?? '';
		const { firstName, lastName, emailAddress: email, clientName } = formik.values;
		const body = {
			hubspotId,
			firstName,
			lastName,
			email,
			...(phone.length > 0 && { phoneNumber: phone }),
			...((clientName ?? '').length > 0 && { insuredName: clientName }),
		};
		console.log('📋 ~ Create contact object', body);
		const res = await createContact(body).unwrap();
		if (res?.contactId && !res?.existing) {
			await new Promise(resolve => setTimeout(resolve, 10000));
			try {
				const response = dispatch(contactsApi.util.invalidateTags([
					{ type: 'CONTACTS', id: 'LIST' }
				]));
				console.log('Invalidated tags', response);
			} catch (error) {
				console.error('Error invalidating API:', error);
			}
			dispatch(showToast({
				message: 'New prospect created!',
				action: {
					path: `/prospects/${res.contactId}`,
					label: 'GO TO PROSPECT',
				},
				autohide: false,
			}));
		}
		return res;
	};

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnBlur: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			console.log('📋 ~ Formik values', values);
			try {
				const res = await handleCreateContact();
				if (res?.contactId && !res?.existing) {
					console.log('🚀 ~ Successfully created contact', res);
					resetForm();
					handleClose();
				} else {
					console.log('🙅 ~ Error creating contact', res?.error);
					setFieldValue(
						'errorMessage',
						res?.existing ? 'Contact already exists' : res?.error?.message ?? 'Error creating contact. Please try again'
					);
				}
			} catch (err) {
				console.log('🙅 ~ Error creating contact', err);
				setFieldValue('errorMessage', 'Error creating contact. Please try again');
			}
			setSubmitting(false);
		},
	});

	const { getError, getErrorMessage } = useFormikHelper(formik);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			sx={{ minWidth: '50%', padding: '1em' }}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle>Create Prospect</DialogTitle>
				<DialogContent sx={{ paddingBottom: '2em' }}>
					<Grid container spacing={2}>
						<Grid size={{ xs: 12, md: 6 }}>
							<FormLabel required>{'First Name'}</FormLabel>
							<TextField
								fullWidth={true}
								name={FIRST_NAME}
								{...formik.getFieldProps(FIRST_NAME)}
								error={getError(FIRST_NAME)}
								helperText={getErrorMessage(FIRST_NAME)}
								disabled={formik.isSubmitting}
								required
							/>
						</Grid>
						<Grid size={{ xs: 12, md: 6 }}>
							<FormLabel required>{'Last Name'}</FormLabel>
							<TextField
								fullWidth
								required
								name={LAST_NAME}
								{...formik.getFieldProps(LAST_NAME)}
								error={getError(LAST_NAME)}
								helperText={getErrorMessage(LAST_NAME)}
								disabled={formik.isSubmitting}
							/>
						</Grid>
						<Grid size={{ xs: 12, md: 6 }}>
							<FormLabel required>{'Email Address'}</FormLabel>
							<TextField
								fullWidth
								name={EMAIL}
								{...formik.getFieldProps(EMAIL)}
								error={getError(EMAIL)}
								helperText={getErrorMessage(EMAIL)}
								required
								disabled={formik.isSubmitting}
								type="email"
							/>
							<FormHelperText>
                        We will use this email to contact you
							</FormHelperText>
						</Grid>
						<Grid size={{ xs: 12, md: 6 }}>
							<FormControl>
								<FormLabel>{'Phone Number'}</FormLabel>
								<NumericFormat
									customInput={TextField}
									fullWidth
									decimalScale={0}
									fixedDecimalScale
									allowNegative={false}
									allowLeadingZeros
									onValueChange={({ value }) => {
										formik.setFieldValue(PHONE, value);
									}}
									{...formik.getFieldProps(PHONE)}
									disabled={formik.isSubmitting}
									name={PHONE}
									error={getError(PHONE)}
									helperText={getErrorMessage(PHONE)}
								/>
							</FormControl>
						</Grid>
						<Grid size={12}>
							<FormLabel>{'Client'}</FormLabel>
							<TextField
								fullWidth
								{...formik.getFieldProps(CLIENT_NAME)}
								error={getError(CLIENT_NAME)}
								helperText={getErrorMessage(CLIENT_NAME)}
								disabled={formik.isSubmitting}
								name={CLIENT_NAME}
							/>
						</Grid>
					</Grid>
					{formik.values.errorMessage.length > 0 && (
						<FormHelperText error>{formik.values.errorMessage}</FormHelperText>
					)}
                    <DevJSONView value={formik.values} />
					{/* <DevJSONView value={formik.values} /> */}
				</DialogContent>
				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button
						onClick={() => {
							formik.resetForm();
							handleClose();
						}}
						color="primary"
					>
            Cancel
					</Button>
					<Button
						color="primary"
						type="submit"
						variant="contained"
						disabled={formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<CircularProgress size="2em" sx={{ color: '#ffffff' }} />
						) : (
							'Create'
						)}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
