/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useRef, useState } from 'react';
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Box,
	IconButton,
	FormHelperText,
	CircularProgress,
	Typography,
} from '@mui/material';
import { keyframes } from '@emotion/react';
import '@/styles/fileuploader.scss';
import { FileUploader } from 'react-drag-drop-files';
import { specialChars, folioBlue } from '@/utils/constants';
import { AttachFile } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const LoadingBox = () => {
	return (
		<Box
			width={'100%'}
			sx={{
				border: `2px dashed ${folioBlue}`,
				borderRadius: '5px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'rgba(0, 0, 0, 0.05)'
			}}
		>
			<Box sx={{ padding: '0.5em' }}>
				<CircularProgress />
			</Box>
		</Box>
	);
};

const FileUpload = ({ files, setFiles, loading, types, multiple }) => {
	const handleDragAndDrop = (file) => {
		const newFiles = Array.from(file);
		setFiles(multiple != false ? [...files, ...newFiles] : [file]);
	};

	const removeFile = (index) => {
		const newFiles = files.filter((_, i) => i !== index);
		setFiles(newFiles);
		console.log('📋 ~ Selected files after delete', newFiles);
	};

	return (
		<>
			{loading ? (
				<LoadingBox />
			) : (
				<FileUploader
					classes='drop_zone'
					handleChange={handleDragAndDrop}
					multiple={multiple != false}
					name='file'
					types={types}
				>
					<div
						style={{
							border: `2px dashed ${folioBlue}`,
							backgroundColor: 'white',
							borderRadius: '5px',
							display: 'flex',
							alignItems: 'center',
							padding: '1em',
							cursor: 'pointer'
						}}
					>
						<UploadFileIcon
							sx={{ fontSize: '2em', color: folioBlue, marginRight: '0.25em' }}
						/>
						<Typography variant='card_header'>
							<span style={{ textDecoration: 'underline' }}>{'Upload'}</span>
							{' or drop a file right here'}
						</Typography>
					</div>
				</FileUploader>
			)}
			{files.length > 0 && (
				<List dense>
					{files.map((file, i) => (
						<ListItem
							disablePadding
							key={`${file.name}`}
							secondaryAction={
								<IconButton
									disabled={loading}
									edge='end'
									aria-label='delete'
									onClick={() => removeFile(i)}
								>
									<DeleteIcon />
								</IconButton>
							}
						>
							<ListItemIcon>
								<AttachFile />
							</ListItemIcon>
							<ListItemText primary={`${file.name}`} />
						</ListItem>
					))}
				</List>
			)}
		</>
	);
};

export default FileUpload;
