import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { IS_DEV } from './environment';

const prodServer = {
	nearestNode: { 
		host: `${process.env.REACT_APP_TYPESENSE_HOST_URL}.a1.typesense.net`, 
		port: '443', 
		protocol: 'https'
	},
	nodes: [
		{ 
			host: `${process.env.REACT_APP_TYPESENSE_HOST_URL}-1.a1.typesense.net`, 
			port: '443', 
			protocol: 'https',
		},
		{ 
			host: `${process.env.REACT_APP_TYPESENSE_HOST_URL}-2.a1.typesense.net`, 
			port: '443', 
			protocol: 'https' 
		},
		{ 
			host: `${process.env.REACT_APP_TYPESENSE_HOST_URL}-3.a1.typesense.net`, 
			port: '443', 
			protocol: 'https' 
		},
	],
	apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
	cacheSearchResultsForSeconds: 2 * 60,
};

const server = IS_DEV ? {
	nodes: [
		{
			host: process.env.REACT_APP_TYPESENSE_HOST_URL,
			port: 443,
			protocol: 'https'
		}
	],
	apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
	cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
} : prodServer;

const clientSearchAdapter = new TypesenseInstantSearchAdapter({
	server,
	// The following parameters are directly passed to Typesense's search API endpoint.
	//  So you can pass any parameters supported by the search endpoint below.
	//  query_by is required.
	additionalSearchParameters: {
		query_by: 'name,contactName,contactEmail',
		sort_by: 'name:asc'
	},
});

export const searchClient = clientSearchAdapter.searchClient;

const dealSearchAdapter = new TypesenseInstantSearchAdapter({
	server,
	// The following parameters are directly passed to Typesense's search API endpoint.
	//  So you can pass any parameters supported by the search endpoint below.
	//  query_by is required.
	additionalSearchParameters: {
		query_by: 'dealName,clientName,description,contactName,claimNumber,policies.description,policies.policyNumber',
		sort_by: 'createDate:desc'
	},
});

export const dealClient = dealSearchAdapter.searchClient;

const invoiceSearchAdapter = new TypesenseInstantSearchAdapter({
	server,
	// The following parameters are directly passed to Typesense's search API endpoint.
	//  So you can pass any parameters supported by the search endpoint below.
	//  query_by is required.
	additionalSearchParameters: {
		query_by: 'clientName,salesTeamEmail,insuredInsight,insurerName,invoiceNumber,classCode',
		sort_by: 'createdWhenUnix:desc'
	},
});

export const invoiceClient = invoiceSearchAdapter.searchClient;