/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect } from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { setItemId, setBreadcrumbs } from '@/features/table/attachmentsTableSlice';
import { folioBlue } from '@/utils/constants';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { useGetUserDetailsQuery } from '@/features/user/userApi';

export const FolderBreadcrumbs = () => {
	const dispatch = useDispatch();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const { data: userDetails } = useGetUserDetailsQuery({ email }, { skip: !email });

	const { breadcrumbs, refreshIndicator } = useSelector((state) => state.attachmentsTable);

	const { getClientGraphItem } = useClientStorageHooks(userDetails);

	useEffect(() => {
		const resetBreadcrumbText = async () => {
			const newBreadcrumbs = await Promise.all(
				breadcrumbs.map(async (b) => {
					console.log('BREADCRUMB', b);
					return await getClientGraphItem(userDetails?.clientFolderDriveId, b.id).catch(
						(e) => console.log('Error getting updated breadcrumb', e, b)
					);
				})
			);
			console.log('NEW BREADCRUMBS', newBreadcrumbs);
			dispatch(setBreadcrumbs(newBreadcrumbs.filter((f) => f)));
		};
		resetBreadcrumbText();
	}, [refreshIndicator]);

	return (
		<Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} sx={{ mb: '0.5em' }}>
			{breadcrumbs.map((b, i) => {
				return (
					<Link
						component='button'
						key={`breadcrumb-${b.id}`}
						underline='hover'
						variant='task_updated'
						color={i !== breadcrumbs.length - 1 ? 'inherit' : 'textPrimary'}
						sx={{
							'&:hover': {
								color: folioBlue,
							},
						}}
						onClick={() => {
							if (i !== breadcrumbs.length - 1) {
								dispatch(setItemId(b.id));
								const newBreadcrumbs = breadcrumbs.slice(0, i + 1);
								dispatch(setBreadcrumbs(newBreadcrumbs));
							}
						}}
					>
						{b.name}
					</Link>
				);
			})}
		</Breadcrumbs>
	);
};
