import React, { useEffect, useMemo } from 'react';
import { Box, Card, IconButton, Link, Stack, Typography } from '@mui/material';
import {
	folioBlue,
	formatDate,
	formatKey,
	getPipelineName,
	getUrlFromPipeline,
} from '@/utils/constants';
import { useGetDealRowQuery } from '@/features/deals/dealsApi';
import { useGetClientRowQuery } from '@/features/clients/clientsApi';
import { determineContext } from '@/hooks/determineContext';
import { Link as RouterLink } from 'react-router-dom';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';

export const CreatedObjectCard = ({ activity }) => {
	const completedBy = activity?.completedBy?.name ?? 'Unknown';
	const owner = activity?.owner?.name ?? 'Unknown';

	const { data: dealRow, isLoading: dealRowLoading } = useGetDealRowQuery(activity.dealId, {
		skip: !activity.dealId,
	});

	const { deal, client, objectType } = determineContext();

	const isClient = objectType === 'client';

	const pipelineQuery = useGetPipelinePropertiesQuery(activity.deal?.pipeline, {
		skip: !activity.deal?.pipeline,
	});
	const pipelineName = `${pipelineQuery.data?.pipelines?.label}` ?? '';

	useEffect(() => {
		console.log('Activity [CreatedObjectCard]', activity, 'Deal ID', activity.dealId, dealRow);
	}, [activity, dealRow]);

	const date = useMemo(
		() => (activity ? new Date(activity.createdDate._seconds * 1000) : new Date()),
		[activity?.createdDate?._seconds]
	);

	const activityType = useMemo(() => {
		const dealName = dealRow?.dealName ?? activity.dealId;
		const createdSpan = (
			<span style={{ fontWeight: 'bold', marginLeft: '0.25em' }}>{'created'}</span>
		);
		const dealNameSpan = isClient && dealName && (
			<span style={{ fontWeight: 'normal', marginLeft: '0.25em' }}>{`"${dealName}"`}</span>
		);
		let text = '';
		switch (activity.activityType) {
		case 'newBusinessCreated':
			text = 'New Business';
			break;
		case 'newClaimCreated':
			text = 'New Claim';
			break;
		case 'newEndorsementCreated':
			text = 'New Endorsement';
			break;
		case 'newRenewalCreated':
			text = 'New Renewal';
			break;
		case 'dealDeleted':
			return <span style={{ fontWeight: 'bold' }}>{'Deal deleted'}</span>;
		case 'dealArchived':
			return <span style={{ fontWeight: 'bold' }}>{'Deal archived'}</span>;
		default:
			return (
				<span style={{ fontWeight: 'bold' }}>{formatKey(activity.activityType)}</span>
			); //
		}
		return (
			<span style={{ fontWeight: 'bold' }}>
				{text}
				{/* {dealNameSpan} */}
				{createdSpan}
			</span>
		);
	}, [dealRow, isClient]);

	const activityText =
		completedBy !== owner && activity?.owner ? `${completedBy} and assigned to ${owner}` : `${completedBy}`;

	const dealLink = () => {
		const path = getUrlFromPipeline(dealRow?.pipeline);
		return `${path}/${dealRow?.dealId}`;
	};

	const dealName =
		dealRow?.dealName?.length > 30 ? `${dealRow?.dealName.slice(0, 30)}...` : dealRow?.dealName;

	return (
		<Box mb='12px'>
			<Card>
				<Stack direction={'column'} p='10px' spacing={'0.5em'}>
					<Stack
						direction={'row'}
						sx={{
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Stack
							direction={'row'}
							sx={{
								// cursor: 'pointer',
								alignItems: 'center',
							}}
						>
							<Typography component='div' variant='task_body'>
								<span>
									{activityType}
									<span style={{ fontWeight: 'bold', marginRight: '0.25em' }}>
										{' '}
										by
									</span>
								</span>
								{activityText}
								<span style={{ margin: '0 0.5em' }}> | </span>
								<span style={{ fontWeight: '500' }}>
									{' '}
									{formatDate(date, true)}{' '}
								</span>
							</Typography>
							{objectType === 'client' && dealRow?.dealId && (
								<Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
									<Typography variant='task_body' noWrap>
										<span style={{ margin: '0 0.5em' }}>{' | '}</span>
										<Link
											to={dealLink()}
											component={RouterLink}
											sx={{
												textDecoration: 'none',
											}}
										>
											{`Deal name: ${dealName}`}
										</Link>
									</Typography>
								</Box>
							)}
						</Stack>
					</Stack>
					{activity.activityType == 'dealDeleted' ||
						(activity.activityType == 'dealArchived' && (
							<Stack>
								<Typography component='div' variant='task_updated'>
									<span>{`${pipelineName}: "${activity?.deal?.dealName}"`}</span>
								</Typography>
							</Stack>
						))}
					{[
						'newBusinessCreated',
						'newClaimCreated',
						'newEndorsementCreated',
						'newRenewalCreated',
					].includes(activity.activityType) &&
						!dealRow && (
						<Typography component='div' variant='task_updated'>
								Could not find related deal
						</Typography>
					)}
				</Stack>
			</Card>
		</Box>
	);
};
