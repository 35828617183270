import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	FormHelperText,
	TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { engagementsApi, useCreateNoteMutation } from '@/features/engagements/engagementsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import {
	ref,
	uploadBytes,
} from 'firebase/storage';
import { storage } from '@/utils/firebase';
import {
	formatDate,
	attachmentsFolderPath,
	attachmentsClientFolderPath,
	folderStructure,
	replaceFolderName,
} from '@/utils/constants';
import { jsPDF } from 'jspdf';
import FileUpload from '@/components/FileUpload';
import { dealsApi } from '@/features/deals/dealsApi';
import { triggerRefresh } from '@/features/table/attachmentsTableSlice';
import { determineContext } from '@/hooks/determineContext';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { ticketsApi } from '@/features/claims/ticketsApi';
import { generatePDF } from '@/utils/pdf';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { useMsGraph } from '@/hooks/useMsGraph';

const validationSchema = Yup.object().shape({
	body: Yup.string().required('Note is required.'),
	files: Yup.array(),
});

export const AddNoteDialog = () => {
	const dispatch = useDispatch();
	const account = useSelector((state) => state.msalAccount.account);

	const open = useSelector((state) => state.dialog.open['note']);

	const email = account?.username;
	const {
		data: userDetails,
	} = useGetUserDetailsQuery({ email }, {
		skip: !email,
	});

	const [createNote, { isLoading }] = useCreateNoteMutation();
	const { deal, client, contact, objectType, dealRow, sharepoint } = determineContext();

	const isClient = objectType == 'client';

	const { getClientDriveId, getDealDriveId, uploadClientSharepointFile } = useClientStorageHooks(userDetails);

	const { searchClientMsGraphFoldersByItemId } = useMsGraph();

	const handleClose = () => {
		dispatch(closeDialog('note'));
	};
	
	const handleCreateNote = async () => {
		const id = (objectType === 'client' ? client : objectType === 'contact' ? contact : deal)?.hs_object_id;
		const body = {
			dealId: id,
			noteBody: formik.values.body,
			hubspotId: userDetails?.hubspotId,
			objectType,
		};
		console.log('📋 ~ Create note object', body);
		const response = await createNote(body).unwrap();
		await uploadFilesToStorage(response.id);
		return response;
	};

	const formik = useFormik({
		initialValues: {
			body: '',
			files: [],
			errorMessage: '',
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		validateOnBlur: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			console.log('📋 ~ Formik values', values);
			try {
				const res = await handleCreateNote();
				if (res) {
					console.log('🚀 ~ Successfully created note', res);
					dispatch(triggerRefresh());
					resetForm();
					handleClose();
				} else {
					console.log('🙅 ~ Error creating note');
					setFieldValue(
						'errorMessage',
						'Error creating note. Please try again'
					);
				}
			} catch (err) {
				console.log('🙅 ~ Error creating note', err);
				setFieldValue('errorMessage', 'Error creating note. Please try again');
			}
			setSubmitting(false);
		},
	});

	const generatePDFAndUpload = async (noteBody, driveId) => {
		const date = new Date();
		const file = generatePDF(noteBody, `Created Note - ${formatDate(date, true).replace(':', '.')}`);
		console.log('FILE', file);
		
		let noteDriveId = dealRow?.noteDriveId ?? client?.clientRow?.noteDriveId;
		try {
			if (!noteDriveId) {
				const path = isClient ? folderStructure.clientNotes : folderStructure.notes;
				const drives = await searchClientMsGraphFoldersByItemId(userDetails?.clientFolderDriveId, driveId, path);
				console.log('[searchClientGraphFolders] FOLDERS', drives);
				const drive = drives.find(d => d?.folder && d?.name == path);
				noteDriveId = drive?.id;
			}
			console.log('NOTE DRIVE ID', noteDriveId);
			if (noteDriveId) {
				await uploadClientSharepointFile(noteDriveId, file).catch(e => console.error('Error uploading generated pdf file via Graph API', file, e));
			}
		} catch (error) {
			console.log('🙅 ~ Error creating file', error);
		}
	};

	const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

	const uploadFilesToStorage = async (noteId) => {
		const type = objectType.charAt(0).toUpperCase() + objectType.slice(1);
		const id = (objectType === 'client' ? client : objectType === 'contact' ? contact : deal)?.hs_object_id;
		

		const attachmentsPath = isClient || objectType == 'contact' ? attachmentsClientFolderPath : attachmentsFolderPath;

		let { driveId } = isClient ? await getClientDriveId(id) : objectType == 'contact' ? { driveId: null } : await getDealDriveId(id);
		const path = decodeURI(folderStructure.clientAttachments.normalize());

		const parentDriveId = driveId;
		if (isClient) {
			const drives = await searchClientMsGraphFoldersByItemId(userDetails?.clientFolderDriveId, driveId, path);
			console.log('[searchClientGraphFolders] FOLDERS', drives);
			const drive = drives.find(d => d?.folder && d?.name == path);
			driveId = drive?.id;
		}

		await Promise.all(formik.values.files.map(async file => {
			if (driveId) {
				await uploadClientSharepointFile(driveId, file).catch(e => console.error('Error uploading file via Graph API', file, e));
			}
            
			const filePath = `/${attachmentsPath}/${id}/${file.name}`;
			const storageRef = ref(storage, filePath);
			const metadata = {
				customMetadata: {
					[`${type} ID`]: id.toString(),
					'Note ID': noteId.toString(),
				},
			};
			await uploadBytes(storageRef, file, metadata);
		})).then(r => {
			console.log(`Uploaded ${formik.values.files.length} files`, formik.values.files, r);
		});
        
		if (objectType != 'contact') {
			await generatePDFAndUpload(formik.values.body, parentDriveId);
		}
		setTimeout(async () => {
			try {
				const uppercaseType = (objectType ?? '').toUpperCase();
				dispatch((objectType == 'deal' ? dealsApi : ticketsApi).util.invalidateTags([
					{ type: `${uppercaseType}S`, id: 'LIST' },
					{ type: `${uppercaseType}S`, id: deal?.hs_pipeline_stage ?? deal?.dealstage },
					{ type: uppercaseType, id },
				]));
				dispatch(dealsApi.util.invalidateTags([
					{ type: 'DEAL_ROW', id: id },
				]));
				dispatch(engagementsApi.util.invalidateTags([
					{ type: 'ENGAGEMENTS', id: id },
					{ type: 'TASKS', id: 'LIST' },
				]));
				dispatch(triggerRefresh());
			} catch (error) {
				console.error('Error invalidating API:', error);
			}
		}, 2000);
	};
	
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			PaperProps={{
				sx: {
					minWidth: { xs: '100%', sm: '70%', md: '50%' },
					padding: '1em'
				}
			}}
		>
			<form onSubmit={formik.handleSubmit} >
				<DialogTitle>Create Note</DialogTitle>
				<DialogContent sx={{ paddingBottom: '2em' }}>
					<Grid container spacing={2}>
						{objectType != 'contact' && <Grid size={12}>
							<FileUpload 
								files={formik.values.files} 
								setFiles={(files) => formik.handleChange({
									target: {
										name: 'files',
										value: files,
									},
								})}
								loading={formik.isSubmitting}
							/>
						</Grid>}
						<Grid size={12}>
							<TextField
								fullWidth={true}
								name="body"
								placeholder="Type here ..."
								multiline
								rows={4}
								{...formik.getFieldProps('body')}
								error={formik.touched.body && Boolean(formik.errors.body)}
								helperText={formik.touched.body && formik.errors.body}
								disabled={formik.isSubmitting}
								required
							/>
						</Grid>
					</Grid>
					{formik.values.errorMessage.length > 0 && (
						<FormHelperText error>{formik.values.errorMessage}</FormHelperText>
					)}
					{/* <DevJSONView value={formik.values} /> */}
				</DialogContent>
				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button
						onClick={() => {
							formik.resetForm();
							handleClose();
						}}
						color="primary"
					>
            Cancel
					</Button>
					<Button
						color="primary"
						type="submit"
						variant="contained"
						disabled={isLoading || formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<CircularProgress size="2em" sx={{ color: '#ffffff' }} />
						) : (
							'Create'
						)}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
