/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CircularProgress,
	Collapse,
	IconButton,
	Link,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Delete, Toys } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { folioBlue, formatDate, getUrlFromPipeline } from '@/utils/constants';
import { determineContext } from '@/hooks/determineContext';
import { useDispatch } from 'react-redux';
import { openDialog } from '@/features/dialog/dialogSlice';
import EditIcon from '@mui/icons-material/Edit';

const EngagementsAccordion = ({
	title,
	expandedContent,
	content,
	contentPrefix,
	footerContent,
	engagement,
	expandedByDefault = false,
	lastUpdated,
	loading,
	handleDelete,
	editable = false,
}) => {
	const [expanded, setExpanded] = useState(expandedByDefault);

	const { objectType } = determineContext();
	const dispatch = useDispatch();

	const toggleExpanded = () => setExpanded((prev) => !prev);

	const associatedDeal = engagement?.data?.ticket ?? engagement?.data?.deal;
	const dealParams = {
		id: associatedDeal?.id,
		name:
      associatedDeal?.properties?.dealname ??
      associatedDeal?.properties?.subject,
		pipeline:
      associatedDeal?.properties?.pipeline ??
      associatedDeal?.properties?.hs_pipeline,
	};

	const dealLink = () => {
		const path = getUrlFromPipeline(dealParams.pipeline);
		return `${path}/${dealParams.id}`;
	};

	useEffect(() => console.log('engagement: ', engagement), [engagement]);

	
	const handleEngagementOpen = () => {
		//change dynamically later on if notes etc is going to editable this way
		console.log('opened task! ');
		dispatch(openDialog({ dialogName: 'task', data: { engagement } }));
	};

	return (
		<Card
			sx={{ marginBottom: '12px', borderRadius: '4px', boxShadow: 1, p: '1em' }}
		>
			<Stack
				direction={'row'}
				sx={{
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Stack
					direction={'row'}
					sx={{
						cursor: 'pointer',
						alignItems: 'center',
					}}
				>
					<Stack
						direction={'row'}
						sx={{
							cursor: 'pointer',
							alignItems: 'center',
							// flex: 'none',
						}}
						onClick={toggleExpanded}
					>
						<ArrowForwardIosSharpIcon
							sx={{
								fontSize: '0.9rem',
								color: folioBlue,
								transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
								transition: 'transform 0.3s ease',
								mr: '0.5em',
							}}
						/>
						<Typography component="div" variant="task_body">
							{title}
						</Typography>
					</Stack>
					{objectType === 'client' && dealParams.id && (
						<Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
							<Typography variant="task_body" noWrap>
								<span style={{ margin: '0 0.5em' }}>{' | '}</span>
								<Link
									to={dealLink()}
									component={RouterLink}
									sx={{
										textDecoration: 'none',
									}}
								>
									{`Deal name: ${
										dealParams?.name?.length > 30
											? `${dealParams.name.slice(0, 30)}...`
											: dealParams?.name
									}`}
								</Link>
							</Typography>
						</Box>
					)}
				</Stack>
				{(editable || handleDelete) && (
					<Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={1}>
						{editable && (
							<IconButton
								onClick={handleEngagementOpen}
								sx={{ color: folioBlue, position: 'relative' }}
							>
								<EditIcon />
							</IconButton>
						)}
						{handleDelete && (
							<>
								{loading ? (
									<Box padding="0.76rem">
										<CircularProgress size="1rem" />
									</Box>
								) : (
									<IconButton
										aria-label="delete"
										onClick={handleDelete}
										sx={{ color: folioBlue, position:'relative' }}
									>
										<Delete />
									</IconButton>
								)}
							</>
						)}
					</Stack>
				)}
			</Stack>
			{(content || contentPrefix) && (
				<Collapse
					in={expanded}
					collapsedSize={contentPrefix ? '3em' : '2em'}
					sx={{ width: '100%', height: '100%' }}
				>
					<Stack direction="row" alignItems="center" width="100%" height="100%">
						{contentPrefix && (
							<Box display="flex" alignItems="center" mr={1}>
								{contentPrefix}
							</Box>
						)}

						<Typography
							variant="task_body"
							component="div"
							sx={{
								flexGrow: 1,
								display: 'block',
								width: '100%',
								whiteSpace: expanded ? 'normal' : 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{content}
						</Typography>
					</Stack>
				</Collapse>
			)}
			{expandedContent && (
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<Stack px="1em">{expandedContent}</Stack>
				</Collapse>
			)}
			<Stack
				direction={'row'}
				justifyContent={footerContent ? 'space-between' : 'flex-end'}
				alignItems={'center'}
				pt="1em"
				spacing={2}
			>
				{footerContent && footerContent}
				<Typography variant="task_updated">
					{`Last updated: ${formatDate(lastUpdated, true)}`}
				</Typography>
			</Stack>
		</Card>
	);
};

export default EngagementsAccordion;
