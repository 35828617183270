/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useState } from 'react';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Paper,
	Stack,
	TablePagination,
	Typography,
	FormControlLabel,
	Switch,
	IconButton,
	Tooltip,
} from '@mui/material';
import { SearchFolioHead } from '@/components/table/FolioTableHead';
import { PolicyTransactionRow } from '@/components/tables/PolicyTransactionTable/PolicyTransactionRow';
import { OldPolicyTransactionRow } from '@/components/tables/PolicyTransactionTable/OldPolicyTransactionRow';
import { TableProgressIndicator } from '@/components/table/TableProgressIndicator';
import { determineContext } from '@/hooks/determineContext';
import { AddNewBusinessButton } from '@/components/buttons/AddNewBusinessButton';
import { InstantSearch, Configure } from 'react-instantsearch';
import { dealClient } from '@/utils/typesense';
import { InstantSearchFieldAdaptor } from '@/components/SearchField';
import { HitsTableBody } from '@/components/table/HitsTableBody';
import { HitsTablePagination } from '@/components/table/TablePaginationActions';
import { rowLimits } from '@/components/table/data';
import { useDispatch, useSelector } from 'react-redux';
import { setExpandPolicies } from '@/features/groupView/groupViewSlice';
import { SwitchLovely } from '@/components/SwitchLovely';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { folioBlue } from '@/utils/constants';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';

export const PolicyTransactionTab = () => {
	const dispatch = useDispatch();

	const { deals, claims, loading, client } = determineContext();

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'policyTransactionTable'});
	const showNew = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const policyHeadCells = useMemo(() => [
		...(showNew ? [{ id: 'id', numeric: false, label: showNew ? null : '', align: 'left', sortable: false }] : []),
		{ id: 'dealName', numeric: false, label: 'Deal Name', align: 'left', sortable: showNew },
		{ id: 'policies', numeric: false, label: 'Policies', align: 'left', sortable: false },
		...(showNew ? [{ id: 'renewalDate', numeric: false, label: 'Renewal Date', align: 'right', sortable: true }] : []),
		{ id: 'status', numeric: false, label: 'Status', align: 'left', sortable: false },
		{ id: 'pipeline', numeric: false, label: 'Pipeline', align: 'left', sortable: false },
		{ id: 'button', numeric: false, label: '', align: 'left', sortable: false },
	], [showNew]);

	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('dealName');

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const isLoading = loading?.client || loading?.deals;

	const { expandPolicies } = useSelector((state) => state.userPrefs);

	const handleChangePage = (event, newPage) => setPage(newPage);
	
    const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// const reducedMergedDeals = useMemo(() => {
	// 	const mergedDeals = [...deals, ...(claims || [])];
	// 	return mergedDeals.flatMap((deal) => ({
	// 		deal,
	// 		policy: deal?.policyResults?.[0]?.policy || null,
	// 		transaction: deal?.policyResults?.[0]?.transaction || null,
	// 	}));
	// }, [deals, claims]);

	const filterSchema = useMemo(() => {
		const clientFilter = `clientHubspotId := ${client?.hs_object_id}`;
		const pipelineFilter = `pipeline :!= ${process.env.REACT_APP_PIPELINE_CLAIMS}`;
		const schema = {
			filters: `${clientFilter} && ${pipelineFilter}`,
		};
		console.log('Filters:', schema.filters);
		return schema;
	}, [client]);

	const sortItems = policyHeadCells.reduce((acc, cell, index) => {
		if (index === 0) {
			acc.push({ value: 'deals', label: cell.label });
		}
		if (cell.sortable !== false && !cell.frontEndSort) {
			acc.push({ value: `deals/sort/${cell.id}:asc`, label: `${cell.label} (asc)` });
			acc.push({ value: `deals/sort/${cell.id}:desc`, label: `${cell.label} (desc)` });
		}
		return acc;
	}, []);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		console.log('PROPERTY', property);
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	return (
		<InstantSearch indexName='deals' searchClient={dealClient}>
			<Configure {...filterSchema} />
			<TableContainer component={Paper}>
				<Stack direction='column' spacing={1}>
					<Stack direction='row' justifyContent='space-between' p={1} spacing={2}>
						<Box sx={{ maxWidth: 600, width: '100%' }}>
							<InstantSearchFieldAdaptor />
						</Box>
						<AddNewBusinessButton />
					</Stack>
					{/* <Box display='flex' justifyContent={'flex-end'}>
						<FormControlLabel
							control={
								<SwitchLovely
									checked={expandPolicies}
									onChange={(e) => {
										dispatch(setExpandPolicies(e.target.checked));
									}}
								/>
							}
							label='Expand Policies'
							sx={{
								'& .MuiTypography-root': {
									fontWeight: 500,
									fontSize: '12px',
								},
							}}
						/>
					</Box> */}
				</Stack>

				<Box>
					<Table>
						<SearchFolioHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							headCells={policyHeadCells}
							items={sortItems}
							{...showNew && {
								CustomCheckbox: <Tooltip title={`${expandPolicies ? 'Hide' : 'Expand'} all policy rows`}><IconButton
									aria-label='expand rows'
									size='small'
									onClick={() => dispatch(setExpandPolicies(!expandPolicies))}
								>
									{expandPolicies ? (
										<KeyboardArrowUpIcon sx={{ color: folioBlue }} />
									) : (
										<KeyboardArrowDownIcon />
									)}
								</IconButton></Tooltip>
							}}
							// CustomCheckbox={<Tooltip title={`${expandPolicies ? 'Hide' : 'Expand'} all policy rows`}><IconButton
							// 	aria-label='expand rows'
							// 	size='small'
							// 	onClick={() => dispatch(setExpandPolicies(!expandPolicies))}
							// >
							// 	{expandPolicies ? (
							// 		<KeyboardArrowUpIcon sx={{ color: folioBlue }} />
							// 	) : (
							// 		<KeyboardArrowDownIcon />
							// 	)}
							// </IconButton></Tooltip>}
						/>
						<HitsTableBody
							TableRowComponent={showNew ? PolicyTransactionRow : OldPolicyTransactionRow}
							headCells={policyHeadCells}
							order={order}
							orderBy={orderBy}
							includePolicies={true}
							ErrorRowComponent={() => (
								<TableRow>
									<TableCell colSpan={policyHeadCells.length} align='center'>
										<Typography variant='subtitle1'>
											There was an error. Please try again.
										</Typography>
									</TableCell>
								</TableRow>
							)}
							EmptyRowComponent={() => (
								<TableRow>
									<TableCell colSpan={policyHeadCells.length} align='center'>
										<Typography variant='subtitle1'>{'No deals'}</Typography>
									</TableCell>
								</TableRow>
							)}
						/>
					</Table>
				</Box>
				<HitsTablePagination
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					items={rowLimits.map((item) => ({
						label: `${item} rows per page`,
						value: item,
						default: item == rowsPerPage,
					}))}
				/>
			</TableContainer>
		</InstantSearch>
	);
};
