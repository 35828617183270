import React, { useEffect, useState } from 'react';
import {
	IconButton,
	Menu,
	MenuList,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
	CircularProgress,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArchiveIcon from '@mui/icons-material/Archive';
import { determineContext } from '@/hooks/determineContext';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import { useDeleteDealMutation, useUpdateDealRowMutation } from '@/features/deals/dealsApi';
import { useNavigate } from 'react-router-dom';
import { DEAL_STATUS_TYPES, getUrlFromPipeline } from '@/utils/constants';
import { useDispatch } from 'react-redux';
import { showToast } from '@/features/toast/toastSlice';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { useGetUserDetailsQuery } from '@/features/user/userApi';

const DealMenu = ({ setIsEditing }) => {
	const { deal, dealRow, objectType } = determineContext();

	const ownerId = dealRow?.ownerId ?? deal?.hubspot_owner_id;

	const userQuery = useGetUserDetailsQuery({ hubspotId: ownerId }, { skip: !ownerId });

	const hasDependentDeal =
		(dealRow?.status && dealRow?.status != DEAL_STATUS_TYPES.notStarted) || dealRow?.nextDealId;

	const dealId = deal?.id ?? deal?.hs_object_id;

	const { renameClientSharepointItem, getClientGraphItem } = useClientStorageHooks(
		userQuery.data
	);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [showDialog, setShowDialog] = useState(false);
	const [showArchiveDialog, setShowArchiveDialog] = useState(false);

	const [deleteDeal] = useDeleteDealMutation({
		fixedCacheKey: `delete-deal-${dealId}-${objectType}`,
	});
	const [updateDeal, { isLoading }] = useUpdateDealRowMutation();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const archiveDeal = async () => {
		setShowArchiveDialog(false);
		await updateDeal({
			dealId: dealRow.dealId,
			docId: dealRow.id,
			properties: {
				status: DEAL_STATUS_TYPES.archived,
				isRenewable: false,
			},
		}).unwrap();
	};

	const handleDeleteConfirmation = async () => {
		setShowDialog(false);
		const path = getUrlFromPipeline(deal?.pipeline ?? deal?.hs_pipeline);
		dispatch(
			showToast({
				message: `Deleting deal ${deal?.id ?? deal?.hs_object_id} '${
					deal?.dealname ?? dealRow?.dealName
				}' ...`,
				autohide: false,
				loading: true,
			})
		);
		navigate(path);
		// await deleteDeal({ dealId, objectType })
		deleteDeal({ dealId, objectType })
			.unwrap()
			.then(async (res) => {
				try {
					const item = await getClientGraphItem(
						userQuery.data?.clientFolderDriveId,
						dealRow?.driveId
					);
					console.log('ITEM', item);
					await renameClientSharepointItem(
						item.parentReference.driveId,
						item.id,
						`[DELETED] - ${item.name}`
					);
				} catch (e) {
					console.log('Error updating item name', e);
				}
				dispatch(
					showToast({
						message: `Deal ${deal?.id ?? deal?.hs_object_id} successfully deleted.`,
						success: true,
					})
				);
				// navigate(path);
			})
			.catch((err) => {
				console.log(`Error deleting deal ${deal?.id ?? deal?.hs_object_id}`, err);
				dispatch(
					showToast({
						message: 'Error deleting deal; please try again.',
						error: true,
					})
				);
			});
	};

	return (
		<>
			<IconButton
				id='deal-menu-button'
				aria-controls={open ? 'deal-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id='deal-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'deal-menu-button',
				}}
			>
				<MenuItem
					onClick={() => {
						setIsEditing(true);
						handleClose();
					}}
				>
					<ListItemIcon>
						<EditIcon />
					</ListItemIcon>
					<ListItemText>Edit deal name</ListItemText>
				</MenuItem>
				{![
					DEAL_STATUS_TYPES.archived,
					DEAL_STATUS_TYPES.endorsementCreated,
					DEAL_STATUS_TYPES.renewalCreated,
				].includes(dealRow?.status) && (
					<Tooltip title={'Archiving a deal removes it from your Renewals list'}>
						<MenuItem
							disabled={isLoading}
							onClick={() => {
								setShowArchiveDialog(true);
								handleClose();
							}}
						>
							<ListItemIcon>
								<ArchiveIcon />
							</ListItemIcon>
							<ListItemText sx={{ fontWeight: 'bold' }}>Archive deal</ListItemText>
						</MenuItem>
					</Tooltip>
				)}
				<Tooltip
					title={
						hasDependentDeal
							? 'Cannot delete deal as it has a dependent deal (e.g. a renewal or endorsement)'
							: ''
					}
				>
					<MenuItem
						disabled={hasDependentDeal || userQuery.isLoading || !dealRow.driveId}
						onClick={() => {
							setShowDialog(true);
							handleClose();
						}}
					>
						<ListItemIcon sx={{ color: 'red' }}>
							{userQuery.isLoading || !dealRow.driveId ? (
								<CircularProgress size='1em' />
							) : (
								<Delete />
							)}
						</ListItemIcon>
						<ListItemText sx={{ fontWeight: 'bold', color: 'red' }}>
							Delete deal
						</ListItemText>
					</MenuItem>
				</Tooltip>
			</Menu>
			{showDialog && (
				<ConfirmDialog
					openDialog={showDialog}
					handleClose={() => setShowDialog(false)}
					handleConfirm={handleDeleteConfirmation}
					actionName={'delete'}
				/>
			)}
			{showArchiveDialog && (
				<ConfirmDialog
					openDialog={showArchiveDialog}
					handleClose={() => setShowArchiveDialog(false)}
					handleConfirm={archiveDeal}
					actionName={'archive deal'}
					canBeUndone={true}
					additionalContent={'Archiving a deal removes it from your Renewals list.'}
				/>
			)}
		</>
	);
};

export default DealMenu;
