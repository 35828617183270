/* eslint-disable no-mixed-spaces-and-tabs */
import { Button, Card, Tab, Tabs, Skeleton, Box, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import React, { useEffect, useMemo, useState } from 'react';
import { Add } from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { getCreateUrlFromPipeline } from '@/utils/constants';
import DealTable from '@/components/tables/DealTable';
import { useNavigate } from 'react-router-dom';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { useDispatch, useSelector } from 'react-redux';
import { setSelected } from '@/features/table/dealsTableSlice';
import { ArchiveDealsButton } from '@/components/buttons/ArchiveDealsButton';

export const DealTableView = ({ pipelineId }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { selected } = useSelector((state) => state.dealTable);

	const [currentTab, setCurrentTab] = useState('active');

	const isRenewals = pipelineId === process.env.REACT_APP_PIPELINE_RENEWALS;

	useEffect(() => {
		dispatch(setSelected([]));
	}, []);

	// Feature flag query for renewals
	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'renewals' });
	const isFeatureFlagLoading = featureFlagQuery.isLoading;
	const showNewRenewalsFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	// Fetching pipeline properties
	const { data: pipelineData, isLoading: isPipelineLoading } = useGetPipelinePropertiesQuery(
		pipelineId,
		{ skip: !pipelineId }
	);
	const pipelineName = pipelineData?.pipelines?.label;
	const isLoading = isPipelineLoading || isFeatureFlagLoading;

	const isClaims = pipelineId == process.env.REACT_APP_PIPELINE_CLAIMS;
	const isEndorsement = pipelineId == process.env.REACT_APP_PIPELINE_ENDORSEMENTS;

	const createPipelineName = useMemo(() => {
		if (pipelineId == process.env.REACT_APP_PIPELINE_NEW_BUSINESS) {
			return pipelineName;
		} else {
			return `New ${pipelineName?.substring(0, pipelineName.length - 1)}`;
		}
	}, [pipelineId, pipelineName]);

	const handleNavigation = () => {
		const createUrl = getCreateUrlFromPipeline(pipelineId);
		if (createUrl) {
			const params =
				selected.length > 0
					? {
						state: {
							dealIds: selected.map((d) => d.id),
							clientId: selected[0].clientHubspotId,
							// policies: selected.flatMap((d) => d.policies),
						},
					  }
					: null;
			console.log('PARAMS', params);
			navigate(createUrl, params);
		}
	};

	const tabs = showNewRenewalsFeature
		? [
			{
				label: isRenewals ? pipelineName : `Active ${pipelineName}`,
				value: 'active',
			},
			...(isClaims
				? [
					{
						label: 'Closed',
						value: 'closed',
					},
					  ]
				: []),
		  ]
		: [
			{
				label: `Active ${pipelineName}`,
				value: 'active',
			},
			{
				label: isClaims ? 'Closed' : 'In Term',
				value: 'closed',
			},
		  ];

	const handleTabChange = (event, value) => {
		event.preventDefault();
		setCurrentTab(value);
	};

	const renewalDisabledDueToClient = selected.length > 0 && new Set(selected.map(d => d.clientHubspotId)).size !== 1;
	const renewalDisabled = renewalDisabledDueToClient || selected.some(d => !d.isRenewable);

	const nonRenewableDeals = selected.filter(d => !d.isRenewable);
    

	return (
		<Card>
			<Grid container>
				<Grid size={'grow'}>
					{isLoading ? (
						<Skeleton
							variant='rectangular'
							height={48}
							width='100%'
							sx={{ px: 2, borderRadius: 1 }}
						/>
					) : (
						<Tabs
							indicatorColor='primary'
							scrollButtons='auto'
							textColor='primary'
							value={currentTab}
							sx={{ px: 2 }}
							variant='scrollable'
							onChange={handleTabChange}
						>
							{tabs.map((tab) => (
								<Tab
									sx={{ fontWeight: 'bold' }}
									key={tab.value}
									label={tab.label}
									value={tab.value}
								/>
							))}
						</Tabs>
					)}
				</Grid>
				<Grid size={5} container justifyContent={'flex-end'} mr={2} py={1} spacing={1}>
					{selected.length > 0 && isRenewals && showNewRenewalsFeature && (
						<Box>
							<ArchiveDealsButton />
							{/* <Button
								variant='outlined'
								sx={{ borderRadius: '15px' }}
								startIcon={<ArchiveIcon />}
								onClick={() => {}}
							>
								<span>
									{'Archive'}
									{selected.length > 0 && (
										<span
											style={{ marginLeft: '0.25em' }}
										>{`(${selected.length})`}</span>
									)}
								</span>
							</Button> */}
						</Box>
					)}
					<Tooltip title={nonRenewableDeals.length > 0 ? `${nonRenewableDeals.length} deal${nonRenewableDeals.length > 1 ? 's are' : ' is'} not currently renewable` : renewalDisabledDueToClient ? 'Please select deals from one client in order to bundle renewals' : ''}>
						<Box>
							<Button variant='contained' disabled={renewalDisabled} startIcon={<Add />} onClick={handleNavigation}>
								<span>
									{createPipelineName}
									{selected.length > 0 && (
										<span
											style={{ marginLeft: '0.25em' }}
										>{`(${selected.length})`}</span>
									)}
								</span>
							</Button>
						</Box>
					</Tooltip>
				</Grid>
			</Grid>
			{!isLoading && (
				<DealTable
					tab={currentTab}
					{...((!isRenewals || !showNewRenewalsFeature) && { pipeline: pipelineId })}
				/>
			)}
		</Card>
	);
};
