// features/table/claimsTableSlice.js
import { generateInitialState, createTableSlice } from './tableSlice';

const claimsTableInitialState = generateInitialState();
export const claimsTableSlice = createTableSlice('claimTable', claimsTableInitialState);

export const { 
	setPageAfter,
	setRowsPerPage,
	setOpenDialog,
	setSearchValue,
	setSearchText,
	setOrderBy,
	setOrder,
	setPage,
	setRows,
	resetSearchFields,
	changeRowsPerPage,
	requestSort,
	changePage,
	resetAllPages,
	onOrderChange,
	filterByInsurerType,
	triggerRefresh,
} = claimsTableSlice.actions;

export default claimsTableSlice.reducer;