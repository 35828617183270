/* eslint-disable no-mixed-spaces-and-tabs */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useGetDealRowQuery } from '@/features/deals/dealsApi';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs, setItemId, triggerRefresh } from '@/features/table/attachmentsTableSlice';
import { determineIfFolderDeletable, determineIfCanCreateFolder } from '@/utils/folders';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { AddFolderSharepointDialog } from '@/components/dialogs/AddFolderSharepointDialog';
import { MoveFileDialog } from '@/components/dialogs/MoveFileDialog';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import { showToast } from '@/features/toast/toastSlice';
import { FileUploadMSGraph } from '@/components/FileUploadMSGraph';
import { FileExistsDialog } from '@/components/dialogs/FileExistsDialog';
import { attachmentsClientFolderPath, attachmentsFolderPath } from '@/utils/constants';
import { ref, uploadBytes } from 'firebase/storage';
import { storage } from '@/utils/firebase';
import { RenameFileDialog } from '@/components/dialogs/RenameFileDialog';

const initialState = {
	deal: {},
	client: {},
	sharepoint: {},
	isLoading: true,
	isError: false,
	dealRow: {},
	files: [],
	canCreate: false,
	userDriveId: null,
	handleDelete: async () => {},
	handleMove: async (newParentId) => {},
	handleUploadFile: async (file) => {},
	handleRenameFile: async (item, newName) => {},
	handleUpdateFileMetadata: async (item, metadata) => {},
};

const AttachmentsContext = createContext(initialState);

export const useAttachmentsContext = () => {
	const context = useContext(AttachmentsContext);
	if (!context) {
		throw new Error('useAttachmentsContext must be used within an AttachmentsContextProvider');
	}
	return context;
};

export const AttachmentsContextProvider = ({ deal, client, children }) => {
	const ownerId = deal?.hubspot_owner_id ?? client?.hubspot_owner_id;
	const userQuery = useGetUserDetailsQuery({ hubspotId: ownerId }, { skip: !ownerId });
	const dispatch = useDispatch();

	const clientRow = client?.clientRow;
	const [loading, setLoading] = useState(true);
	const [files, setFiles] = useState([]);

	const dealRowQuery = useGetDealRowQuery(deal?.hs_object_id ?? deal?.id, {
		skip: !deal?.hs_object_id && !deal?.id,
	});

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'folderView' });
	const showNewFoldersFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const { refreshIndicator, itemId, breadcrumbs, searchValue, selectedRows } = useSelector(
		(state) => state.attachmentsTable
	);

	const canCreate = useMemo(() => {
		const currentItem = breadcrumbs[breadcrumbs.length - 1];
		if (breadcrumbs.length === 1) {
			// Can create from deal root folder but not client
			return deal != null;
		} else if (currentItem) {
			const cannotCreate = determineIfCanCreateFolder(currentItem);
			return !cannotCreate;
		} else {
			return false;
		}
	}, [breadcrumbs, deal]);

	const {
		getClientGraphFolders,
		getClientGraphFolderByItemId,
		searchClientGraphFolders,
		deleteClientSharepointItem,
		moveClientSharepointItem,
		renameClientSharepointItem,
		uploadClientSharepointFile,
		updateClientSharepointItemMetadata,
	} = useClientStorageHooks(userQuery.data);

	const isLoading =
		userQuery.isLoading ||
		userQuery.isUninitialized ||
		(deal && dealRowQuery.isLoading) ||
		featureFlagQuery.isLoading ||
		featureFlagQuery.isUninitialized ||
		loading;
	const isError = userQuery.isError || !ownerId;

	const sharepoint = useMemo(() => {
		const isValidSharepoint =
			userQuery.data?.sharepointSite != null &&
			userQuery.data?.clientSiteSuffix != null &&
			userQuery.data?.clientFolder != null &&
			userQuery.data?.clientSite != null;
		return {
			sharepointSite: userQuery.data?.sharepointSite,
			clientSiteSuffix: userQuery.data?.clientSiteSuffix,
			clientFolder: userQuery.data?.clientFolder,
			clientSite: userQuery.data?.clientSite,
			isValid: isValidSharepoint,
		};
	}, [userQuery.data]);

	const getFolderPaths = (folders) => {
		const folderMap = folders.reduce((acc, folder) => {
			acc[folder.id] = folder;
			return acc;
		}, {});

		const buildPath = (folder) => {
			const { name, parentReference } = folder;

			if (!parentReference || !parentReference.id) {
				return name;
			}

			const parentFolder = folderMap[parentReference.id];
			if (parentFolder) {
				return `${buildPath(parentFolder)}/${name}`;
			}

			return name;
		};

		return folders.map((folder) => buildPath(folder));
	};

	const baseSite = useMemo(() => {
		if (sharepoint?.clientSite && sharepoint?.clientFolder) {
			try {
				return decodeURI(`${sharepoint.clientSite}/${sharepoint.clientFolder}`);
			} catch (e) {
				console.log('Error decoding sharepoint URL', e);
				return `${sharepoint.clientSite}/${sharepoint.clientFolder}`;
			}
		}
		return '';
	}, [sharepoint?.clientSite, sharepoint?.clientFolder]);

	useEffect(() => {
		const rowId = deal ? dealRowQuery.data?.driveId : clientRow?.driveId;
		dispatch(setItemId(rowId));
	}, [clientRow?.driveId, dealRowQuery.data?.driveId]);

	useEffect(() => {
		const updateMsGraph = async () => {
			// const { files, folders } = await getFoldersFunction(userQuery.data?.clientFolderDriveId, itemId, searchValue);
			const { files, folders } =
				showNewFoldersFeature == false
					? await getClientGraphFolders(clientRow)
					: searchValue.length > 0
						? await searchClientGraphFolders(
							userQuery.data?.clientFolderDriveId,
							itemId,
							searchValue
					  )
						: await getClientGraphFolderByItemId(
							userQuery.data?.clientFolderDriveId,
							itemId
					  );

			const filesAndFolders = showNewFoldersFeature ? files.concat(folders) : [...files];
			const folderPaths = getFolderPaths(folders);

			const nextBreadcrumb = filesAndFolders?.[0]?.parentReference;
			// console.log('NEXT breadcrumb', nextBreadcrumb, filesAndFolders, breadcrumbs);
			if (nextBreadcrumb && !breadcrumbs.some((b) => b.id == nextBreadcrumb?.id)) {
				dispatch(setBreadcrumbs(nextBreadcrumb));
			}

			setFiles(
				filesAndFolders.map((f) => {
					const suffix = f.name.split('.');
					const isFolder = !Object.keys(f).includes('file');
					console.log('FILE TTTT', f);
					const cannotDelete = determineIfFolderDeletable(f);

					let webUrl;
					try {
						webUrl = decodeURI(f.webUrl)
							.replace(baseSite, '')
							.replace(decodeURI(f.name), '');
					} catch (e) {
						console.log('Error decoding uri', e);
					}

					const parentPath = f.parentReference?.path
						? f.parentReference.path.split('/root:')
						: null;
					const directory =
						folderPaths.find((p) => p.endsWith(f.parentReference.name)) ??
						(parentPath ? parentPath[parentPath.length - 1] : webUrl) ??
						'';

					return {
						...f,
						type: Object.keys(f).includes('file')
							? suffix[suffix.length - 1]
							: '000folder',
						Description: '',
						directory,
						createdDateTimeUnix: Date.parse(f.createdDateTime),
						lastModifiedTimeUnix: Date.parse(f.lastModifiedDateTime),
						cannotDelete,
						isFolder,
					};
				})
			);
			setLoading(false);
		};
		console.log('REFRESH INDICATOR', refreshIndicator, 'itemId', itemId);
		if (
			userQuery.data &&
			itemId &&
			!(
				featureFlagQuery.isLoading ||
				featureFlagQuery.isFetching ||
				featureFlagQuery.isUninitialized
			) &&
			featureFlagQuery.isSuccess &&
			(showNewFoldersFeature === true || showNewFoldersFeature === false)
		) {
			setLoading(true);
			updateMsGraph();
		} else {
			if (files.length !== 0) {
				setFiles([]);
			}
		}
	}, [
		userQuery.data,
		itemId,
		refreshIndicator,
		searchValue,
		baseSite,
		clientRow,
		showNewFoldersFeature,
		featureFlagQuery.isSuccess,
		featureFlagQuery.isLoading,
		featureFlagQuery.isFetching,
		featureFlagQuery.isUninitialized,
	]);

	const handleDelete = async () => {
		const itemLength = selectedRows.length;
		await Promise.all(
			selectedRows.map(
				async (item) =>
					await deleteClientSharepointItem(item.parentReference.driveId, item.id)
			)
		)
			.then((res) => {
				console.log('handleDelete RES', res);
				dispatch(
					showToast({
						message: `Successfully deleted ${itemLength} item${
							itemLength > 1 ? 's' : ''
						}.`,
						success: true,
					})
				);
				dispatch(triggerRefresh());
			})
			.catch((err) => {
				console.log('Error deleting items', err);
				const message =
					err?.response?.data?.error?.message ??
					err?.message ??
					'Error deleting items; please try again.';
				dispatch(
					showToast({
						message: `Error: ${message}`,
						error: true,
					})
				);
			})
			.finally(() => setConfirmDialogOpen(false));
	};

	const handleMove = async (newParentId) => {
		return await Promise.all(
			selectedRows.map(
				async (item) =>
					await moveClientSharepointItem(
						item.parentReference.driveId,
						item.id,
						newParentId
					)
			)
		);
	};

	const handleUpdateFileMetadata = async (item, metadata) => {
		console.log('ITEM TO UPDATE', item);
		return await updateClientSharepointItemMetadata(
			item.parentReference.driveId,
			item.id,
			metadata
		);
	};

	const handleRenameFile = async (item, newName) => {
		return await renameClientSharepointItem(item.parentReference.driveId, item.id, newName);
	};

	const uploadToFirebaseStorage = async (file) => {
		const isClient = deal ? false : true;

		const objectId = deal ? deal?.id ?? deal?.hs_object_id : client?.id ?? client?.hs_object_id;
		const attachmentsPath = isClient ? attachmentsClientFolderPath : attachmentsFolderPath;

		const filePath = `/${attachmentsPath}/${objectId}/${file.name}`;
		const storageRef = ref(storage, filePath);
		const metadata = {
			customMetadata: {
				[`${isClient ? 'Client' : 'Deal'} ID`]: objectId.toString(),
			},
		};

		await uploadBytes(storageRef, file, metadata);
	};

	const handleUploadFile = async (file) => {
		setLoading(true);
		try {
			await uploadToFirebaseStorage(file);
		} catch (e) {
			console.log('Error uploading file to Firebase Storage', e);
		}

		await uploadClientSharepointFile(itemId, file)
			.then((res) => {
				console.log('handleUploadFile RES', res);
				dispatch(
					showToast({
						message: `Successfully uploaded '${file.name}'.`,
						success: true,
					})
				);
				dispatch(triggerRefresh());
			})
			.catch((err) => {
				console.log('Error uploading file', err);
				const message =
					err?.response?.data?.error?.message ??
					err?.message ??
					'Error uploading file; please try again.';
				const pathIsTooLong = err?.response?.data?.error?.innerError?.code == 'pathIsTooLong';
				const additionalMessage = pathIsTooLong ? ' Please try shortening folder or file names, or reduce number of sub-folders.' : '';
				dispatch(
					showToast({
						message: `Error: ${message}${additionalMessage}`,
						error: true,
					})
				);
			});
		setLoading(false);
	};

	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

	const contextValue = {
		deal,
		client,
		dealRow: dealRowQuery.data ?? {},
		userDriveId: userQuery.data?.clientFolderDriveId,
		files,
		isLoading,
		isError,
		sharepoint,
		handleDelete: () => setConfirmDialogOpen(true),
		handleMove,
		handleUploadFile,
		handleRenameFile,
		handleUpdateFileMetadata,
		canCreate,
	};

	useEffect(() => {
		console.log('📋 ~ ATTACHMENTS CONTEXT VALUE', contextValue);
	}, [contextValue]);

	return (
		// Provide the context variables to the children components.
		<AttachmentsContext.Provider value={contextValue}>
			<FileUploadMSGraph />
			{children}
			<MoveFileDialog />
			<RenameFileDialog />
			<AddFolderSharepointDialog />
			{/* <FileExistsDialog /> */}
			<ConfirmDialog
				openDialog={confirmDialogOpen}
				handleClose={() => setConfirmDialogOpen(false)}
				handleConfirm={handleDelete}
				actionName={'delete'}
			/>
		</AttachmentsContext.Provider>
	);
};

export default AttachmentsContext;
