import React, { useState } from 'react';
import {
	Box,
	Card,
	Button,
	Tab,
	CardHeader,
	Tabs,
	Container,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { PageHeader } from '@/components/layouts/PageHeader';
import { UserSettings } from '@/components/layouts/UserSettings';
import { BrokerageSettings } from '@/components/layouts/BrokageSettings';
import { Settings } from '@mui/icons-material';

export const ProfileView = () => {
	const [currentTab, setCurrentTab] = useState('user');

	const tabs = [
		{
			label: 'User Settings',
			value: 'user',
		},
		{
			label: 'Brokerage Settings',
			value: 'brokerage',
		},
	];

	const handleTabChange = (event, value) => {
		event.preventDefault();
		setCurrentTab(value);
	};

	return (
		<Container sx={{ pt: 3 }}>
			<Box sx={{ backgroundColor: 'neutral.100', p: 3 }}>
				<CardHeader
					disableTypography
					title={
						<PageHeader title={'Settings'} icon={<Settings />} />
					}
				/>
				<Card>
					<Grid container>
						<Grid size={'grow'}>
							<Tabs
								indicatorColor="primary"
								scrollButtons="auto"
								textColor="primary"
								value={currentTab}
								sx={{ px: 2 }}
								variant="scrollable"
								onChange={handleTabChange}
							>
								{tabs.map((tab) => (
									<Tab
										key={tab.value}
										label={tab.label}
										value={tab.value}
										sx={{ fontWeight: 'bold' }}
									/>
								))}
							</Tabs>
						</Grid>
					</Grid>

					<Box p={2}>
						{currentTab === 'user' && <UserSettings />}
						{currentTab === 'brokerage' && <BrokerageSettings />}
					</Box>
				</Card>
			</Box>
		</Container>
	);
};
