import { useAttachmentsContext } from '@/context/attachmentsContext';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { determineIfCanCreateFolder, determineIfFolderDeletable } from '@/utils/folders';
import {
	FormLabel,
	Skeleton,
	Typography,
	Stack,
	Box,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import React, { useEffect, useMemo, useState } from 'react';
import { FolderOutlined } from '@mui/icons-material';
import { useFolderSelectContext } from '@/context/folderSelectContext';

const SharepointTreeItem = ({ item }) => {
	const { deal, dealRow, client, userDriveId } = useAttachmentsContext();

	const { apiRef, expandedItems, selectedFolder, setSelectedFolder } = useFolderSelectContext();

	const [loading, setLoading] = useState(false);

	const ownerId = deal?.hubspot_owner_id ?? client?.hubspot_owner_id;
	const userQuery = useGetUserDetailsQuery({ hubspotId: ownerId }, { skip: !ownerId });

	const { getClientGraphFolderByItemId } = useClientStorageHooks(userQuery.data);

	const [folders, setFolders] = useState([]);
	const [initialised, setInitialised] = useState(false);

	const expanded = useMemo(() => expandedItems.includes(item.id), [expandedItems]);

	useEffect(() => {
		const updateMsGraph = async () => {
			setLoading(true);
			const folderQuery = await getClientGraphFolderByItemId(userDriveId, item.id);

			setFolders(
				folderQuery.folders.map((f) => {
					const cannotDelete = determineIfFolderDeletable(f);
					const cannotCreate = determineIfCanCreateFolder(f);
					return {
						...f,
						isFolder: !Object.keys(f).includes('file'),
						cannotCreate,
						cannotDelete,
					};
				})
			);
			setLoading(false);
			setInitialised(true);
		};
		if (userDriveId && expanded && !initialised) {
			updateMsGraph();
		}
	}, [userDriveId, expanded, folders]);

	const generateLoadingSkeletons = () =>
		Array.from({ length: item?.folder?.childCount ?? 1 }, (x, i) => {
			return <Skeleton key={`loading-tree-item-${item.id}-${i}`} />;
		});

	const checked = useMemo(() => selectedFolder == item.id, [selectedFolder]);

	const handleChange = (e) => {
		apiRef?.current?.focusItem(e, checked ? null : item.id);
		setSelectedFolder(checked ? null : item.id);
	};

	return (
		<TreeItem
			itemId={item.id}
			label={
				<Stack
					direction='row'
					alignItems={'center'}
					onClick={(e) => {
						if (!item.cannotCreate) {
							handleChange(e);
						}
					}}
				>
					<Checkbox
						size={'small'}
						checked={checked}
						onChange={handleChange}
						disabled={item.cannotCreate}
						icon={<FolderOutlined />}
						checkedIcon={<FolderIcon />}
						sx={{ p: '4px' }}
					/>
					<Typography {...(checked && { sx: { fontWeight: 'bold' } })}>
						{item.name}
					</Typography>
				</Stack>
			}
		>
			{loading ? (
				generateLoadingSkeletons()
			) : folders.length > 0 ? (
				folders.map((f, i) => (
					<SharepointTreeItem
						key={`sharepoint-tree-item-child-${item.id}-${i}`}
						item={f}
					/>
				))
			) : (
				<Box pl={'3em'}>
					<Typography variant={'task_updated'}>No folders found</Typography>
				</Box>
			)}
		</TreeItem>
	);
};

const SharepointFolderTree = () => {
	const { deal, dealRow, client } = useAttachmentsContext();

	const { expandedItems, setExpandedItems } = useFolderSelectContext();

	const rowId = useMemo(
		() => (deal ? dealRow?.driveId : client?.clientRow?.driveId),
		[deal, dealRow?.driveId, client?.clientRow?.driveId]
	);

	const item = useMemo(
		() => ({
			id: rowId,
			name: dealRow?.dealName ?? client?.clientRow?.name,
			cannotCreate: !deal,
		}),
		[rowId, dealRow?.dealName, client?.clientRow?.name]
	);

	const handleExpandedItemsChange = (event, itemIds) => {
		setExpandedItems(itemIds);
	};

	return (
		<Stack>
			<FormLabel>Select folder</FormLabel>
			<Box
				sx={{
					maxHeight: '20em',
					overflowY: 'auto',
					pt: '1em',
				}}
			>
				<SimpleTreeView
					disableSelection
					expandedItems={expandedItems}
					onExpandedItemsChange={handleExpandedItemsChange}
				>
					<SharepointTreeItem item={item} />
				</SimpleTreeView>
			</Box>
		</Stack>
	);
};

export default SharepointFolderTree;
