import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	Box,
	Drawer,
	IconButton,
	Typography,
	TextField,
	Button,
	Avatar,
	Stack,
	FormLabel,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useFormik } from 'formik';

const DRAWER_WIDTH = 400;

const EditProfileDrawer = ({ title, open, onClose }) => {
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const { data: userDetails, isLoading: isUserLoading } = useGetUserDetailsQuery(
		{ email },
		{ skip: !email || !open }
	);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			phoneNumber: '',
			emailField: '',
			linkedIn: '',
			fspNumber: '',
			website: '',
			imageUrl: '',
			aboutUser: '',
			brokerSupport: '',
			brokerAdmin: '',
			adminAdviser: '',
		},
		onSubmit: () => {
			onClose(); 
		},
	});

	useEffect(() => {
		if (open && userDetails) {
			formik.setFieldValue('emailField', userDetails.email ?? '');
			formik.setFieldValue('phoneNumber', userDetails.phoneNumber ?? '');
			formik.setFieldValue('brokerSupport', userDetails.brokerSupport ?? '');
			formik.setFieldValue('fspNumber', userDetails.fspNumber ?? '');
			formik.setFieldValue('aboutUser', userDetails?.aboutUser ?? '');
			formik.setFieldValue('brokerAdmin', userDetails?.brokerAdmin ?? '');
			formik.setFieldValue('adminAdviser', userDetails?.adminAdviser ?? '');
			
		}
	}, [open, userDetails]);

	return (
		<Drawer
			open={open}
			onClose={onClose}
			anchor="right"
			sx={{
				width: DRAWER_WIDTH,
				'& .MuiDrawer-paper': {
					width: DRAWER_WIDTH,
					boxSizing: 'border-box',
					boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
				},
			}}
		>
			<Stack direction="row" sx={{ p: '1em' }} spacing={0.5} alignItems="center">
				<IconButton onClick={onClose}>
					<ChevronRightIcon />
				</IconButton>
				<Typography variant="h6">{`${title}`.trim()}</Typography>
			</Stack>

			<Box p={2}>
				{/* Avatar Display */}
				<Box
					sx={{
						position: 'relative',
						width: 120,
						height: 120,
						margin: '0 auto 8px',
					}}
				>
					<Avatar
						src={userDetails?.imageUrl || ''}
						alt="Profile Picture"
						sx={{
							width: '100%',
							height: '100%',
							backgroundColor: '#999999',
						}}
					>
						{!userDetails?.imageUrl && <PersonIcon fontSize="large" />}
					</Avatar>
				</Box>

				<Typography variant="body1" align="center" sx={{ mb: 2 }}>
					{formik.values.emailField}
				</Typography>

				<Box component="form" noValidate>
					{/* READ-ONLY FIELDS */}
					<FormLabel>FSP Number</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="fspNumber"
						value={formik.values.fspNumber}
						sx={{ mb: 1 }}
						inputProps={{ readOnly: true }}
					/>

					<FormLabel>Phone Number</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="phoneNumber"
						value={formik.values.phoneNumber}
						sx={{ mb: 1 }}
						inputProps={{ readOnly: true }}
					/>

					<FormLabel>Broker Support</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="brokerSupport"
						value={formik.values.brokerSupport}
						sx={{ mb: 1 }}
						inputProps={{ readOnly: true }}
					/>

					<FormLabel>Broker Admin</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="brokerAdmin"
						value={formik.values.brokerAdmin}
						sx={{ mb: 1 }}
						inputProps={{ readOnly: true }}
					/>
					<FormLabel>Admin Adviser</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="adminAdviser"
						value={formik.values.adminAdviser}
						sx={{ mb: 1 }}
						inputProps={{ readOnly: true }}
					/>

					<FormLabel>About My Services</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						multiline
						rows={4}
						name="about"
						value={formik.values.aboutUser}
						sx={{ mb: 1 }}
						inputProps={{ readOnly: true }}
					/>

					<Box mt={2}>
						<Link to="/profile-page" style={{ textDecoration: 'none' }}>
							<Button variant="text">Advanced Settings</Button>
						</Link>
					</Box>
				</Box>
			</Box>
		</Drawer>
	);
};
export default EditProfileDrawer;
