/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	CardContent,
	CardHeader,
	TextField,
	FormLabel,
	Container,
	Typography,
	Accordion,
	AccordionSummary,
	IconButton,
	InputAdornment,
	Select,
	MenuItem,
	CardActions,
	Divider,
	CircularProgress,
	Backdrop,
	FormControl,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import { Delete, Download, ExpandMore } from '@mui/icons-material';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { useFormik } from 'formik';
import { determineContext } from '@/hooks/determineContext';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import {
	folioBlue,
	formatDate,
	formatKey,
	getUrlFromPipeline,
	toCamelCase,
} from '@/utils/constants';
import { PageHeader } from '@/components/layouts/PageHeader';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { FIELDS } from './validationSchema';
import {
	COMMERCIAL_MOTOR,
	GOALS_OF_COVER_LABELS,
	MATERIAL_DAMAGE,
	NEEDS_ANALYSIS_LABELS,
	SCOPE_OF_ADVICE_LABELS_DOMESTIC,
	SCOPE_OF_ADVICE_LABELS_COMMERCIAL,
	STATUTORY_LIABILITY,
	COMMERCIAL,
	PRIVATE_VEHICLE,
	CLIENT_TYPE,
	DOMESTIC,
	HOUSE,
	BOAT,
} from '../CreateNewBusinessForm/validationSchema';
import { factFindApi, useGenerateSOAMutation } from '@/features/factFind/factFindApi';
import { showToast } from '@/features/toast/toastSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RecommendationTable } from './fields/RecommendationTable';
import { ResultsTable } from './fields/ResultsTable';
import { WhatHasBeenSentToMarket } from './fields/WhatHasBeenSentToMarket';
import { ChangeProductSelection } from './fields/ChangeProductSelection';
import { DetailsAccordion } from './fields/DetailsAccordion';
import { AddProductButton } from './fields/AddProductButton';
import { FormTextField } from './fields/FormTextField';
import Robot from '@/assets/images/ai-assistant.gif';
import { EndorsementsTable } from './fields/EndorsementsTable';
import { MotorVehicleTable } from './fields/MotorVehicleTable';
import { NumericFormat } from 'react-number-format';
import { MaterialDamageLocationTable } from './fields/MaterialDamageLocationTable';
import ConfirmEmailDialog from '@/components/dialogs/ConfirmEmailDialog';

const CreateSOAForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { width } = useSelector((state) => state.sideNavSlice);

	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const account = useSelector((state) => state.msalAccount.account);

	const email = account?.username;
	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });
	const hubspotId = userQuery?.data?.hubspotId;

	const [formLoading, setFormLoading] = useState(false);

	// Deal context
	const { deal, client, dealRow, quotes, riskInfo, soas, ownerId, loading } = determineContext();
	const isDomestic = dealRow?.[CLIENT_TYPE] == DOMESTIC;

	const isGenerating = useMemo(() => soas.length > 0 && soas[0]?.downloadUrl == null, [soas]);

	const [generateSOA] = useGenerateSOAMutation();

	const latestQuote = useMemo(() => (isDomestic ? {} : quotes[0] ?? {}), [quotes, isDomestic]);
	const latestRiskInfo = useMemo(
		() => (isDomestic ? {} : riskInfo[0] ?? {}),
		[riskInfo, isDomestic]
	);

	const isGeneratingData = latestRiskInfo?.generating || latestQuote?.generating;

	const isLoading =
		isGenerating ||
		isGeneratingData ||
		formLoading ||
		loading?.deal ||
		loading?.client ||
		loading?.aiDocs;

	const downloadButtonDisabled =
		formLoading || isGenerating || isGeneratingData || !soas?.[0]?.downloadUrl;

	useEffect(() => {
		let intervalId;

		const checkRunStatus = async () => {
			dispatch(
				factFindApi.util.invalidateTags([{ type: 'GENERATED_SOA', id: deal?.hs_object_id }])
			);
		};

		if (isGenerating) {
			intervalId = setInterval(checkRunStatus, 10000); // Check every 10 seconds
		}

		return () => clearInterval(intervalId);
	}, [isGenerating, deal?.hs_object_id]);

	const [products, setProducts] = useState([]);
	const [marketRows, setMarketRows] = useState([]);
	const [motorVehicles, setMotorVehicles] = useState([
		{
			year: '',
			make: '',
			model: '',
			coverage: '',
			sumInsured: '',
			...(isDomestic && { lossOfUse: '' }),
			id: 0,
		},
	]);
	const [pleasureCrafts, setPleasureCrafts] = useState([
		{
			year: '',
			type: '',
			make: '',
			coverage: '',
			sumInsured: '',
			id: 0,
		},
	]);

	const [locations, setLocations] = useState([
		{
			address: '',
			rows: [
				{
					coverage: 'Buildings',
					basisOfSettlement: '',
					sumInsured: '',
					id: 0,
				},
				{
					coverage: 'Contents',
					basisOfSettlement: '',
					sumInsured: '',
					id: 1,
				},
				...(!isDomestic
					? [
						{
							coverage: 'Stock',
							basisOfSettlement: '',
							sumInsured: '',
							id: 2,
						},
					  ]
					: []),
			],
		},
	]);

	useEffect(() => {
		if (isDomestic) {
			setLocations((prev) => [
				...prev.map((p) => ({
					...p,
					rows: p.rows.filter((r) => r.coverage !== 'Stock'),
				})),
			]);
		}
	}, [isDomestic]);

	const [resultsTables, setResultsTables] = useState({});
	const [insurerSelection, setInsurerSelection] = useState({});

	const formFields = useMemo(() => {
		const { scopeOfAdvice, whatHasBeenSentToMarket, businessDetails } = FIELDS;

		const needsAnalysis = dealRow?.needsAnalysis ?? [];
		const needs = needsAnalysis.map((v) => v.key);

		const clientTypeNeeds = Object.keys(
			!isDomestic ? SCOPE_OF_ADVICE_LABELS_COMMERCIAL : SCOPE_OF_ADVICE_LABELS_DOMESTIC
		).filter((v) => v !== 'fullNeedsAnalysis');

		const filteredScopeOfAdvice = Object.keys(scopeOfAdvice)
			.filter((key) => clientTypeNeeds.includes(key) && needs.includes(key))
			.reduce((acc, key) => {
				acc[key] = scopeOfAdvice[key];
				return acc;
			}, {});

		const filteredSentToMarket = Object.keys(whatHasBeenSentToMarket)
			.filter((key) => clientTypeNeeds.includes(key) && needs.includes(key))
			.reduce((acc, key) => {
				acc[key] = whatHasBeenSentToMarket[key];
				return acc;
			}, {});

		products.forEach((product) => {
			filteredScopeOfAdvice[product.title] = { columns: 6 };
		});

		filteredScopeOfAdvice['commentsOnScope'] = FIELDS.scopeOfAdvice['commentsOnScope'];

		const businessDetailsKeys = Object.keys(businessDetails).filter((key) =>
			isDomestic
				? !['annualTurnover', 'numberOfEmployees', 'businessActivities'].includes(key)
				: key
		);

		const newBusinessDetails = businessDetailsKeys.reduce((acc, k) => {
			acc[k] = businessDetails[k];
			return acc;
		}, {});

		const newFields = { ...FIELDS };
		newFields.scopeOfAdvice = filteredScopeOfAdvice;
		newFields.whatHasBeenSentToMarket = filteredSentToMarket;
		newFields.businessDetails = newBusinessDetails;
		return newFields;
	}, [products, dealRow?.needsAnalysis, isDomestic]);

	const createEmptyStructure = (fields) => {
		const result = {};
		Object.entries(fields).forEach(([key, value]) => {
			if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
				result[key] = Object.keys(value).reduce((acc, innerKey) => {
					acc[innerKey] = '';
					return acc;
				}, {});
			} else {
				result[key] = '';
			}
		});

		return result;
	};

	const initialValues = useMemo(() => createEmptyStructure(formFields), [formFields]);

	useEffect(() => {
		const newValues = products.reduce((acc, product) => {
			acc[product.title] = product.value;
			return acc;
		}, {});

		formik.setFieldValue('scopeOfAdvice', {
			...formik.values.scopeOfAdvice,
			...newValues,
		});
	}, [products]);

	useEffect(() => {
		const businessDetails = {
			...(dealRow?.clientName && { companyName: dealRow?.clientName }),
			...(dealRow?.description && { businessActivities: dealRow?.description }),
			...(client?.insight?.OneLineAddress && {
				insuredAddress: client?.insight?.OneLineAddress,
			}),
			...(client?.annualrevenue && {
				annualTurnover: client?.annualrevenue,
			}),
			...(deal?.adviser_fee && { currentlyPaying: deal?.adviser_fee }),
			...(client?.numberofemployees && {
				numberOfEmployees: client?.numberofemployees,
			}),
			...(dealRow?.renewalDate && {
				currentRenewalDate: formatDate(new Date(dealRow?.renewalDate)),
			}),
		};

		formik.setFieldValue('businessDetails', {
			...formik.values.businessDetails,
			...businessDetails,
		});
	}, [
		dealRow?.clientName,
		dealRow?.description,
		client?.insight?.OneLineAddress,
		client?.numberofemployees,
		client?.annualrevenue,
		dealRow?.clientType,
		dealRow?.goalsOfCover,
		dealRow?.renewalDate,
		deal?.adviser_fee,
	]);

	useEffect(() => {
		const clientTypeTitleCase = (dealRow?.clientType ?? ' ').replace(
			/\w\S*/g,
			(text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
		);
		const focus = {
			...(dealRow?.clientType && {
				clientType: clientTypeTitleCase,
			}),
			...(dealRow?.goalsOfCover && {
				goalsOfCover: dealRow?.goalsOfCover
					.map((v) => GOALS_OF_COVER_LABELS[v] ?? formatKey(v ?? ''))
					.join(', '),
			}),
		};

		formik.setFieldValue('whatWeAgreedToFocusOn', {
			...formik.values.whatWeAgreedToFocusOn,
			...focus,
		});
	}, [dealRow?.clientType, dealRow?.goalsOfCover]);

	useEffect(() => {
		const scope = (dealRow?.needsAnalysis ?? []).reduce((acc, scope) => {
			acc[scope.key] = scope.value;
			return acc;
		}, {});

		formik.setFieldValue('scopeOfAdvice', {
			...formik.values.scopeOfAdvice,
			...scope,
		});
	}, [dealRow?.needsAnalysis]);

	const extractVehicles = (vehicleData) => {
		return vehicleData.reduce((acc, vehicle, index) => {
			let sumInsured = parseInt(
				(vehicle?.sumInsured ?? vehicle?.SumInsured ?? vehicle['Sum Insured'])
					.toString()
					.replace(/\D/g, '')
			);
			sumInsured = isNaN(sumInsured) ? 0 : sumInsured;
			acc.push({
				year: vehicle.year ?? '',
				make: vehicle.make ?? '',
				model: vehicle.model ?? '',
				coverage: vehicle.coverOption ?? vehicle.coverage ?? vehicle['Cover Option'] ?? '',
				sumInsured,
				lossOfUse: '',
				id: index,
			});
			return acc;
		}, []);
	};

	const extractLocations = (locations) => {
		return locations.reduce((acc, location) => {
			const materialDamageCovers =
				location.materialDamageCovers &&
				typeof location.materialDamageCovers === 'object' &&
				!Array.isArray(location.materialDamageCovers)
					? location.materialDamageCovers
					: {};
			const sumInsureds = ['buildings', 'contents', 'stock'].reduce((acc, coverage) => {
				let sumInsured = parseInt(
					(
						materialDamageCovers?.[coverage]?.sumInsured ??
						materialDamageCovers?.[coverage]?.SumInsured ??
						materialDamageCovers?.[coverage]?.['Sum Insured'] ??
						''
					)
						.toString()
						.replace(/\D/g, '')
				);
				sumInsured = isNaN(sumInsured) ? '' : sumInsured.toString();
				acc[coverage] = sumInsured;
				return acc;
			}, {});

			acc.push({
				address: location.addressLine1,
				rows: [
					{
						coverage: 'Buildings',
						basisOfSettlement: materialDamageCovers?.buildings?.basisOfSettlement ?? '',
						sumInsured: sumInsureds.buildings,
						id: 0,
					},
					{
						coverage: 'Contents',
						basisOfSettlement: materialDamageCovers?.contents?.basisOfSettlement ?? '',
						sumInsured: sumInsureds.contents,
						id: 1,
					},
					{
						coverage: 'Stock',
						basisOfSettlement: materialDamageCovers?.contents?.basisOfSettlement ?? '',
						sumInsured: sumInsureds.stock,
						id: 2,
					},
				],
			});
			return acc;
		}, []);
	};

	useEffect(() => {
		const newValues = Object.keys(formFields.whatHasBeenSentToMarket).reduce((acc, val) => {
			acc[val] = '';
			return acc;
		}, {});

		let vehicles = [];
		let locationData = [];
		const riskInfoFields = Object.keys(latestRiskInfo?.jsonData?.classOfRisk ?? {});
		(dealRow?.needsAnalysis ?? []).forEach((scope) => {
			const scopeKey = formatKey(scope.key).toUpperCase();
			const dataField = riskInfoFields.find((k) =>
				formatKey(k).toUpperCase().includes(scopeKey)
			);
			if (dataField) {
				const field = latestRiskInfo.jsonData?.classOfRisk?.[dataField];
				const newValue = (
					field?.sumInsured ??
					field?.SumInsured ??
					field['Sum Insured'] ??
					0
				)
					.toString()
					.replace(/\D/g, '');
				newValues[scope.key] = newValue;

				if (
					field?.vehicles &&
					typeof field?.vehicles === 'object' &&
					Array.isArray(field?.vehicles)
				) {
					vehicles = extractVehicles(field?.vehicles);
				}
				if (
					field?.locations &&
					typeof field?.locations === 'object' &&
					Array.isArray(field?.locations)
				) {
					locationData = extractLocations(field?.locations);
				}
				if (field?.stockAnywhereInNZ) {
					const newValue = (
						field?.stockAnywhereInNZ?.sumInsured ??
						field?.stockAnywhereInNZ?.SumInsured ??
						field?.stockAnywhereInNZ?.['Sum Insured'] ??
						0
					)
						.toString()
						.replace(/\D/g, '');
					if (['', '0'].includes(newValues[scope.key])) {
						newValues[scope.key] = newValue;
					}
					formik.setFieldValue('whatHasBeenSentToMarket.stockAndOtherProperty', newValue);
				}
			} else {
				newValues[scope.key] = '0';
			}
		});

		if (vehicles.length > 0) {
			setMotorVehicles([
				...vehicles,
				{
					year: '',
					make: '',
					model: '',
					coverage: '',
					sumInsured: '',
					lossOfUse: '',
					id: vehicles.length,
				},
			]);
		}

		if (locationData.length > 0) {
			setLocations([
				...locationData,
				{
					address: '',
					rows: [
						{
							coverage: 'Buildings',
							basisOfSettlement: '',
							sumInsured: '',
							id: 0,
						},
						{
							coverage: 'Contents',
							basisOfSettlement: '',
							sumInsured: '',
							id: 1,
						},
						...(!isDomestic
							? [
								{
									coverage: 'Stock',
									basisOfSettlement: '',
									sumInsured: '',
									id: 2,
								},
							  ]
							: []),
					],
				},
			]);
		}
		setMarketRows(
			Object.entries(newValues)
				.map(([key, value]) => ({
					id: key,
					classOfRisk: formatKey(key),
					sumInsured: value,
				}))
				.concat({
					classOfRisk: '',
					sumInsured: '',
					id: 0,
				})
		);
	}, [
		formFields?.whatHasBeenSentToMarket,
		dealRow?.needsAnalysis,
		latestRiskInfo?.jsonData,
		isDomestic,
	]);

	const path = getUrlFromPipeline(deal.pipeline);

	const resetFormFields = () => {
		setProducts([]);
		setMarketRows([]);
		setResultsTables({});
		setInsurerSelection({});
	};

	const generateFormJsonData = () => {
		const { businessDetails, whatWeAgreedToFocusOn, scopeOfAdvice, results, myRecommendation } =
			formik.values;

		const stockAndOtherProperty = formik.values?.whatHasBeenSentToMarket?.stockAndOtherProperty;

		const scopeOfAdviceProducts = Object.entries(scopeOfAdvice)
			.filter(([key, value]) => {
				return (value ?? '').length > 0 && key !== 'commentsOnScope';
			})
			.map(([key, value]) => ({
				title: formatKey(key),
				value: NEEDS_ANALYSIS_LABELS[value] ?? value,
				outOfScope: value !== 'clientQuoted',
			}));

		const filteredMarketRows = marketRows.filter((r) => {
			const sum = r.sumInsured.length > 0 ? parseInt(r.sumInsured) : 0;
			return r.classOfRisk.length > 0 && !isNaN(sum) && sum > 0;
		});

		const recs = recommendations.map((r) => ({
			...r,
			classOfRisk: formatKey(r.classOfRisk),
		}));

		const resTables = Object.entries(resultsTables).reduce((acc, [key, value]) => {
			const newValue = value
				.filter((v) => v.insurer.length > 0)
				.map((v) => ({
					insurer: v.insurer,
					premium: parseFloat(v.premium),
					excess: parseFloat(v.excess),
					sumInsured: parseInt(v.sumInsured),
					endorsements: (v.endorsements ?? '').toString(),
				}));
			acc[formatKey(key)] = newValue;
			return acc;
		}, {});

		const filteredVehicles = motorVehicles.filter(({ year, make, model, sumInsured }) => {
			const sum = parseInt(sumInsured);
			return year.length > 0 && make.length > 0 && model.length > 0 && !isNaN(sum) && sum > 0;
		});

		const filteredPleasureCrafts = pleasureCrafts.filter(({ year, make, type, sumInsured }) => {
			const sum = parseInt(sumInsured);
			return year.length > 0 && make.length > 0 && type.length > 0 && !isNaN(sum) && sum > 0;
		});

		const filteredLocations = locations.filter(({ address, rows }) => {
			return (
				address.trim().length > 0 &&
				rows.every((r) => {
					const sum = parseInt(r.sumInsured);
					return r.basisOfSettlement.trim().length > 0 && !isNaN(sum) && sum > 0;
				})
			);
		});

		const formJson = {
			businessDetails,
			whatWeAgreedToFocusOn,
			scopeOfAdvice: {
				comments: scopeOfAdvice.commentsOnScope,
				products: scopeOfAdviceProducts,
			},
			whatHasBeenSentToMarket: filteredMarketRows,
			motorVehicles: filteredVehicles,
			pleasureCrafts: filteredPleasureCrafts,
			locations: filteredLocations,
			...(stockAndOtherProperty && !isDomestic && { stockAndOtherProperty }),
			results: {
				...results,
				products: resTables,
			},
			// insurers: insurersArray,
			myRecommendation: {
				comments: myRecommendation.summaryOfRecommendations,
				products: recs,
			},
		};

		console.log(formJson);
		return formJson;
	};

	const formik = useFormik({
		initialValues,
		// validationSchema,
		// enableReinitialize: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			setFormLoading(true);
			console.log('📋 ~ Formik values', {
				values,
				'What has been sent to market': marketRows,
				Results: resultsTables,
				// Endorsements: endorsements,
			});

			// const {
			// 	businessDetails,
			// 	whatWeAgreedToFocusOn,
			// 	scopeOfAdvice,
			// 	results,
			// 	myRecommendation,
			// } = values;

			// const scopeOfAdviceProducts = Object.entries(scopeOfAdvice)
			// 	.filter(([key, value]) => {
			// 		return (value ?? '').length > 0 && key !== 'commentsOnScope';
			// 	})
			// 	.map(([key, value]) => ({
			// 		title: formatKey(key),
			// 		value: NEEDS_ANALYSIS_LABELS[value] ?? value,
			// 		outOfScope: value !== 'clientQuoted',
			// 	}));

			// const filteredMarketRows = marketRows.filter((r) => {
			// 	const sum = r.sumInsured.length > 0 ? parseInt(r.sumInsured) : 0;
			// 	return r.classOfRisk.length > 0 && !isNaN(sum) && sum > 0;
			// });

			// const recs = recommendations.map((r) => ({
			// 	...r,
			// 	classOfRisk: formatKey(r.classOfRisk),
			// }));

			// const resTables = Object.entries(resultsTables).reduce((acc, [key, value]) => {
			// 	const newValue = value
			// 		.filter((v) => v.insurer.length > 0)
			// 		.map((v) => ({
			// 			insurer: v.insurer,
			// 			premium: parseFloat(v.premium),
			// 			excess: parseFloat(v.excess),
			// 			sumInsured: parseInt(v.sumInsured),
			// 			endorsements: (v.endorsements ?? '').toString(),
			// 		}));
			// 	acc[formatKey(key)] = newValue;
			// 	return acc;
			// }, {});

			// const filteredVehicles = motorVehicles.filter(({ year, make, model, sumInsured }) => {
			// 	const sum = parseInt(sumInsured);
			// 	return (
			// 		year.length > 0 && make.length > 0 && model.length > 0 && !isNaN(sum) && sum > 0
			// 	);
			// });

			// const filteredLocations = locations.filter(({ address, rows }) => {
			// 	return address.trim().length > 0 && rows.every(r => {
			// 		const sum = parseInt(r.sumInsured);
			// 		return r.basisOfSettlement.trim().length > 0 && !isNaN(sum) && sum > 0;
			// 	});
			// });

			// const formJson = {
			// 	businessDetails,
			// 	whatWeAgreedToFocusOn,
			// 	scopeOfAdvice: {
			// 		comments: scopeOfAdvice.commentsOnScope,
			// 		products: scopeOfAdviceProducts,
			// 	},
			// 	whatHasBeenSentToMarket: filteredMarketRows,
			// 	motorVehicles: filteredVehicles,
			// 	locations: filteredLocations,
			// 	results: {
			// 		...results,
			// 		products: resTables,
			// 	},
			// 	// insurers: insurersArray,
			// 	myRecommendation: {
			// 		comments: myRecommendation.summaryOfRecommendations,
			// 		products: recs,
			// 	},
			// };

			// console.log(formJson);

			const formJson = generateFormJsonData();

			await generateSOA({
				dealId: deal?.hs_object_id,
				quoteId: latestQuote?.id,
				riskInfoId: latestRiskInfo?.id,
				userId: hubspotId,
				formJson,
			})
				.unwrap()
				.then((res) => {
					dispatch(
						showToast({
							message: 'Proposal generation in progress! Check back soon.',
							success: true,
						})
					);
					// resetForm();
					// resetFormFields();
				})
				.catch((err) => {
					console.log('Error generating proposal', err);
					dispatch(
						showToast({
							message: 'Error generating proposal; please try again.',
							error: true,
						})
					);
				});
			setSubmitting(false);
			setFormLoading(false);
		},
	});

	const { getError, getErrorMessage } = useFormikHelper(formik);

	const transformQuoteData = (details) => {
		const result = Object.entries(details ?? {}).reduce((acc, [classOfRisk, values]) => {
			console.log('classOfRisk', classOfRisk, 'values', values);
			let insurers = [];
			if (Object.keys(values).includes('insurer')) {
				insurers = [
					{
						insurer: values?.insurer ?? '',
						excess: '',
						premium: values?.totalPayable ?? '',
						endorsements: (values?.endorsements ?? '').toString(),
						id: values?.insurer ?? 0,
					},
				];
			} else {
				insurers = Object.entries(values ?? {}).map(([insurer, v]) => {
					return {
						insurer: insurer ?? '',
						excess: '',
						premium: v?.totalPayable ?? '',
						endorsements: (v?.endorsements ?? '').toString(),
						id: insurer ?? 0,
					};
				});
			}

			const categoryString = toCamelCase(classOfRisk);
			const classOfRiskString = categoryString.toLowerCase().includes('materialdamage')
				? 'materialDamage'
				: categoryString;

			if (!acc[classOfRiskString]) {
				acc[classOfRiskString] = [];
			}

			insurers.forEach((value) => acc[classOfRiskString].push(value));
			return acc;
		}, {});

		console.log('transformQuoteData', result);
		return result;
	};

	useEffect(() => {
		const newRows = marketRows.filter((r) => {
			const value = parseInt(r.sumInsured);
			return !isNaN(value) && value > 0;
		});

		const table = newRows.reduce((acc, r) => {
			acc[r.id] = [
				{
					insurer: r.insurer ?? '',
					sumInsured: r.sumInsured ?? '',
					excess: r.excess ?? '',
					premium: r.premium ?? '',
					id: r.id ?? 0,
				},
			];
			return acc;
		}, {});

		const results = transformQuoteData(latestQuote?.jsonData?.classOfRisk);

		Object.entries(results ?? {}).forEach(([key, value]) => {
			const row = marketRows.find((r) => r.id === key || r.classOfRisk === key);
			const sumInsured = parseInt(row?.sumInsured);
			if (row && !isNaN(sumInsured) && sumInsured > 0) {
				console.log('VALUE', value);
				table[key] = [
					...value.map((v) => ({
						...v,
						sumInsured: row?.sumInsured,
						id: v.insurer,
						classOfRisk: key,
						excess: (v.excess ?? 0).toString(),
						premium: (v.premium ?? 0).toString(),
						endorsements: (v.endorsements ?? '').toString(),
					})),
					{
						insurer: '',
						sumInsured: '',
						excess: '',
						premium: '',
						classOfRisk: key,
						endorsements: '',
						id: 0,
					},
				].slice();
			}
		});
		setResultsTables(table);
	}, [latestQuote, marketRows]);

	const processResultsTableRowDeletion = (key, rowId) => {
		// [prev[key]]: prev[key].filter((p) => p.id !== rowId)
		setResultsTables((prev) => ({
			...prev,
			[key]: prev[key].filter((p) => p.id !== rowId),
		}));
	};

	const processResultsTableRowUpdate = (key, newRow, oldRow) => {
		const row = marketRows.find((r) => r.id === key);

		newRow.sumInsured = row?.sumInsured;
		setResultsTables((prevResultsTables) => {
			const table = prevResultsTables[key];
			if (!table) {
				return prevResultsTables;
			}
			const index = table.findIndex((r) => r.insurer === oldRow.insurer);
			if (index >= 0) {
				const updatedTable = [...table].slice();

				if ((newRow.insurer ?? '').length > 0) {
					newRow.id = newRow.insurer;
					updatedTable[index] = newRow;
				} else if (updatedTable.length > 1) {
					updatedTable.splice(index, 1);
				}

				const lastRow = updatedTable[updatedTable.length - 1];
				if (lastRow && lastRow.insurer !== '') {
					updatedTable.push({
						insurer: '',
						sumInsured: '',
						excess: '',
						premium: '',
						classOfRisk: key,
						endorsements: '',
						id: 0,
					});
				}
				console.log('Updated table', {
					...prevResultsTables,
					[key]: updatedTable,
				});
				return {
					...prevResultsTables,
					[key]: updatedTable,
				};
			}
			console.log('Old table', prevResultsTables);
			return prevResultsTables;
		});
		return newRow;
	};

	useEffect(() => {
		console.log('USE EFFECT [resultsTables]');
		const newSelections = Object.keys(resultsTables).reduce((acc, r) => {
			if (r?.id) {
				acc[r.id] = [];
			}
			return acc;
		}, {});
		setInsurerSelection((prev) => ({ ...newSelections, ...prev }));
	}, [resultsTables]);

	const recommendations = useMemo(() => {
		return Object.entries(insurerSelection).map(([key, insurer]) => {
			const row =
				resultsTables[key]?.find((r) => r.insurer === insurer || r.id === insurer) ?? {};
			row.classOfRisk = key;
			row.id = key;
			return row;
		});
	}, [insurerSelection, resultsTables]);

	const handleInsurerSelection = (row, key) => {
		const latest = row[row.length - 1];
		setInsurerSelection((prev) => ({ ...prev, [key]: latest ?? [] }));
	};

	const processWHBSTMRowDeletion = (rowId) => {
		setMarketRows((prev) => prev.filter((p) => p.id !== rowId));
	};

	const processWHBSTMRowUpdate = (newRow, oldRow) => {
		// const row = rows.find((r) => r.id === key);

		const newValue = parseInt(newRow.sumInsured);
		newRow.sumInsured = isNaN(newValue) ? oldRow.sumInsured : `${newValue}`;
		setMarketRows((prevRows) => {
			const index = prevRows.findIndex((r) => r.classOfRisk === oldRow.classOfRisk);
			if (index >= 0) {
				const updatedTable = [...prevRows].slice();

				if ((newRow.classOfRisk ?? '').length > 0) {
					newRow.id = toCamelCase(newRow.classOfRisk);
					updatedTable[index] = newRow;
				} else if (updatedTable.length > 1) {
					updatedTable.splice(index, 1);
				}

				const lastRow = updatedTable[updatedTable.length - 1];
				if (lastRow && lastRow.classOfRisk !== '') {
					updatedTable.push({
						classOfRisk: '',
						sumInsured: '',
						id: 0,
					});
				}
				return [...updatedTable].slice();
			}
			return prevRows;
		});

		return newRow;
	};

	const processMotorVehicleRowDeletion = (rowId) => {
		setMotorVehicles((prev) => prev.filter((p) => p.id !== rowId));
	};

	const processMotorVehicleRowUpdate = (newRow, oldRow) => {
		const rowValues = Object.entries(newRow)
			.map(([key, r]) => (key == 'id' ? '' : (r ?? '').toString().trim()))
			.join('');
		console.log('ROW VALUES', rowValues);

		setMotorVehicles((prevRows) => {
			const index = prevRows.findIndex((r) => r.id === oldRow.id);
			if (index >= 0) {
				const updatedTable = [...prevRows].slice();

				if (rowValues.length > 0) {
					updatedTable[index] = newRow;
				} else if (updatedTable.length > 1) {
					updatedTable.splice(index, 1);
					updatedTable.forEach((r, i) => (r.id = i));
				}

				const lastRow = updatedTable[updatedTable.length - 1];
				const lastRowValues = lastRow
					? Object.entries(lastRow)
						.map(([key, r]) => (key == 'id' ? '' : (r ?? '').toString().trim()))
						.join('')
					: '';
				if (lastRow && lastRowValues !== '') {
					const nextId = parseInt(lastRow.id);
					updatedTable.push({
						year: '',
						make: '',
						model: '',
						coverage: '',
						sumInsured: '',
						lossOfUse: '',
						id: isNaN(nextId) ? updatedTable.length : nextId + 1,
					});
				}
				return [...updatedTable].slice();
			}
			return prevRows;
		});

		return newRow;
	};

	const processPleasureCraftRowDeletion = (rowId) => {
		setPleasureCrafts((prev) => prev.filter((p) => p.id !== rowId));
	};

	const processPleasureCraftRowUpdate = (newRow, oldRow) => {
		const rowValues = Object.entries(newRow)
			.map(([key, r]) => (key == 'id' ? '' : (r ?? '').toString().trim()))
			.join('');
		console.log('ROW VALUES', rowValues);

		setPleasureCrafts((prevRows) => {
			const index = prevRows.findIndex((r) => r.id === oldRow.id);
			if (index >= 0) {
				const updatedTable = [...prevRows].slice();

				if (rowValues.length > 0) {
					updatedTable[index] = newRow;
				} else if (updatedTable.length > 1) {
					updatedTable.splice(index, 1);
					updatedTable.forEach((r, i) => (r.id = i));
				}

				const lastRow = updatedTable[updatedTable.length - 1];
				const lastRowValues = lastRow
					? Object.entries(lastRow)
						.map(([key, r]) => (key == 'id' ? '' : (r ?? '').toString().trim()))
						.join('')
					: '';
				if (lastRow && lastRowValues !== '') {
					const nextId = parseInt(lastRow.id);
					updatedTable.push({
						year: '',
						make: '',
						type: '',
						coverage: '',
						sumInsured: '',
						id: isNaN(nextId) ? updatedTable.length : nextId + 1,
					});
				}
				return [...updatedTable].slice();
			}
			return prevRows;
		});

		return newRow;
	};

	const processLocationDeletion = (index) => {
		setLocations((prev) => prev.splice(index, 1));
	};

	const addLocation = () => {
		setLocations((prev) => {
			return [
				...prev,
				{
					address: '',
					rows: [
						{
							coverage: 'Buildings',
							basisOfSettlement: '',
							sumInsured: '',
							id: 0,
						},
						{
							coverage: 'Contents',
							basisOfSettlement: '',
							sumInsured: '',
							id: 1,
						},
						...(!isDomestic
							? [
								{
									coverage: 'Stock',
									basisOfSettlement: '',
									sumInsured: '',
									id: 2,
								},
							  ]
							: []),
					],
				},
			];
		});
	};

	const processLocationRowUpdate = (index, newRow, oldRow) => {
		setLocations((prevRows) => {
			return prevRows.map((row, i) => {
				if (i === index) {
					return {
						...row,
						rows: row.rows.map((r) => (r.id === newRow.id ? newRow : r)),
					};
				}
				return row;
			});
		});

		return newRow;
	};

	const processLocationUpdate = (index, location) => {
		setLocations((prevRows) => {
			return prevRows.map((row, i) => {
				if (i === index) {
					return {
						...row,
						address: location,
					};
				}
				return row;
			});
		});
	};

	const generateRecommendationTable = () => {
		const field = 'myRecommendation';
		const index = Object.keys(formFields).indexOf(field);
		return (
			<>
				<Stack spacing={3} width='100%'>
					<RecommendationTable rows={recommendations} />
				</Stack>
				{generateFormFields(field, index)}
			</>
		);
	};

	const generateResultsTable = () => {
		const field = 'results';
		return (
			<Grid size={12}>
				<Stack spacing={6}>
					{Object.keys(resultsTables).length > 0 ? (
						Object.entries(resultsTables).map(([key, value]) => {
							
							return (
								<Stack key={`stack-${field}-${key}`} spacing={3}>
									<Stack spacing={1}>
										<Typography fontWeight='bold'>{formatKey(key)}</Typography>
										<ResultsTable
											key={`table-${field}-${key}`}
											setRowSelectionModel={(row) =>
												handleInsurerSelection(row, key)
											}
											rowSelectionModel={insurerSelection[key]}
											rows={value}
											updateRows={(newRow, oldRow) =>
												processResultsTableRowUpdate(key, newRow, oldRow)
											}
											removeRow={(rowId) =>
												processResultsTableRowDeletion(key, rowId)
											}
											disabled={isLoading}
										/>
										<EndorsementsTable
											key={`endorsements-table-${field}-${key}`}
											setRowSelectionModel={(row) =>
												handleInsurerSelection(row, key)
											}
											rowSelectionModel={insurerSelection[key]}
											rows={value}
											updateRows={(newRow, oldRow) =>
												processResultsTableRowUpdate(key, newRow, oldRow)
											}
											disabled={isLoading}
										/>
									</Stack>
									<Stack spacing={1}>
										<FormLabel>{`${formatKey(
											`${key}`
										)} recommendation`}</FormLabel>
										<TextField
											fullWidth
											multiline
											rows={2}
											name={`${field}.${key}`}
											{...formik.getFieldProps(`${field}.${key}`)}
											disabled={isLoading}
										/>
									</Stack>
								</Stack>
							);
						})
					) : (
						<Typography color={'rgba(0,0,0,0.5)'}>
							{'Please enter values in '}
							<span style={{ fontWeight: 500 }}>
								{'What Has Been Sent To Market'}
							</span>
							{' section'}
						</Typography>
					)}
				</Stack>
			</Grid>
		);
	};

	const generateMarketTable = () => {
		const field = 'whatHasBeenSentToMarket';
		return (
			<>
				<Grid size={12}>
					<WhatHasBeenSentToMarket
						key={`table-${field}`}
						rows={marketRows}
						updateRows={processWHBSTMRowUpdate}
						removeRow={processWHBSTMRowDeletion}
						disabled={isLoading}
					/>
				</Grid>
				{(Object.keys(resultsTables).includes(COMMERCIAL_MOTOR) ||
					Object.keys(resultsTables).includes(PRIVATE_VEHICLE)) &&
					generateMotorVehicleTable()}
				{Object.keys(resultsTables).includes(BOAT) && generatePleasureCraftTable()}
				{(Object.keys(resultsTables).includes(MATERIAL_DAMAGE) ||
					Object.keys(resultsTables).includes(HOUSE)) &&
					generateStockAndOtherProperty()}
			</>
		);
	};

	const generateStockAndOtherProperty = () => {
		const field = 'whatHasBeenSentToMarket';
		const key = 'stockAndOtherProperty';
		const formikKey = `${field}.${key}`;

		return (
			<Grid size={12} key={`render-fields-${field}-${key}`}>
				<Stack spacing={2}>
					<Typography fontWeight='bold'>
						{isDomestic ? 'Home and Contents ' : 'Material Damage'}
					</Typography>
					{!isDomestic && (
						<Stack spacing={1}>
							<FormLabel>
								{'Stock/Other Property Anywhere In New Zealand ($)'}
							</FormLabel>
							<NumericFormat
								prefix={'$'}
								decimalScale={0}
								fixedDecimalScale
								thousandSeparator
								allowNegative={false}
								customInput={TextField}
								fullWidth
								value={formik.values[formikKey]}
								onValueChange={({ floatValue }) => {
									formik.setFieldValue(formikKey, floatValue);
								}}
								disabled={isLoading}
								name={formikKey}
							/>
						</Stack>
					)}
					{locations.map(({ address, rows }, index) => {
						const isLocationError =
							address.length === 0 ||
							(locations.length > 1 &&
								locations.some(
									(l, i) =>
										i !== index &&
										l.address.trim().toLowerCase() ==
											address.trim().toLowerCase()
								));
						return (
							<Stack key={`${field}-${key}-locations-${index}`} spacing={1}>
								<FormControl fullWidth>
									<FormLabel>{`Location ${index + 1}`}</FormLabel>
									<Stack
										direction='row'
										justifyContent={'space-between'}
										alignItems={'flex-start'}
										spacing={1}
									>
										<TextField
											fullWidth
											name={`${field}-${key}-locations-${index}`}
											value={address}
											onChange={(e) =>
												processLocationUpdate(index, e.target.value)
											}
											disabled={isLoading}
											placeholder={'Street address, Suburb, City, Postcode'}
											error={isLocationError}
											helperText={
												isLocationError
													? address.length === 0
														? 'Please enter location'
														: 'Location must be unique'
													: ''
											}
										/>
										{locations.length > 1 && (
											<IconButton
												size='small'
												onClick={() => processLocationDeletion(index)}
												sx={{ marginTop: '0.5em' }}
											>
												<Delete />
											</IconButton>
										)}
									</Stack>
								</FormControl>

								<MaterialDamageLocationTable
									rows={rows}
									updateRows={(newRow, oldRow) =>
										processLocationRowUpdate(index, newRow, oldRow)
									}
									disabled={isLoading}
								/>
							</Stack>
						);
					})}
					<Box py={'1em'}>
						<Button
							variant='text'
							onClick={addLocation}
							sx={{
								textTransform: 'none',
							}}
							disabled={
								isLoading || locations.some((l) => l.address.trim().length === 0)
							}
						>
							Add new location
						</Button>
					</Box>
				</Stack>
			</Grid>
		);
	};

	const generateMotorVehicleTable = () => {
		const field = 'motorVehicle';
		return (
			<Stack spacing={1} py='1em' width='100%'>
				<Typography fontWeight='bold'>Vehicle information</Typography>
				<MotorVehicleTable
					key={`table-${field}`}
					rows={motorVehicles}
					updateRows={processMotorVehicleRowUpdate}
					removeRow={processMotorVehicleRowDeletion}
					disabled={isLoading}
				/>
				{/* <DevJSONView value={motorVehicles} /> */}
			</Stack>
		);
	};

	const generatePleasureCraftTable = () => {
		const field = 'boat';
		return (
			<Stack spacing={1} py='1em' width='100%'>
				<Typography fontWeight='bold'>Pleasure Craft information</Typography>
				<MotorVehicleTable
					key={`table-${field}`}
					rows={pleasureCrafts}
					updateRows={processPleasureCraftRowUpdate}
					removeRow={processPleasureCraftRowDeletion}
					disabled={isLoading}
					isPleasureCraft={true}
				/>
				{/* <DevJSONView value={pleasureCrafts} /> */}
			</Stack>
		);
	};

	const generateScopeOfAdvice = (field, key) => {
		const formikKey = `${field}.${key}`;

		return (
			<Grid size={6} key={formikKey}>
				{products.map((p) => p.title).includes(key) ? (
					<ChangeProductSelection
						product={products.find((p) => p.title === key)}
						products={products}
						removeProduct={() =>
							setProducts((prev) => prev.filter((p) => p.title !== key))
						}
						onBlur={(title, value) => {
							const index = products.findIndex((p) => p.title === key);
							const newProducts = [...products];
							newProducts[index] = {
								title,
								value,
							};
							setProducts(newProducts);
						}}
						disabled={isLoading}
					/>
				) : (
					<>
						<FormLabel>{formatKey(key)}</FormLabel>
						<Select
							disabled={Object.keys(formFields[field]).includes(key)}
							fullWidth
							{...formik.getFieldProps(formikKey)}
							value={formik.values[field][key] ?? ''}
							onChange={(e) => formik.setFieldValue(formikKey, e.target.value)}
						>
							{Object.entries(NEEDS_ANALYSIS_LABELS).map(([value, displayLabel]) => (
								<MenuItem
									value={value}
									key={`${formikKey}-needs-analysis-selection-${value}`}
								>
									{displayLabel}
								</MenuItem>
							))}
						</Select>
					</>
				)}
			</Grid>
		);
	};

	const generateFormFields = (field, i) => {
		return Object.entries(formFields[field]).map(([key, value], index) => {
			const formikKey = `${field}.${key}`;

			if (field === 'scopeOfAdvice' && key !== 'commentsOnScope') {
				return generateScopeOfAdvice(field, key);
			}
			return (
				<React.Fragment key={`render-fields-${field}-${i}-${index}`}>
					{field === 'scopeOfAdvice' && key === 'commentsOnScope' && (
						<AddProductButton
							onClick={() => {
								setProducts((prev) => [
									...prev,
									{
										title: `Product ${prev.length + 1}`,
										value: '',
									},
								]);
							}}
							disabled={isLoading}
						/>
					)}
					<Grid
						size={{
							xs: 12,
							sm: value.columns,
						}}
						key={formikKey}
					>
						<FormTextField
							field={field}
							formKey={key}
							value={value}
							formik={formik}
							disabled={isLoading}
						/>
					</Grid>
				</React.Fragment>
			);
		});
	};

	return (
		<>
			<Stack>
				<Box display='flex' sx={{ alignItems: 'center' }}>
					<Button
						variant='text'
						onClick={() => navigate(`${path}/${deal.hs_object_id}`)}
						sx={{ color: 'black', fontWeight: 'bold' }}
						startIcon={<KeyboardBackspaceOutlinedIcon />}
					>
						{'Back to deal'}
					</Button>
				</Box>
				<Container sx={{ pt: 3 }}>
					<form onSubmit={formik.handleSubmit}>
						<CardHeader
							disableTypography
							title={
								<Stack direction='row' justifyContent={'space-between'}>
									<PageHeader title={'Generate Statement of Advice'} />
									<Button
										size='small'
										sx={{ minWidth: '22%' }}
										onClick={() => {
											try {
												window.open(soas[0].downloadUrl, '_blank');
											} catch (err) {
												console.log('🙅 ~ Error downloading SOA', err);
												dispatch(
													showToast({
														message:
															'Error downloading document. Please try again.',
														error: true,
													})
												);
											}
										}}
										disabled={downloadButtonDisabled}
										startIcon={
											isGenerating ? (
												<CircularProgress color='inherit' size='0.75em' />
											) : (
												<Download />
											)
										}
									>
										{'Download last generated'}
									</Button>
								</Stack>
							}
						/>
						<CardContent>
							{isGenerating ? (
								<Stack direction='row' spacing={1} alignItems='center'>
									<CircularProgress size='1.25em' />
									<Typography>
										Currently generating SOA document. Please check back soon.
									</Typography>
								</Stack>
							) : (
								<Typography paragraph>
									Fill out the following form to generate a Statement of Advice
								</Typography>
							)}

							{Object.keys(formFields).map((field, i) => {
								return (
									<DetailsAccordion title={formatKey(field)} key={`field-${i}`}>
										<Grid container spacing={4}>
											{field === 'whatHasBeenSentToMarket'
												? generateMarketTable()
												: field === 'results'
													? generateResultsTable()
													: field === 'myRecommendation'
														? generateRecommendationTable()
														: generateFormFields(field, i)}
										</Grid>
									</DetailsAccordion>
								);
							})}
						</CardContent>
						<CardActions
							sx={{
								width: '100%',
								justifyContent: 'space-between',
							}}
						>
							<Button
								variant='text'
								onClick={(e) => {
									e.preventDefault();
									navigate(`${path}/${deal.hs_object_id}`);
								}}
							>
								Exit
							</Button>
							<Button
								variant='contained'
								onClick={(e) => {
									e.preventDefault();
									setConfirmationOpen(true);
								}}
							>
								<Box px={'2em'}>Create</Box>
							</Button>
						</CardActions>
						{confirmationOpen && (
							<ConfirmEmailDialog
								{...{
									emailType: 'Statement of Advice',
									confirmationOpen,
									setConfirmationOpen,
									email,
									handleSendEmail: formik.handleSubmit,
									emailExplanation: '',
								}}
							/>
						)}
						{/* <DevJSONView value={formik.values} /> */}
					</form>
				</Container>
			</Stack>
			<Backdrop sx={{ backgroundColor: 'transparent' }} open={isGenerating}>
				<Box
					sx={{
						borderRadius: '50%',
						zIndex: 5,
						height: '150px',
						width: '150px',
						marginLeft: `${width}px`,
						backgroundImage: `url(${Robot})`,
						backgroundPosition: 'left',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
					}}
				/>
			</Backdrop>
		</>
	);
};
export default CreateSOAForm;
