import React, { useEffect } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useSelector } from 'react-redux';

export const DealTableRefresh = () => {
	const { results, refresh } = useInstantSearch();

	const { refreshIndicator } = useSelector((state) => state.dealTable);

    useEffect(() => console.log('RESULTS', results), [results]);
    
	useEffect(() => {
		console.log('REFRESH INDICATOR', refreshIndicator);
		refresh();
	}, [refreshIndicator]);

	return <></>;
};