import React, { useLayoutEffect, useCallback, useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import { FormHelperText, Typography } from '@mui/material';

const DataGridMultilinePopper = (props) => {
	const { id, field, value, colDef, hasFocus, maxLength, validationSchema } = props;
	const [valueState, setValueState] = useState(value);
	const [anchorEl, setAnchorEl] = useState();
	const [inputRef, setInputRef] = useState(null);
	const apiRef = useGridApiContext();

	const [errorMessage, setErrorMessage] = useState('');

	useLayoutEffect(() => {
		if (hasFocus && inputRef) {
			inputRef.focus();
		}
	}, [hasFocus, inputRef]);

	const handleRef = useCallback((el) => {
		setAnchorEl(el);
	}, []);

	const handleChange = useCallback(
		async (event) => {
			const newValue = event.target.value;
			setValueState(newValue);
			if (validationSchema) {
				try {
					await validationSchema.validate({ [field]: newValue }, { strict: true });
					setErrorMessage('');
					apiRef.current.setEditCellValue(
						{ id, field, value: newValue, debounceMs: 200 },
						event
					);
				} catch (e) {
					console.log('ERROR', e);
					setErrorMessage(
						Array.isArray(e?.errors) ? e?.errors.join(', ') : 'Validation error'
					);
				}
			} else {
				apiRef.current.setEditCellValue({ id, field, value: newValue, debounceMs: 200 }, event);
			}
			
		},
		[apiRef, field, id]
	);

	return (
		<div style={{ position: 'relative', alignSelf: 'flex-start' }}>
			<div
				ref={handleRef}
				style={{
					height: 1,
					width: colDef.computedWidth,
					display: 'block',
					position: 'absolute',
					top: 0,
				}}
			/>
			{anchorEl && (
				<Popper open anchorEl={anchorEl} placement='bottom-start' sx={{ zIndex: 1300 }}>
					<Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
						<InputBase
							multiline
							rows={4}
							value={valueState}
							sx={{
								textarea: { resize: 'both' },
								width: '100%',
								...(validationSchema &&
									errorMessage.length > 0 && {
									border: '1px solid red',
								}),
							}}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
							onChange={handleChange}
							inputRef={(ref) => setInputRef(ref)}
							{...(maxLength && { inputProps: { maxLength } })}
						/>
						{validationSchema && errorMessage.length > 0 && (
							<FormHelperText error>{errorMessage}</FormHelperText>
						)}
						{maxLength && (
							<Typography
								variant='caption'
								sx={{
									display: 'block',
									textAlign: 'right',
									mt: 0.5,
									color: 'text.secondary',
									...(valueState.length == maxLength && { fontWeight: 'bold' }),
								}}
							>
								{valueState.length}/{maxLength}
							</Typography>
						)}
					</Paper>
				</Popper>
			)}
		</div>
	);
};

export default DataGridMultilinePopper;
