// features/table/clientTableSlice.js
import { generateInitialState, createTableSlice } from './tableSlice';

const clientsTableInitialState = generateInitialState('name', 'asc');
export const clientsTableSlice = createTableSlice('clientTable', clientsTableInitialState);

export const { 
	setPageAfter,
	setRowsPerPage,
	setOpenDialog,
	setSearchValue,
	setSearchText,
	setOrderBy,
	setOrder,
	setPage,
	setRows,
	resetSearchFields,
	changeRowsPerPage,
	requestSort,
	changePage,
	resetAllPages,
	resetPages,
	onOrderChange,
	triggerRefresh,
} = clientsTableSlice.actions;

export default clientsTableSlice.reducer;