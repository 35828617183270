import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { BlockPicker  } from 'react-color';

const ColorPicker = ({ label, initialColor, onChange }) => {
	const [color, setColor] = useState(initialColor || '#000000');
	const [showPicker, setShowPicker] = useState(false);

	const handleColorChange = (newColor) => {
		setColor(newColor.hex);
		onChange(newColor.hex);
	};

	return (
		<Box sx={{ mb: 2 }}>
			<Typography variant="subtitle1" sx={{ mb: 1 }}>
				{label}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 2,
				}}
			>
				<Box
					sx={{
						width: 36,
						height: 36,
						borderRadius: '4px',
						backgroundColor: color,
						border: '1px solid #ccc',
					}}
					onClick={() => setShowPicker(!showPicker)}
				/>
				<Button
					variant="outlined"
					onClick={() => setShowPicker(!showPicker)}
				>
					{showPicker ? 'Close' : 'Pick Color'}
				</Button>
			</Box>
			{showPicker && (
				<Box sx={{ mt: 1 }}>
					<BlockPicker   color={color} onChange={handleColorChange} />
				</Box>
			)}
		</Box>
	);
};

export default ColorPicker;
