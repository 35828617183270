import { yearFolderOptions } from '@/utils/constants';
import { MenuItem, Select } from '@mui/material';
import React from 'react';

export const YearFolderSelect = ({ name, value, disabled, onChange }) => {
	return (
		<Select
			name={name}
			value={value}
			disabled={disabled}
			onChange={onChange}
			fullWidth={true}
		>
			{yearFolderOptions.map((value) => {
				return (
					<MenuItem value={value} key={`year-folder-selection-${value}`}>
						{value}
					</MenuItem>
				);
			})}
		</Select>
	);
};
