import React, { useState, useEffect, useMemo} from 'react';
import { useSelector } from 'react-redux';
import {
	Box,
	TextField,
	Button,
	Avatar,
	IconButton,
	FormLabel,
	FormHelperText,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { showToast } from '@/features/toast/toastSlice';
import { useGetUserDetailsQuery, useUpdateUserProfileMutation } from '@/features/user/userApi';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { BrokerSelectProfile } from '@/components/selects/BrokerSelectProfile';
import { OwnerSelect } from '@/components/selects/OwnerSelect';


export const UserSettings = () => {
	const dispatch = useDispatch();
	const [imageFile, setImageFile] = useState(null);
	const [previewUrl, setPreviewUrl] = useState(null);
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const { data: userDetails } = useGetUserDetailsQuery({ email }, { skip: !email });
	const [updateUserProfile] = useUpdateUserProfileMutation();

	const formik = useFormik({
		initialValues: {
			email: '',
			phoneNumber: '',
			linkedIn: '',
			imageUrl: '',
			aboutUser: '',
			fspNumber: '',
			//New Values 
			brokerSupport: '',
			brokerAdmin: '',
			adminAdviser: '',
			compliance: '',
			disclosureUrl: '',
		},
		onSubmit: async (values) => {
			try {
				await updateUserProfile({
					email,
					properties:{
						phoneNumber: values.phoneNumber,
						linkedIn: values.linkedIn,
						imageUrl: values.imageUrl,
						aboutUser: values.aboutUser,
						fspNumber: values.fspNumber,
						//new values
						brokerSupport: values.brokerSupport,
						brokerAdmin: values.brokerAdmin,
						adminAdviser: values.adminAdviser,
						compliance: values.compliance,
						disclosureUrl: values.disclosureUrl,
					}
				}).unwrap();

				dispatch(showToast({ message: 'Profile updated!' }));
			} catch (err) {
				console.error(err);
			}
		},
	});

	useEffect(() => {
		if (userDetails) {
			formik.setFieldValue('email', userDetails.email ?? '');
			formik.setFieldValue('phoneNumber', userDetails.phoneNumber ?? '');
			formik.setFieldValue('linkedIn', userDetails.linkedIn ?? '');
			formik.setFieldValue('aboutUser', userDetails.aboutUser ?? '');
			formik.setFieldValue('fspNumber', userDetails.fspNumber ?? '');

			formik.setFieldValue('brokerSupport', userDetails.brokerSupport ?? '');
			formik.setFieldValue('brokerAdmin', userDetails.brokerAdmin ?? '');
			formik.setFieldValue('adminAdviser', userDetails.adminAdviser ?? '');
			formik.setFieldValue('compliance', userDetails.compliance ?? '');
			formik.setFieldValue('disclosureUrl', userDetails.disclosureUrl ?? '');

			if (userDetails.imageUrl) {
				setPreviewUrl(userDetails.imageUrl);
				formik.setFieldValue('imageUrl', userDetails.imageUrl);
			}
		}
	}, [userDetails]);

	const handleImageUpload = async (e) => {
		const file = e.target.files?.[0];
		if (!file) return;
    
		try {
			setImageFile(file);
			const localPreview = URL.createObjectURL(file);
			setPreviewUrl(localPreview);
    
			const storage = getStorage();
			const storageRef = ref(storage, `userData/${userDetails.userId}/${file.name}`);
			await uploadBytes(storageRef, file);
    
			const downloadUrl = await getDownloadURL(storageRef);
    
			// Update Formik and local preview
			formik.setFieldValue('imageUrl', downloadUrl);
			setPreviewUrl(downloadUrl);
    
			// Update user profile with basic info.
			await updateUserProfile({
				email,
				properties: {
					phoneNumber: formik.values.phoneNumber,
					linkedIn: formik.values.linkedIn,
					fspNumber: formik.values.fspNumber,
					imageUrl: downloadUrl, 
					aboutUser: formik.values.aboutUser,
					brokerSupport: formik.values.brokerSupport,
					brokerAdmin: formik.values.brokerAdmin,
					adminAdviser: formik.values.adminAdviser,
					compliance: formik.values.compliance,
					disclosureUrl: formik.values.disclosureUrl,
				}
			}).unwrap();
    
			dispatch(showToast({ message: 'Image uploaded successfully!' }));
			setPreviewUrl(downloadUrl); 
		} catch (err) {
			console.error('Error uploading image:', err);
			dispatch(showToast({ message: 'Error uploading image' }));
		}
	};

	const brokerSupportId = useMemo(() => formik.values.brokerSupport, [formik.values.brokerSupport]);
	const brokerAdminId = useMemo(() => formik.values.brokerAdmin, [formik.values.brokerAdmin]);
	const adminAdviserId = useMemo(() => formik.values.adminAdviser, [formik.values.adminAdviser]);
	const complianceId = useMemo(() => formik.values.compliance, [formik.values.compliance]);

	return (
		<Box>
			<form onSubmit={formik.handleSubmit}>
				<Box sx={{ textAlign: 'center', mb: 3 }}>
					<Box
						sx={{
							position: 'relative',
							width: 150,
							height: 150,
							marginTop: 2,
						
						}}
					>
						<Avatar
							src={previewUrl || ''}
							alt="Profile Picture"
							sx={{
								width: '100%',
								height: '100%',
								backgroundColor: '#999999',
							}}
						>
							{!previewUrl && 'N/A'}
						</Avatar>
						<Box
							sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								borderRadius: '50%',
								backgroundColor: 'rgba(0, 0, 0, 0.4)',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								opacity: 0,
								transition: 'opacity 0.3s ease',
								cursor: 'pointer',
								'&:hover': { opacity: 1 },
							}}
						>
							<IconButton component="label" sx={{ color: 'white' }}>
								<CloudUploadIcon fontSize="large" />
								<input type="file" hidden accept="image/*" onChange={handleImageUpload} />
							</IconButton>
						</Box>
					</Box>
				</Box>
				<FormLabel>{formik.values.email}</FormLabel>
				<Grid container spacing={4} my={2}>
					<Grid size={6}>
						<FormLabel>FSP Number</FormLabel>
						<TextField
							fullWidth
							variant="outlined"
							name="fspNumber"
							value={formik.values.fspNumber}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.fspNumber && Boolean(formik.errors.fspNumber)}
							helperText={formik.touched.fspNumber && formik.errors.fspNumber}
						/>
					</Grid>
					<Grid size={6} >
						<FormLabel>Phone Number</FormLabel>
						<TextField
							fullWidth
							variant="outlined"
							name="phoneNumber"
							value={formik.values.phoneNumber}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
							helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={4} my={2}>
					<Grid size={12} >	
						<FormLabel>LinkedIn Handle</FormLabel>
						<TextField
							fullWidth
							variant="outlined"
							name="linkedIn"
							value={formik.values.linkedIn}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.linkedIn && Boolean(formik.errors.linkedIn)}
							helperText={formik.touched.linkedIn && formik.errors.linkedIn}
						/>
					</Grid>
				</Grid>
				<Grid size={12} >
					<FormLabel>About my Services</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="aboutUser"
						multiline
						rows={4}
						value={formik.values.aboutUser}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.aboutUser && Boolean(formik.errors.aboutUser)}
						helperText={formik.touched.aboutUser && formik.errors.aboutUser}
					/>
				</Grid>
				<Grid size={12} mt={2} >
					<FormLabel>My Team</FormLabel>
					<FormHelperText>The following team members, if selected will appear on your generated Statement of Advice</FormHelperText>
				</Grid>
				<Grid container spacing={4} mt={2}>
					<Grid size={6}>
						<FormLabel>Broker Support</FormLabel>
						<OwnerSelect
							key="brokerSupportId"
							initialId={brokerSupportId}
							onChange={(value) => formik.setFieldValue('brokerSupport', value)}
						/>
					</Grid>
					<Grid size={6}>
						<FormLabel>Broker Admin</FormLabel>
						<OwnerSelect
							key="brokerAdmin"
							initialId={brokerAdminId}
							onChange={(value) => formik.setFieldValue('brokerAdmin', value)}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={4} my={2}>
					<Grid size={6}>
						<FormLabel>Admin Adviser</FormLabel>
						<OwnerSelect
							key="adminAdviser"
							initialId={adminAdviserId}
							onChange={(value) => formik.setFieldValue('adminAdviser', value)}
						/>
					</Grid>
					<Grid size={6}>
						<FormLabel>Compliance</FormLabel>
						<OwnerSelect
							key="compliance"
							initialId={complianceId}
							onChange={(value) => formik.setFieldValue('compliance', value)}
						/>
					</Grid>
				</Grid>
				<Button type="submit" variant="contained">
					Save
				</Button>

				
			</form>
		</Box>
	);
};