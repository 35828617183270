/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { Box, Skeleton, Stack, Tooltip, useTheme } from '@mui/material';
import {
	folioBlue,
	getPipelineIcon,
	getUrlFromPipeline,
	RENEWAL_MAX_DAYS,
	DEAL_STATUS_TYPES,
} from '@/utils/constants';
import { useNavigate } from 'react-router-dom';
import { Configure, InstantSearch, useHits } from 'react-instantsearch';
import { dealClient } from '@/utils/typesense';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { useSelector } from 'react-redux';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { addDays, isAfter } from 'date-fns';
import InfoIcon from '@mui/icons-material/Info';

const today = new Date();

export const InstantDealDisplayCard = ({ pipelineId, title, url }) => {
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const pipelineQuery = useGetPipelinePropertiesQuery(pipelineId);

	const { groupView, hubspotId: groupViewHubspotId } = useSelector((state) => state.userPrefs);

	const { active, inactive } = useMemo(() => {
		const pipelineStages = pipelineQuery.data?.pipelines?.stages ?? [];
		return {
			active: pipelineStages
				.filter((stage) => stage.metadata?.isClosed != 'true')
				.map((s) => s.id),
			inactive: pipelineStages
				.filter((stage) => stage.metadata?.isClosed == 'true')
				.map((s) => s.id),
		};
	}, [pipelineQuery.data]);

	const isRenewals = pipelineId === process.env.REACT_APP_PIPELINE_RENEWALS;
	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'renewals' });
	const showNewRenewalsFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	useEffect(() => console.log(
		'is renewals pipeline card & showNewRenewals feature ',
		isRenewals,
		showNewRenewalsFeature
	), [isRenewals, showNewRenewalsFeature]);

	const userQuery = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const userDetailsHubspotId = userQuery.data?.hubspotId;
	const hubspotId = groupViewHubspotId ?? userDetailsHubspotId ?? 0;
	const brokerGroupIds = userQuery.data?.brokerGroupArray ?? [];

	const filterSchema = useMemo(() => {
		const stageIds =
			showNewRenewalsFeature && isRenewals ? inactive.join(',') : active.join(',');
		const ownerFilter = groupView
			? `brokerGroupId: [${brokerGroupIds.join(',')}]`
			: `ownerId := ${hubspotId}`;

		const pipelines = [
			process.env.REACT_APP_PIPELINE_RENEWALS,
			process.env.REACT_APP_PIPELINE_NEW_BUSINESS,
			process.env.REACT_APP_PIPELINE_ENDORSEMENTS,
		];

		// Base filter
		let filters =
			showNewRenewalsFeature && isRenewals
				? `pipeline: [${pipelines.join(',')}] && ${ownerFilter}`
				: `dealStage: [${stageIds}] && ${ownerFilter}`;

		if (isRenewals && showNewRenewalsFeature) {
			const endDate = addDays(today, 60).getTime();
			filters += `&& (renewalDate:<=${endDate} && (isRenewable:=true || status:=${DEAL_STATUS_TYPES.renewalCreated}))`;
		}

		const schema = { filters };
		// console.log('Filter schema', schema, isRenewals && showNewRenewalsFeature);
		return schema;
	}, [hubspotId, groupView, brokerGroupIds, active, isRenewals, showNewRenewalsFeature]);

	return (
		<InstantSearch searchClient={dealClient} indexName='deals'>
			<Configure hitsPerPage={15} {...filterSchema} />
			<InstantDealDisplayCardWrapper title={title} pipelineId={pipelineId} url={url} />
		</InstantSearch>
	);
};

const InstantDealDisplayCardWrapper = ({ pipelineId, title, ...props }) => {
	const { results } = useHits(props);

	const total = results.nbHits;

	const isRenewals = pipelineId === process.env.REACT_APP_PIPELINE_RENEWALS;
	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'renewals' });
	const isFeatureFlagLoading = featureFlagQuery.isLoading;
	const showNewRenewalsFeature = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);
	const navigate = useNavigate();
	const theme = useTheme();

	const url = getUrlFromPipeline(pipelineId);

	return (
		<Card
			onClick={() => navigate(url)}
			sx={{
				borderRadius: '15px',
				height: '14em',
				[theme.breakpoints.down('md')]: {
					height: '13.5em',
				},
				padding: '1.25em',
				transition: 'background-color 0.3s',
				'&:hover': {
					backgroundColor: '#f0f0f0',
					cursor: 'pointer',
				},
			}}
		>
			<Stack height='100%' justifyContent={'space-between'}>
				{isFeatureFlagLoading ? (
					<Skeleton variant='wave' height={15} width='100%' />
				) : (
					<Typography variant='card_header'>
						<Stack direction="row" alignItems={'center'}>
							<span>
								{isRenewals && showNewRenewalsFeature
									? 'Upcoming Renewals'
									: `Active ${title}`}
							</span>
							{isRenewals && showNewRenewalsFeature && (
								<Tooltip
									title={
										'This is the number of deals that include a policy needing review within the next 60 days or with a renewal date that has already passed, where a renewal has not been completed.'
									}
									placement='bottom'
								>
									<Box
										component='span'
										ml={0.5}
										display='flex'
										alignItems='center'
									>
										<InfoIcon sx={{ fontSize: 16 }} />
									</Box>
								</Tooltip>
							)}
						</Stack>
					</Typography>
				)}
				<Stack direction='row' justifyContent='space-between' alignItems='center' height={'60px'}>
					<Typography variant='card_h4'>{total}</Typography>
					{getPipelineIcon(pipelineId, '50px')}
				</Stack>
				<Typography sx={{ color: folioBlue }}>{`View all ${title}`}</Typography>
			</Stack>
		</Card>
	);
};
