/* eslint-disable no-mixed-spaces-and-tabs */
import React, { createContext, useState, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useGetDealsListByIdsQuery } from '@/features/deals/dealsApi';
import {
	clientsApi,
	useGetClientPoliciesQuery,
	useGetClientPropertiesQuery,
	useUpdateClientRowMutation,
} from '@/features/clients/clientsApi';
import { useGetContactPropertiesQuery } from '@/features/contacts/contactsApi';
import { useGetOwnerQuery } from '@/features/user/ownerApi';
import { useGetTicketsListByIdsQuery } from '@/features/claims/ticketsApi';
import {
	useGetAllDealActivitiesQuery,
	useGetEngagementsQuery,
} from '@/features/engagements/engagementsApi';
import { setLoading } from '@/features/engagements/engagementsSlice';
import { useDispatch } from 'react-redux';
import { useGetFormsQuery } from '@/features/forms/formsApi';
import { useGetUserDetailsQuery, userApi } from '@/features/user/userApi';
import { IS_DEV } from '@/utils/environment';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';

const initialState = {
	objectType: 'client',
	client: {},
	contact: {},
	isProspect: false,
	deals: [],
	claims: [],
	notes: [],
	tasks: [],
	calls: [],
	emails: [],
	quotes: [],
	msgFiles: [],
	owner: {},
	sharepoint: {
		sharepointSite: '',
		clientSiteSuffix: '',
		clientFolder: '',
		clientSite: '',
		isValid: false,
	},
	isLoading: true,
	isError: false,
	// clientRow: {},
	activities: [],
	loading: {
		deals: false,
		client: false,
		contact: false,
		owner: false,
	},
};

const ClientContext = createContext(initialState);

// ClientContextProvider component that provides the Client context to its children.
export const ClientContextProvider = ({ children }) => {
	const dispatch = useDispatch();

	const clientId = useMemo(
		() => window.location.pathname.split('/').pop(),
		[window.location.pathname]
	);

	const clientProperties = useGetClientPropertiesQuery(clientId, { skip: !clientId });
	const clientRow = clientProperties.data?.properties?.clientRow;

	const [updateClientRow, { isLoading: isUpdatingClient }] = useUpdateClientRowMutation();

	const isProspect = clientRow?.isProspect;

	const brokerGroupId = clientRow?.brokerGroupId;
	const formsQuery = useGetFormsQuery({ brokerRef: brokerGroupId }, { skip: !brokerGroupId });

	const objectType = 'client';
	const contactId = clientProperties.data?.contacts[0];
	const contactEmail = clientRow?.contactEmail;

	const contactProperties = useGetContactPropertiesQuery(
		{ hubspotId: contactId, email: contactEmail },
		{ skip: !contactId || !contactEmail }
	);

	const dealIds = clientProperties.data?.deals;
	const dealsProperties = useGetDealsListByIdsQuery(
		{ clientId, dealIds, includePolicies: true },
		{ skip: !clientId || !dealIds }
	);

	const ticketIds = clientProperties.data?.claims;
	const claimsProperties = useGetTicketsListByIdsQuery(
		{ clientId, ticketIds, includePolicies: IS_DEV },
		{ skip: !clientId || !ticketIds }
	);

	const ownerId = clientProperties?.data?.properties?.hubspot_owner_id;

	const ownerProperties = useGetOwnerQuery(
		{ ownerId },
		{
			skip: !ownerId,
		}
	);

	const { data: userDetails } = useGetUserDetailsQuery(
		{ hubspotId: ownerId },
		{ skip: !ownerId }
	);

	const activitiesQuery = useGetAllDealActivitiesQuery(
		{ clientId: clientId },
		{ skip: !clientId }
	);

	const isValidSharepoint =
		userDetails?.sharepointSite != null &&
		userDetails?.clientSiteSuffix != null &&
		userDetails?.clientFolder != null &&
		userDetails?.clientSite != null;

	const [msgFiles, setMsgFiles] = useState([]);

	const { updateClientGraphProperties, getClientGraphFolders } =
		useClientStorageHooks(userDetails);
	// const { getSharepointSiteDrives, querySharepointSites, getClientFolderDrive, getClientFolders } = useMsGraph();


	useEffect(() => {
		const updateMsGraph = async () => {
			if (clientRow?.driveId) {
				const { files } = await getClientGraphFolders(clientRow);
				const newMsgFiles = (files ?? []).filter(
					(f) => f?.file?.mimeType == 'application/vnd.ms-outlook'
				);
				setMsgFiles(
					newMsgFiles.slice().map((f) => ({
						...f,
						lastUpdated: new Date(f.lastModifiedDateTime).getTime(),
					}))
				);
			} else {
				await updateClientGraphProperties(clientRow);
				// if (
				// 	isValidSharepoint &&
				// 	userDetails?.sharepointSiteId &&
				// 	userDetails?.clientFolderDriveId &&
				// 	clientRow?.driveId == null
				// ) {
				// 	await createSharepoint();
				// }
			}
			dispatch(setLoading(false));
		};
		if (userDetails && clientRow) {
			dispatch(setLoading(true));
			updateMsGraph();
		}
	}, [userDetails, clientRow, isValidSharepoint]);

	const engagementsQuery = useGetEngagementsQuery(
		{ dealId: clientId, objectType: objectType },
		{ skip: !clientId }
	);

	const { data: policies, isLoading: isLoadingPolicies } = useGetClientPoliciesQuery(
		{ insightId: clientRow?.insightId, hubspotId: clientRow?.hubspotId },
		{ skip: !clientRow?.insightId && !clientRow?.hubspotId }
	);

	useEffect(() => {
		const updateClientType = async () => {
			await updateClientRow({
				clientId,
				docId: clientRow?.docId,
				properties: { isProspect: false },
			}).unwrap();
		};
		if (isProspect && (policies ?? []).length > 0) {
			updateClientType();
		}
	}, [policies]);

	useEffect(() => {
		dispatch(
			setLoading(
				engagementsQuery.isFetching ||
					engagementsQuery.isUninitialized ||
					engagementsQuery.isLoading ||
					activitiesQuery.isLoading ||
					activitiesQuery.isFetching
			)
		);
	}, [
		engagementsQuery.isFetching,
		engagementsQuery.isLoading,
		activitiesQuery.isLoading,
		activitiesQuery.isFetching,
	]);

	// engagementsQuery.isLoading;
	const isError =
		clientProperties.isError ||
		dealsProperties.isError ||
		ownerProperties.isError ||
		engagementsQuery.isError; // || clientRowQuery.isError;

	// Context value
	const contextValue = {
		objectType,
		client: clientProperties.data?.properties || {},
		contact: contactProperties.data?.properties || {},
		isProspect,
		deals: dealsProperties.data || [],
		claims: claimsProperties.data || [],
		notes: engagementsQuery.data?.notes || [],
		tasks: engagementsQuery.data?.tasks || [],
		calls: engagementsQuery.data?.calls || [],
		emails: engagementsQuery.data?.emails || [],
		msgFiles,
		forms: formsQuery.data?.data || [],
		quotes: [],
		activities: activitiesQuery.data?.data ?? [],
		sharepoint: {
			sharepointSite: userDetails?.sharepointSite,
			clientSiteSuffix: userDetails?.clientSiteSuffix,
			clientFolder: userDetails?.clientFolder,
			clientSite: userDetails?.clientSite,
			isValid: isValidSharepoint,
		},
		owner: ownerProperties.data || {},
		loading: {
			deals:
				dealsProperties.isLoading ||
				claimsProperties.isLoading ||
				dealsProperties.isUninitialized ||
				claimsProperties.isUninitialized,
			client:
				clientProperties.isLoading || clientProperties.isUninitialized || isUpdatingClient,
			contact: contactProperties.isLoading || contactProperties.isUninitialized,
			owner: ownerProperties.isLoading || ownerProperties.isUninitialized,
			forms: formsQuery.isLoading || formsQuery.isUninitialized,
		},
	};

	useEffect(() => {
		console.log('📋 ~ CLIENT CONTEXT VALUE', contextValue);
	}, [contextValue]);

	return (
		// Provide the context variables to the children components.
		<ClientContext.Provider value={contextValue}>
			{isError ? (
				<Box>
					<Typography textAlign='center'>
						There was an issue loading this client. Please try again.
					</Typography>
				</Box>
			) : (
				children
			)}
		</ClientContext.Provider>
	);
};

export default ClientContext;
