import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, useMediaQuery, useTheme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useLocation } from 'react-router-dom';

import CustomNavLink from './CustomNavLink';

export const SideBarListItem = ({ item }) => {
	const { isCollapsed } = useSelector((state) => state.sideNavSlice);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const location = useLocation();

	const collapsed = isCollapsed && !isMobile;
	const basePath = '/clients';

	// Preserve your existing "selected" logic if item.link exists:
	const isSelected = item.link
		? item.link === '/'
			? location.pathname.startsWith(basePath)
			: location.pathname.includes(item.link)
		: false;

	/**
   * If item.onClick is present, we run that function (instead of navigating).
   * Otherwise, we let <CustomNavLink> handle navigation as before.
   */
	const handleClick = (event) => {
		if (item.onClick) {
			// Prevent navigation if there's an onClick
			event.preventDefault();
			item.onClick();
		}
	};

	return (
		<ListItem
			key={item.text}
			disableGutters={collapsed}
			disablePadding
		>
			<Tooltip title={collapsed ? item.text : ''} placement="right">
				<ListItemButton
					// If there's an onClick, we'll just use a 'div' for the component
					// so it won't attempt to navigate. Otherwise, use CustomNavLink.
					component={item.onClick ? 'div' : CustomNavLink}
					to={item.onClick ? undefined : item.link}
					onClick={handleClick}
					sx={{
						borderRadius: '20px',
						'&:hover': {
							backgroundColor: 'rgba(0, 0, 0, 0.1)',
						},
						'&.Mui-selected': {
							backgroundColor: '#505AFC',
						},
						...(collapsed && {
							paddingLeft: 0,
							paddingRight: 0,
							justifyContent: 'center',
						}),
					}}
					className={isSelected ? 'Mui-selected' : ''}
				>
					<ListItemIcon
						sx={{
							color: '#FAFDFF',
							...(collapsed && {
								minWidth: '24px',
								display: 'flex',
								justifyContent: 'center',
							}),
						}}
					>
						{item.icon}
					</ListItemIcon>
					{!collapsed && (
						<ListItemText
							primary={item.text}
							sx={{ fontWeight: 400, marginLeft: '-12px', color: '#FAFDFF' }}
						/>
					)}
				</ListItemButton>
			</Tooltip>
		</ListItem>
	);
};
