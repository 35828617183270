import React, { useState, useEffect } from 'react';
import { 	Box,
	TextField,
	Button,
	FormLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import ColorPicker from '@/components/ColourPicker';
import {useUpdateBrokerMutation,} from '@/features/user/userApi';
import GooglePlacesAutocomplete from '@/components/selects/GoogleAddressSelect';
import { useFormik } from 'formik';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useSelector } from 'react-redux';


export const BrokerageSettings = () => {
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const { data: userDetails, isLoading: isUserLoading } =
		useGetUserDetailsQuery({ email }, { skip: !email });


	const [updateBroker] = useUpdateBrokerMutation();
	const [colors, setColors] = useState({
		primary: '#505afc',
		secondary: '#DADCFF',
		tertiary: '#333333',
	});

	const handleColorChange = (field, color) => {
		setColors((prev) => ({ ...prev, [field]: color }));
	};

	const handleSave = async () => {
		await updateBroker({
			docId: 'brokerId',
			properties: { colours: colors },
		}).unwrap();
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			emailField: '',
			fspNumber: '',
			claimsEmail: '',
			website: '',
			about: '',
			streetAddress: '',
			suburb: '',
			city: '',
			postcode: '',					
			privacyStatement: '',
			termsOfEngagement: '',
		},
	});

	useEffect(() => {
		if (userDetails) {
			formik.setFieldValue('emailField', userDetails.email ?? '');
			formik.setFieldValue('claimsEmail', userDetails.claimsEmail ?? '');
			formik.setFieldValue('website', userDetails.website ?? '');
			formik.setFieldValue('about', userDetails.about ?? '');	
			formik.setFieldValue('disclosureUrl', userDetails.disclosureUrl ?? '');
			formik.setFieldValue('privacyStatement', userDetails.privacyStatement ?? '');
			formik.setFieldValue('termsOfEngagement', userDetails.termsOfEngagement ?? '');

			if (userDetails.businessAddress) {
				const parts = userDetails.businessAddress.split(',').map((p) => p.trim());
				formik.setFieldValue('streetAddress', parts[0] ?? '');
				formik.setFieldValue('suburb', parts[1] ?? '');
				formik.setFieldValue('city', parts[2] ?? '');
				formik.setFieldValue('postcode', parts[3] ?? '');
			}

			if (userDetails) {
				console.log('Updated Colors:', userDetails.colours);
			}
			if (userDetails.colours) {
				setColors({
					primary: userDetails.colours.primary || '#505afc',
					secondary: userDetails.colours.secondary || '#DADCFF',
					tertiary: userDetails.colours.tertiary || '#333333',
				});
			}
		}
	}, [userDetails]);

	const handlePlaceSelect = (place) => {
		if (!place?.address_components) return;
		const addressComponents = place.address_components;

		const streetNumber = addressComponents.find((comp) => comp.types.includes('street_number'))?.long_name || '';
		const route = addressComponents.find((comp) => comp.types.includes('route'))?.long_name || '';
		const combinedStreet = [streetNumber, route].filter(Boolean).join(' ');
		const suburb =addressComponents.find((comp) => comp.types.includes('sublocality_level_1'))?.long_name || addressComponents.find((comp) => comp.types.includes('sublocality'))?.long_name || '';
		const city = addressComponents.find((comp) => comp.types.includes('locality'))?.long_name || '';
		const postcode = addressComponents.find((comp) => comp.types.includes('postal_code'))?.long_name || '';

		formik.setFieldValue('streetAddress', combinedStreet.trim());
		formik.setFieldValue('suburb', suburb);
		formik.setFieldValue('city', city);
		formik.setFieldValue('postcode', postcode);
	};

	return (
		<Box sx={{pt: 2, pb: 1}}>
			<Grid container spacing={2}>
				{['primary', 'secondary', 'tertiary'].map((color) => (
					<Grid size={4} key={color}>
						<ColorPicker
							label={color.charAt(0).toUpperCase() + color.slice(1)}
							initialColor={colors[color]}
							onChange={(newColor) => handleColorChange(color, newColor)}
						/>
					</Grid>
				))}
			</Grid>
			
			<Grid size={12}>
				<FormLabel>Search Address</FormLabel>
				<GooglePlacesAutocomplete label="" address="" onSelect={handlePlaceSelect} />
			</Grid>
			<Grid container spacing={4} my={2}>
				<Grid size={6} >
					<FormLabel>Street Address</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="streetAddress"
						value={formik.values.streetAddress}
						onChange={formik.handleChange}
					/>
				</Grid>			
				<Grid size={6}>
					<FormLabel>Suburb</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="suburb"
						value={formik.values.suburb}
						onChange={formik.handleChange}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={4} mb={2}>
				<Grid size={6}>
					<FormLabel>City</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="city"
						value={formik.values.city}
						onChange={formik.handleChange}
					/>
				</Grid>
				<Grid size={6}>
					<FormLabel>Postcode</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="postcode"
						value={formik.values.postcode}
						onChange={formik.handleChange}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={4} my={2}>
				<Grid size={6}>
					<FormLabel>Claims Email</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="claimsEmail"
						value={formik.values.claimsEmail}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.claimsEmail && Boolean(formik.errors.claimsEmail)}
						helperText={formik.touched.claimsEmail && formik.errors.claimsEmail}
					/>
				</Grid>
				<Grid size={6}>
					<FormLabel>Website</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="website"
						value={formik.values.website}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.website && Boolean(formik.errors.website)}
						helperText={formik.touched.website && formik.errors.website}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={4} my={2}>
				<Grid size={6}>
					<FormLabel>Privacy Statement</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="privacyStatement"
						value={formik.values.privacyStatement}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.privacyStatement && Boolean(formik.errors.privacyStatement)}
						helperText={formik.touched.privacyStatement && formik.errors.privacyStatement}
					/>
				</Grid>
				<Grid size={6}>
					<FormLabel>Terms of Engagement</FormLabel>
					<TextField
						fullWidth
						variant="outlined"
						name="termsOfEngagement"
						value={formik.values.termsOfEngagement}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.termsOfEngagement && Boolean(formik.errors.termsOfEngagement)}
						helperText={formik.touched.termsOfEngagement && formik.errors.termsOfEngagement}
					/>
				</Grid>
			</Grid>
			<Grid size={12}>
				<FormLabel>About the Brokerage</FormLabel>
				<TextField
					fullWidth
					variant="outlined"
					name="about"
					multiline
					rows={4}
					value={formik.values.about}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.about && Boolean(formik.errors.about)}
					helperText={formik.touched.about && formik.errors.about}
				/>
			</Grid>
			<Button onClick={handleSave} variant="contained" sx={{mt: 1}}>
				Save 
			</Button>
		</Box>
	);
};
