import {
	BOAT,
	BUSINESS_INTERRUPTION,
	CARRIERS_LIABILITY,
	COMMERCIAL_MOTOR,
	CONTENTS,
	CONTRACTORS_PLANT_AND_MACHINERY,
	CYBER_LIABILITY,
	EMPLOYERS_LIABILITY,
	GENERAL_LIABILITY,
	HOUSE,
	MANAGEMENT_LIABILITY,
	MARINE_CARGO,
	MATERIAL_DAMAGE,
	PRIVATE_VEHICLE,
	PROFESSIONAL_INDEMNITY,
	STATUTORY_LIABILITY,
} from '../CreateNewBusinessForm/validationSchema';

export const FIELDS = {
	businessDetails: {
		companyName: { columns: 12 },
		insuredAddress: { columns: 12 },
		businessActivities: { columns: 12, lines: 2 },
		currentlyPaying: { columns: 6, type: 'currency' },
		currentRenewalDate: { columns: 6 },
		currentBrokingHouse: { columns: 6 },
		currentInsurer: { columns: 6 },
		annualTurnover: { columns: 6, type: 'currency' },
		numberOfEmployees: { columns: 6, type: 'numeric' },
		claimsHistory: { columns: 12, lines: 2 },
	},
	whatWeAgreedToFocusOn: {
		clientType: { columns: 12 },
		// lendingAndFinance: { columns: 6 },
		personalInsurance: { columns: 12 },
		goalsOfCover: {
			columns: 12,
			lines: 2,
		},
	},
	scopeOfAdvice: {
		[HOUSE]: { columns: 6 },
		[PRIVATE_VEHICLE]: { columns: 6 },
		[CONTENTS]: { columns: 6 },
		[BOAT]: { columns: 6 },
		[MATERIAL_DAMAGE]: { columns: 6 },
		[BUSINESS_INTERRUPTION]: { columns: 6 },
		[COMMERCIAL_MOTOR]: { columns: 6 },
		[GENERAL_LIABILITY]: { columns: 6 },
		[STATUTORY_LIABILITY]: { columns: 6 },
		[EMPLOYERS_LIABILITY]: { columns: 6 },
		[MANAGEMENT_LIABILITY]: { columns: 6 },
		[PROFESSIONAL_INDEMNITY]: { columns: 6 },
		[CYBER_LIABILITY]: { columns: 6 },
		[CARRIERS_LIABILITY]: { columns: 6 },
		[CONTRACTORS_PLANT_AND_MACHINERY]: { columns: 6 },
		[MARINE_CARGO]: { columns: 6 },
		commentsOnScope: { columns: 12, lines: 2 },
	},
	whatHasBeenSentToMarket: {
		[HOUSE]: { table: true },
		[PRIVATE_VEHICLE]: { table: true },
		[CONTENTS]: { table: true },
		[BOAT]: { table: true },
		[MATERIAL_DAMAGE]: { table: true },
		[BUSINESS_INTERRUPTION]: { table: true },
		[COMMERCIAL_MOTOR]: { table: true },
		[GENERAL_LIABILITY]: { table: true },
		[STATUTORY_LIABILITY]: { table: true },
		[EMPLOYERS_LIABILITY]: { table: true },
		[MANAGEMENT_LIABILITY]: { table: true },
		[PROFESSIONAL_INDEMNITY]: { table: true },
		[CYBER_LIABILITY]: { table: true },
		[CARRIERS_LIABILITY]: { table: true },
		[CONTRACTORS_PLANT_AND_MACHINERY]: { table: true },
		[MARINE_CARGO]: { table: true },
		stockAndOtherProperty: { columns: 12 },
	},
	results: {},
	myRecommendation: { 
		summaryOfRecommendations: { columns: 12, lines: 2 },
		brokerFee: { columns: 6, type: 'currency' },
		transactionFee: { columns: 6, type: 'currency' },
	},
};