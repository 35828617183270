/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	FormHelperText,
	TextField,
	Select,
	MenuItem,
	FormLabel,
	ListItemIcon,
	Box,
	ToggleButtonGroup,
	ToggleButton,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import GroupsIcon from '@mui/icons-material/Groups';
import { Formik, FormikContext, useFormik } from 'formik';
import * as Yup from 'yup';
import { addDays, getUnixTime, isAfter, isValid, roundToNearestMinutes } from 'date-fns';
import {
	taskTypes,
	getUrlFromPipeline,
	formatDate,
	getTimeFromDate,
	formatTimeToHourMinutes,
} from '@/utils/constants';
import {
	useCreateTaskMutation,
	useGetTaskDocumentByIdQuery,
	useUpdateTaskMutation,
} from '@/features/engagements/engagementsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { InstantDealSelect } from '@/components/selects/DealSelect';
import { InstantClientSelect } from '@/components/selects/ClientSelect';
import { PrioritySelect } from '@/components/selects/PrioritySelect';
import { OwnerSelect } from '@/components/selects/OwnerSelect';
import { showToast } from '@/features/toast/toastSlice';
import { determineContext } from '@/hooks/determineContext';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { useMsGraph } from '@/hooks/useMsGraph';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { useGetBrokerOwnersQuery, useGetOwnerQuery } from '@/features/user/ownerApi';
import { AddressFields } from '@/components/forms/CreateNewBusinessForm/AddressFields';
import { validationSchema } from './validationSchema';
import { FieldValue } from 'firebase/firestore';

const minDate = new Date();

export const AddTaskDialog = () => {
	const dispatch = useDispatch();

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	const { data: userDetails } = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);

	const hubspotId = userDetails?.hubspotId;
	const brokers = useGetBrokerOwnersQuery(hubspotId, {
		skip: !hubspotId,
	});

	const [updateTask] = useUpdateTaskMutation();

	const open = useSelector((state) => state.dialog.open['task']);
	const dialogData = useSelector((state) => state.dialog.data ?? {});

	const [eventTaskMsData, setEventTaskMsData] = useState({});
	const [loadingMsData, setLoadingMsData] = useState(true);

	useEffect(() => console.log('here is the task dialog data: ', dialogData), [dialogData]);

	const taskId = useMemo(
		() => dialogData?.task?.task?.id ?? dialogData?.task?.engagement?.id,
		[dialogData]
	);
	const ownerId = useMemo(
		() =>
			dialogData?.task?.task?.hubspot_owner_id ??
			dialogData?.task?.engagement?.hubspot_owner_id,
		[dialogData]
	);

	const initialTaskType = useMemo(() => {
		const taskDialogData = dialogData?.task?.task ?? dialogData?.task?.engagement ?? {};
		return taskDialogData?.hs_task_type ?? taskDialogData?.metadata?.taskType ?? taskTypes[0];
	}, [dialogData]);

	useEffect(() => {
		if (!taskId) {
			setLoadingMsData(false);
		}
	}, [taskId]);

	const { isLoading: isLoadingTaskDocument, ...taskDocQuery } = useGetTaskDocumentByIdQuery(
		String(taskId),
		{
			skip: !taskId,
		}
	);

	const taskDocData = useMemo(
		() => (taskId ? taskDocQuery.data ?? {} : {}),
		[taskDocQuery.data, taskId]
	);

	const { data: taskUserData } = useGetUserDetailsQuery(
		{ hubspotId: ownerId },
		{
			skip: !ownerId,
		}
	);
	const [userId, setUserId] = useState();

	const {
		createOnlineMeetingEvent,
		createEventTask,
		getUserIdByEmail,
		getGraphEventData,
		updateGraphEventData,
	} = useMsGraph();
	// } = useMsGraph(userId);

	useEffect(() => {
		const getUserId = async () => {
			try {
				const id = await getUserIdByEmail(taskUserData?.email);
				setUserId(id);
			} catch (e) {
				console.log('Error retrieving user ID from MSAL', e);
			}
		};
		if (taskUserData?.email) {
			getUserId();
		}
	}, [taskUserData]);

	useEffect(() => {
		console.log('here is the task firebase data: ', taskDocData);
		const fetchData = async () => {
			if (taskDocData && taskDocData?.graphEventId) {
				setLoadingMsData(true);
				try {
					const eventData = await getGraphEventData(
						taskDocData?.taskType,
						taskDocData?.graphEventId
					);
					console.log('here is the event data:', eventData);
					setEventTaskMsData(eventData);
				} catch (error) {
					console.error('Error fetching event data:', error);
					setEventTaskMsData({});
				} finally {
					setLoadingMsData(false);
				}
			} else {
				setEventTaskMsData({});
			}
		};
		fetchData();
	}, [taskDocData]);

	const name = account?.name;
	const company = userDetails?.broker?.name;

	const initialValues = useMemo(() => {
		// Standardize the task data to handle both 'task.task' and 'task.engagement'
		const taskDialogData = dialogData?.task?.task ?? dialogData?.task?.engagement ?? {};
		console.log(
			'Here is the generic task dialog data:',
			taskDialogData,
			'ms data',
			eventTaskMsData,
			'task doc data',
			taskDocData,
			'task id',
			taskId
		);

		const date = taskDialogData?.hs_timestamp
			? new Date(taskDialogData.hs_timestamp)
			: taskDialogData?.data?.timestamp
				? new Date(taskDialogData.data.timestamp)
				: addDays(minDate, 3);

		let startTime = new Date(date);
		startTime.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
		startTime = roundToNearestMinutes(startTime, { nearestTo: 15, roundingMethod: 'ceil' });

		let endTime = new Date(startTime);
		endTime.setHours(endTime.getHours(), endTime.getMinutes() + 30, 0, 0);

		if (taskDocData?.startTime) {
			startTime = new Date(taskDocData?.startTime);
		}
		if (taskDocData?.endTime) {
			endTime = new Date(taskDocData?.endTime);
		}

		if (eventTaskMsData?.start?.dateTime && eventTaskMsData?.end?.dateTime) {
			const utcStartDate = new Date(eventTaskMsData.start.dateTime);
			const utcEndDate = new Date(eventTaskMsData.end.dateTime);

			const offsetInMinutes = 13 * 60;
			startTime = new Date(utcStartDate.getTime() + offsetInMinutes * 60 * 1000);
			endTime = new Date(utcEndDate.getTime() + offsetInMinutes * 60 * 1000);
		}

		return {
			title: taskDialogData?.hs_task_subject ?? taskDialogData?.subject ?? ' ',
			dueDate: date,
			priority: taskDialogData?.hs_task_priority ?? taskDialogData?.priority ?? 'LOW',
			notes: taskDialogData?.hs_task_body ?? taskDialogData?.body ?? ' ',
			taskType:
				initialTaskType,
			errorMessage: '',
			deal:
				taskDialogData?.deal ??
				taskDialogData?.data?.deal ??
				taskDialogData?.ticket ??
				taskDialogData?.data?.ticket,
			client:
				taskDialogData?.client ?? taskDialogData?.data?.client ?? dialogData?.task?.client,
			contact: null,
			objectType: null,
			ownerId:
				taskDialogData?.hubspot_owner_id ??
				taskDialogData?.ownerId ??
				userDetails?.hubspotId,
			attendees:
				(eventTaskMsData?.attendees ?? []).length > 0
					? eventTaskMsData?.attendees?.map((contact) => ({
						emailAddress: contact?.emailAddress?.address ?? contact.email,
						name: contact?.emailAddress?.name,
					  }))
					: (taskDocData.attendees ?? []).map((contact) => ({
						emailAddress:
								contact?.email ??
								contact?.address ??
								contact?.emailAddress?.address ??
								'',
						name: contact?.name ?? contact?.emailAddress?.name ?? '',
					  })),
			userId: null,
			isOnlineMeeting: taskDocData?.isOnlineMeeting ?? false,
			startTime,
			address:
				eventTaskMsData?.location?.displayName ??
				eventTaskMsData?.locations?.[0]?.displayName ??
				taskDocData?.address ??
				'',
			endTime,
			isExistingTask: Boolean(taskId),
			// isExistingTask:
			// 	!!(taskData && Object.keys(taskData).length > 0) &&
			// 	!!(eventTaskMsData && Object.keys(eventTaskMsData).length > 0),
		};
	}, [dialogData, eventTaskMsData, taskId, taskDocData, initialTaskType]);

	const { deal, client, contact, objectType } = determineContext() ?? initialValues;

	// useEffect(
	// 	() => console.log('Task context', taskDocData, 'DEAL', deal, 'CLIENT', client),
	// 	[deal, client, taskDocData]
	// );

	const [createTask, { isLoading }] = useCreateTaskMutation();

	const getDefaultDateTime = (date) => {
		date.setHours(12, 0, 0, 0);
		return date;
	};

	const handleClose = () => {
		dispatch(closeDialog('task'));
	};

	const handleTaskSubmission = async (graphId) => {
		const attendees = formik.values.attendees.map((contact) => ({
			address: contact?.contactEmail || contact?.email || contact?.emailAddress,
			name:
				contact?.contactName ||
				`${contact?.firstName || ''} ${contact?.lastName || contact?.name || ''}`.trim(),
		}));

		const dealId = formik.values.deal?.hs_object_id ?? formik.values.deal?.id;
		const clientId = formik.values.client?.id ?? formik.values.client?.hs_object_id;
		const contactId = formik.values.contact?.id ?? formik.values.contact?.hs_object_id;

		const pipeline = formik.values.deal?.pipeline;
		const type = formik.values.deal?.objectType ?? objectType;
		const taskDialogData = dialogData?.task?.task ?? dialogData?.task?.engagement ?? {};

		// 	const updatedTitle =
		//   formik.values.taskType === 'MEETING' || formik.values.taskType === 'CALL'
		//   	? `${formik.values.title} (${formatTimeToHourMinutes(
		//   		formik.values.startTime
		//   	)} - ${formatTimeToHourMinutes(formik.values.endTime)})`
		//   	: formik.values.title;

		const body = {
			title: formik.values.title.trim(),
			dueDate: getUnixTime(formik.values.dueDate) * 1000,
			priority: formik.values.priority,
			notes: (formik.values.notes ?? '').trim(),
			hubspotId: formik.values.ownerId,
			type: formik.values.taskType,
			...(type && { objectType: type }),
			...(dealId && { dealId }),
			...(clientId && { clientId }),
			...(contactId && { contactId }),
			...(graphId && { graphId }),
			...((userId || account?.homeAccountId) && {
				graphUserId: userId ?? account?.homeAccountId,
			}),
			...(attendees.length > 0 && { attendees }),
			...(formik.values.startTime && { startTime: formik.values.startTime.toISOString() }),
			...(formik.values.endTime && { endTime: formik.values.endTime.toISOString() }),
			...((formik.values.address ?? '').length > 0 &&
				!formik.values.isOnlineMeeting && { address: formik.values.address }),
			isOnlineMeeting: formik.values.isOnlineMeeting,
		};

		console.log('📋 ~ Create task object', body);

		if (!formik.values.isExistingTask) {
			const response = await createTask(body).unwrap();
			return { response, body: { ...body, pipeline } };
		} else {
			const res = await updateTask({
				taskId: taskDialogData?.id,
				selectedDeal: formik?.values?.deal,
				clientId: clientId,
				existingClientId: taskDialogData?.client?.hs_object_id,
				existingDeal:
					taskDialogData?.deal?.properties ??
					taskDialogData?.data?.deal?.properties ??
					taskDialogData?.ticket?.properties ??
					taskDialogData?.data?.ticket?.properties,
				objectType: formik?.values?.deal?.objectType,
				properties: {
					hs_task_subject: formik.values.title.trim(),
					hs_task_body: formik.values.notes.trim(),
					hubspot_owner_id: formik.values.ownerId,
					hs_timestamp: getUnixTime(formik.values.dueDate) * 1000,
					hs_task_priority: formik.values.priority.toUpperCase(),
					hs_task_type: formik.values.taskType.toUpperCase(),
				},
				...(attendees.length > 0
					? { attendees }
					: taskDocData?.attendees
						? { attendees: FieldValue.delete() }
						: {}),
				...(formik.values.startTime
					? { startTime: formik.values.startTime.toISOString() }
					: taskDocData?.startTime
						? { startTime: FieldValue.delete() }
						: {}),
				...(formik.values.endTime
					? { endTime: formik.values.endTime.toISOString() }
					: taskDocData?.endTime
						? { endTime: FieldValue.delete() }
						: {}),
				...((formik.values.address ?? '').length > 0 &&
					!formik.values.isOnlineMeeting && { address: formik.values.address }),
				isOnlineMeeting: formik.values.isOnlineMeeting,
			}).unwrap();
			return { res, body };
		}
	};

	const formik = useFormik({
		initialValues: {
			...initialValues,
			...((objectType === 'deal' || objectType === 'ticket') && deal && { deal }),
			...(objectType === 'client' && client && { client }),
			...(objectType === 'contact' && contact && { contact }),
			...(objectType && { objectType }),
		},
		validationSchema,
		enableReinitialize: true,
		validateOnBlur: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			console.log('📋 ~ Formik values', values);

			const endTime = formik.values.endTime
				? formik.values.endTime.toISOString()
				: getDefaultDateTime(formik.values.dueDate).toISOString();
			const startTime = formik.values.startTime
				? formik.values.startTime.toISOString()
				: getDefaultDateTime(formik.values.dueDate).toISOString();
			const taskWithClient =
				formik.values?.client?.name?.length > 0 || client?.name
					? `${client?.name ?? formik.values?.client?.name} - ${formik.values.title}`
					: formik.values.title;

			const attendees = formik.values.attendees.map((contact) => ({
				emailAddress: {
					address: contact?.contactEmail || contact?.email || contact?.emailAddress,
					name:
						contact?.contactName ||
						`${contact?.firstName || ''} ${
							contact?.lastName || contact?.name || ''
						}`.trim(),
				},
			}));

			const eventBody = {
				subject: taskWithClient,
				body: {
					contentType: 'HTML',
					content: formik.values.notes ?? '',
				},
				start: {
					dateTime: startTime,
					timeZone: 'New Zealand Standard Time',
				},
				end: {
					dateTime: endTime,
					timeZone: 'New Zealand Standard Time',
				},
				location: {
					displayName: formik.values.address,
				},
				attendees,
				isOnlineMeeting: formik.values.isOnlineMeeting || false,
			};

			try {
				if (formik.values.isExistingTask) {
					console.log('Editing task!');

					try {
						if (eventTaskMsData?.id) {
							const updatedEventRes = await updateGraphEventData(
								initialTaskType,
								eventTaskMsData?.id,
								eventBody
							);
							console.log('Updated event response: ', updatedEventRes);
						} else {
							console.log('Could not find MS Graph event to update');
						}
					} catch (e) {
						console.log('Could not update MS Graph event task', e);
					}

					const res = await handleTaskSubmission();
					console.log('here is the update task res: ', res);
					//set deal or client id to null if no client or deal selected
					// Update the task
					if (res) {
						console.log('🚀 ~ Successfully updated task', res);
						
						// Navigate or show success message as before
						const path = window.location.pathname;
						let newPath, params;
						if (deal) {
							const dealPipeline =
								deal?.pipeline ??
								deal?.properties?.hs_pipeline ??
								deal?.properties?.pipeline;
							const pathName = getUrlFromPipeline(dealPipeline);
							newPath = pathName && deal?.id ? `${pathName}/${deal?.id}` : path;
							params = { state: { task: taskId } };
						} else if (client) {
							newPath = client?.hs_object_id
								? `/clients/${client?.hs_object_id}`
								: path;
							params = { state: { task: taskId } };
						}

						dispatch(
							showToast({
								message: 'Task updated successfully!',
								autohide: true,
								...(path.startsWith('/my-tasks') && newPath && {
									autohide: false,
									action: {
										path: newPath,
										params,
										label: 'GO TO TASK',
									},
								}),
							})
						);
						resetForm();
						handleClose();
					} else {
						console.log('🙅 ~ Error updating task');
						setFieldValue('errorMessage', 'Error updating task. Please try again');
					}
				} else {
					console.log('Creating task!');

					let graphEventId;
					if (formik.values.taskType === 'MEETING') {
						const meetingEventRes = await createOnlineMeetingEvent(eventBody);
						console.log('Meeting event response: ', meetingEventRes);
						graphEventId = meetingEventRes?.id;
					} else {
						const toDoEventRes = await createEventTask(eventBody);
						console.log('Event to-do response: ', toDoEventRes);
						graphEventId = toDoEventRes?.id;
					}

					const updatedTaskType =
						formik.values.taskType === 'MEETING' ? 'TODO' : formik.values.taskType;
					formik.setFieldValue('taskType', updatedTaskType);

					const res = await handleTaskSubmission(graphEventId);
					if (res) {
						console.log('🚀 ~ Successfully created task', res);
						const newTaskId = res.response?.id;
						const path = window.location.pathname;
						const { clientId, dealId, pipeline } = res.body;
						let newPath, params;
						if (dealId) {
							const pathName = getUrlFromPipeline(pipeline);
							newPath = pathName ? `${pathName}/${dealId}` : path;
							params = { state: { task: newTaskId } };
						} else if (clientId) {
							newPath = `/clients/${clientId}`;
							params = { state: { task: newTaskId } };
						}

						dispatch(
							showToast({
								message: 'New task created!',
								autohide: true,
								...(path.startsWith('/my-tasks') && newPath && {
									autohide: false,
									action: {
										path: newPath,
										params,
										label: 'GO TO TASK',
									},
								}),
							})
						);
						resetForm();
						handleClose();
					} else {
						console.log('🙅 ~ Error creating task');
						setFieldValue('errorMessage', 'Error creating task. Please try again');
					}
				}
			} catch (err) {
				console.log('🙅 ~ Error occurred', err);
				setFieldValue('errorMessage', 'An error occurred. Please try again');
			} finally {
				setSubmitting(false);
			}
		},
	});

	const setStartTime = (value) => {
		let newStartTime = formik.values.dueDate ? new Date(formik.values.dueDate) : new Date();
		newStartTime.setHours(value.getHours(), value.getMinutes(), 0, 0);
		newStartTime = roundToNearestMinutes(newStartTime, {
			nearestTo: 15,
			roundingMethod: 'ceil',
		});
		formik.setFieldValue('startTime', newStartTime);
		const newEndTime = new Date(newStartTime.getTime() + 30 * 60 * 1000);
		formik.setFieldValue('endTime', newEndTime);
	};

	const { getError, getErrorMessage } = useFormikHelper(formik);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			disableEnforceFocus
			sx={{ minWidth: '50%', padding: '1em' }}
		>
			<form onSubmit={formik.handleSubmit}>
				<DialogTitle>
					{`${formik.values.isExistingTask ? 'Update' : 'Create'} Task`}
				</DialogTitle>
				<DialogContent sx={{ paddingBottom: '2em' }}>
					<Grid container spacing={2}>
						<Grid size={6}>
							<FormLabel>{'Task Type'}</FormLabel>
							<Select
								name='taskType'
								value={formik.values.taskType}
								disabled={formik.isSubmitting}
								onChange={(e) => {
									const selectedValue = e.target.value;
									formik.setFieldValue('taskType', selectedValue);
								}}
								fullWidth={true}
								renderValue={(value) => (
									<Box display='flex' alignItems='center'>
										{value === 'CALL' ? (
											<PhoneIcon fontSize='small' />
										) : value === 'EMAIL' ? (
											<EmailIcon fontSize='small' />
										) : value === 'MEETING' ? (
											<GroupsIcon fontSize='small' />
										) : (
											<ChecklistIcon fontSize='small' />
										)}
										<Typography
											sx={{ paddingLeft: '0.5em' }}
											variant='body1'
											noWrap
										>
											{value}
										</Typography>
									</Box>
								)}
							>
								{taskTypes.map((value) => {
									return (
										<MenuItem
											value={value}
											key={`task-type-selection-${value}`}
										>
											<ListItemIcon>
												{value === 'CALL' ? (
													<PhoneIcon size='small' />
												) : value === 'EMAIL' ? (
													<EmailIcon size='small' />
												) : value === 'MEETING' ? (
													<GroupsIcon size='small' />
												) : (
													<ChecklistIcon size='small' />
												)}
											</ListItemIcon>

											<Typography variant='body1' noWrap>
												{value}
											</Typography>
										</MenuItem>
									);
								})}
							</Select>
						</Grid>
						<Grid size={6}>
							<PrioritySelect
								disabled={formik.isSubmitting}
								priority={formik.values.priority}
								setPriority={(value) => formik.setFieldValue('priority', value)}
							/>
						</Grid>
						<Grid size={6}>
							<FormLabel required>{'Date'}</FormLabel>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									name='dueDate'
									disablePast={true}
									disabled={formik.isSubmitting}
									format='dd/MM/yyyy'
									value={formik.values.dueDate}
									sx={{ width: '100%' }}
									onChange={(value) => {
										const newDate =
											!isValid(value) || isAfter(minDate, value)
												? minDate
												: value;
										formik.setFieldValue('dueDate', newDate);
										setStartTime(newDate);
									}}
								/>
							</LocalizationProvider>
						</Grid>
						{(formik.values.taskType === 'MEETING' ||
							formik.values.taskType === 'CALL') && (
							<>
								<Grid size={3}>
									<FormLabel required>{'Start Time'}</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<TimePicker
											value={formik.values.startTime}
											// disablePast={true}
											// minTime={formik.values.dueDate}
											disabled={formik.isSubmitting}
											onChange={(value) => {
												if (value) {
													setStartTime(value);
												}
											}}
										/>
									</LocalizationProvider>
									{formik.touched.startTime && formik.errors.startTime && (
										<FormHelperText error>
											{formik.errors.startTime}
										</FormHelperText>
									)}
								</Grid>
								<Grid size={3}>
									<FormLabel required>{'End Time'}</FormLabel>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<TimePicker
											value={formik.values.endTime}
											minTime={formik.values.startTime}
											disabled={formik.isSubmitting}
											onChange={(value) => {
												const dueDate = new Date(formik.values.dueDate);
												const newEndTime = new Date(dueDate);
												newEndTime.setHours(
													value.getHours(),
													value.getMinutes(),
													value.getSeconds(),
													value.getMilliseconds()
												);
												formik.setFieldValue('endTime', newEndTime);
											}}
										/>
									</LocalizationProvider>
									{formik.touched.endTime && formik.errors.endTime && (
										<FormHelperText error>
											{formik.errors.endTime}
										</FormHelperText>
									)}
								</Grid>
							</>
						)}
						{formik.values.taskType === 'MEETING' && (
							<>
								<Grid size={12}>
									<FormLabel>{'Attendees'}</FormLabel>
									<InstantClientSelect
										// clientId={formik.values.client?.id}
										// clientName={formik.values[CLIENT_NAME]}
										disabled={formik.isSubmitting}
										showContact={true}
										attendees={formik.values.attendees}
										setClient={(contacts) => {
											if (contacts) {
												console.log(
													'here is the selected client: ',
													contacts
												);
												formik.setFieldValue('attendees', contacts);
											}
										}}
									/>
								</Grid>
								<Grid size={12}>
									<FormLabel>{'Meeting type'}</FormLabel>
									<ToggleButtonGroup
										color='primary'
										value={formik.values.isOnlineMeeting}
										size='small'
										exclusive
										onChange={(event, newValue) =>
											formik.setFieldValue(
												'isOnlineMeeting',
												newValue ?? false
											)
										}
										disabled={formik.isSubmitting}
										sx={{
											marginLeft: 2,
											boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
											'& .MuiToggleButtonGroup-grouped': {
												border: 'none',
												margin: '4px',
												padding: '3px 12px',
												'&:not(:first-of-type)': {
													borderRadius: '5px',
												},
												'&:first-of-type': {
													borderRadius: '5px',
												},
												color: 'rgba(189, 189, 189, 1)',
												backgroundColor: 'transparent',
												'&.Mui-selected': {
													color: 'rgba(80, 90, 252, 1)',
													backgroundColor: 'rgba(80, 90, 252, 0.33)',
												},
												'&:hover': {
													backgroundColor: 'rgba(80, 90, 252, 0.1)',
												},
											},
										}}
									>
										<ToggleButton value={false}>In person</ToggleButton>
										<ToggleButton value={true}>Online</ToggleButton>
									</ToggleButtonGroup>
								</Grid>
								{!formik.values.isOnlineMeeting && (
									<Grid size={12}>
										<FormLabel required>{'Location'}</FormLabel>
										<AddressFields
											formik={formik}
											minimal={true}
											loading={formik.isSubmitting}
										/>
										{/* <TextField
											fullWidth={true}
											name="location"
											{...formik.getFieldProps('location')}
											disabled={formik.isSubmitting}
										/> */}
										{formik.touched.address && formik.errors.address && (
											<FormHelperText error>
												{formik.errors.address}
											</FormHelperText>
										)}
									</Grid>
								)}
							</>
						)}
						<Grid size={12}>
							<FormLabel required>{'Title'}</FormLabel>
							<TextField
								fullWidth={true}
								name='title'
								{...formik.getFieldProps('title')}
								error={formik.touched.title && Boolean(formik.errors.title)}
								helperText={formik.touched.title && formik.errors.title}
								disabled={formik.isSubmitting}
								required
							/>
						</Grid>
						{((!deal && !client) ||
							Object.keys(eventTaskMsData ?? {}).length > 0 ||
							objectType === 'client') && (
							<Grid size={12}>
								<FormLabel>{'Associated client'}</FormLabel>
								<InstantClientSelect
									clientId={
										formik.values.client?.hs_object_id ??
										formik.values.client?.id
									}
									disabled={formik.isSubmitting}
									showContact={false}
									clientName={formik.values?.client?.name}
									setClient={(newClient) => {
										formik.setFieldValue('client', newClient);
										console.log('NEW CLIENT', newClient);
										if (newClient) {
											formik.setFieldValue('deal', null);
										}
									}}
								/>
							</Grid>
						)}

						{((!deal && !client) ||
							objectType === 'client' ||
							Object.keys(eventTaskMsData ?? {}).length > 0) && (
							<Grid size={12}>
								<FormLabel>{'Associated deal or claim'}</FormLabel>
								<InstantDealSelect
									disabled={formik.isSubmitting}
									clientId={
										formik.values.client?.hs_object_id ??
										formik.values.client?.id
									}
									dealId={formik.values.deal?.id}
									setDeal={(newDeal) => {
										formik.setFieldValue('deal', newDeal);
										if (newDeal) {
											formik.setFieldValue('objectType', newDeal.objectType);
											// formik.setFieldValue('client', null);
										}
									}}
									autoPopulate={true}
								/>
							</Grid>
						)}
						<Grid size={12}>
							<FormLabel>{'Assignee'}</FormLabel>
							<OwnerSelect
								initialId={formik.values.ownerId}
								disabled={formik.isSubmitting}
								onChange={(value) => {
									formik.setFieldValue('ownerId', value);
									const owner =
										brokers?.data?.filter((b) => b.id === value)[0] ?? null;
									const updatedAttendees = [...formik.values.attendees];
									if (owner && !updatedAttendees.includes(owner)) {
										updatedAttendees.push(owner);
									}
									formik.setFieldValue('attendees', updatedAttendees);
								}}
								showEmail={true}
							/>
						</Grid>
						<Grid size={12}>
							<FormLabel>{'Task Info'}</FormLabel>
							<TextField
								fullWidth={true}
								name='notes'
								multiline
								rows={4}
								{...formik.getFieldProps('notes')}
								// error={formik.touched.notes && Boolean(formik.errors.notes)}
								// helperText={formik.touched.notes && formik.errors.notes}
								disabled={formik.isSubmitting}
							/>
						</Grid>
					</Grid>
					{!loadingMsData && taskId && Object.keys(eventTaskMsData).length === 0 && (
						<FormHelperText>
							Could not find Outlook event info; some fields may not pre-populate
							correctly
						</FormHelperText>
					)}
					{formik.values.errorMessage.length > 0 && (
						<FormHelperText error>{formik.values.errorMessage}</FormHelperText>
					)}
					{/* <DevJSONView value={formik.values} /> */}
				</DialogContent>
				<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
					<Button
						onClick={() => {
							formik.resetForm();
							handleClose();
						}}
						color='primary'
					>
						Cancel
					</Button>
					<Button
						color='primary'
						type='submit'
						variant='contained'
						disabled={
							isLoadingTaskDocument ||
							isLoading ||
							loadingMsData ||
							formik.isSubmitting
						}
					>
						{formik.isSubmitting ? (
							<CircularProgress size='2em' sx={{ color: '#ffffff' }} />
						) : formik.values.isExistingTask ? (
							'Update'
						) : (
							'Create'
						)}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
