import { specialChars } from '@/utils/constants';
import * as Yup from 'yup';
export const FOLDER_NAME = 'folderName';
export const ERROR_MESSAGE = 'errorMessage';

// const validationSchema =  Yup.object().shape({
// 	[FOLDER_NAME]: Yup.string().required('Folder name is required.'),
	
// });

const validationSchema = (existingFolders = []) =>
	Yup.object().shape({
		[FOLDER_NAME]: Yup.string()
			.required('Folder name is required')
			.test(
				'no-special-characters',
				'Folder name contains invalid characters.',
				(value) => {
					if (!value) return false;
					return !specialChars.test(value.trim());
				}
			)
            .test(
				'no-end-in-fullstop',
				'Folder name cannot end with full stop.',
				(value) => {
					if (!value) return false;
					return !value.endsWith('.');
				}
			)
			.test(
				'unique-folder',
				'A folder with this name already exists.',
				(value) => {
					if (!value) return false;
					const folderExists = existingFolders.some(
						(folder) => (folder ?? '').toLowerCase() === value.toLowerCase().trim()
					);
					return !folderExists;
				}
			),
	});
  
export default validationSchema;