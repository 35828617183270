import React from 'react';
import { Navigate } from 'react-router-dom';
import { InvoicesPage } from '@/pages/InvoicesPage';
import { ErrorPage } from '@/pages/ErrorPage';
import { CreateNewBusinessPage } from '@/pages/CreateNewBusinessPage';
import { NewBusinessPage } from '@/pages/NewBusinessPage';
import ViewDealPage from '@/pages/ViewDealPage';
import { HomePage } from '@/pages/HomePage';
import { ClaimsPage } from '@/pages/ClaimsPage';
import ViewClaimPage from '@/pages/ViewClaimPage';
import { CreateClaimPage } from '@/pages/CreateClaimPage';
import { RenewalsPage } from '@/pages/RenewalsPage';
import { TasksPage } from '@/pages/TasksPage';
import ViewClientPage from '@/pages/ViewClientPage';
import { EndorsementsPage } from '@/pages/Endorsements';
import { CreateEndorsementPage } from '@/pages/CreateEndorsementPage';
import { CreateRenewalPage } from '@/pages/CreateRenewalPage';
import { ProspectsPage } from '@/pages/ProspectsPage';
import ViewContactPage from '@/pages/ViewContactPage';
import { IS_DEV } from '@/utils/environment';
import DealSOAFormPage from '@/pages/DealSOAFormPage';
import { PageNotFoundPage } from '@/pages/PageNotFoundPage';
import { AdvancedProfilePage } from '@/pages/AdvancedsProfilePage';

export const paths = {
	home: '/',
	invoices: '/invoices',
	newBusiness: '/new-business', 
	createNewBusiness: '/create-new-business',
	renewals: '/renewals',
	createRenewal: '/create-renewal',
	claims: '/claims',
	createClaim: '/create-claim',
	endorsements: '/endorsements',
	createEndorsement: '/create-endorsement',
	clients: '/clients',
	tasks: '/my-tasks',
	contacts: '/prospects',
	generateSOA: '/generate-soa',
	profile: '/profile-page'
};

const routes = [
	{
		path: paths.home,
		element: <HomePage/>,
		errorElement: <ErrorPage/>
	},
	{
		path: paths.invoices,
		element: <InvoicesPage/>,
		errorElement: <ErrorPage/>
	},
	{
		path: paths.createNewBusiness,
		element: <CreateNewBusinessPage/>,
		errorElement: <ErrorPage/>
	},
	{
		path: paths.newBusiness,
		element: <NewBusinessPage />,
		errorElement: <ErrorPage/>
	},
	{
		path: `${paths.newBusiness}/:dealId`,
		element: <ViewDealPage />,
		errorElement: <ErrorPage/>
	},
	{
		path: `${paths.newBusiness}/:dealId${paths.generateSOA}`,
		element: <DealSOAFormPage />,
		errorElement: <ErrorPage />
	},
	{
		path: paths.claims,
		element: <ClaimsPage />,
		errorElement: <ErrorPage/>
	},
	{
		path: paths.tasks,
		element: <TasksPage />,
		errorElement: <ErrorPage/>
	},
	{
		path: `${paths.claims}/:ticketId`,
		element: <ViewClaimPage />,
		errorElement: <ErrorPage/>
	},
	{
		path: paths.createClaim,
		element: <CreateClaimPage/>,
		errorElement: <ErrorPage/>
	},
	{
		path: paths.renewals,
		element: <RenewalsPage />,
		errorElement: <ErrorPage/>
	},
	{
		path: `${paths.renewals}/:ticketId`,
		element: <ViewDealPage />,
		errorElement: <ErrorPage/>
	},
	// {
	// 	path: `${paths.renewals}/:ticketId${paths.generateSOA}`,
	// 	element: <DealSOAFormPage />,
	// 	errorElement: <ErrorPage />
	// },
	{
		path: paths.createRenewal,
		element: <CreateRenewalPage />,
		errorElement: <ErrorPage/>
	},
	{
		path: paths.clients,
		element: <Navigate to="/" replace />,
		errorElement: <ErrorPage/>
	},
	{
		path: `${paths.clients}/:clientId`,
		element: <ViewClientPage />,
		errorElement: <ErrorPage/>
	},
	{
		path: paths.endorsements,
		element: <EndorsementsPage />,
		errorElement: <ErrorPage/>
	},
	{
		path: `${paths.endorsements}/:dealId`,
		element: IS_DEV ? <ViewClaimPage/> : <ViewDealPage />,
		errorElement: <ErrorPage/>
	},
	// {
	// 	path: `${paths.endorsements}/:dealId${paths.generateSOA}`,
	// 	element: <DealSOAFormPage />,
	// 	errorElement: <ErrorPage />
	// },
	{
		path: paths.createEndorsement,
		element: <CreateEndorsementPage/>,
		errorElement: <ErrorPage/>
	},
	{
		path: paths.contacts,
		element: <ProspectsPage />,
		errorElement: <ErrorPage/>
	},
	{
		path: `${paths.contacts}/:contactId`,
		element: <ViewContactPage />,
		errorElement: <ErrorPage/>
	},
	{
		path: paths.profile,
		element: <AdvancedProfilePage/>,
		errorElement: <ErrorPage/>
	},
	{
		path: '*',
		element: <PageNotFoundPage />,
		errorElement: <ErrorPage />
	},
];

export default routes;