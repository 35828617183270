/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useMemo, useEffect } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
	FormHelperText,
	TextField,
	FormLabel,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '@/features/dialog/dialogSlice';
import { showToast } from '@/features/toast/toastSlice';
import { useFormikHelper } from '@/hooks/useFormikHelper';
import { triggerRefresh } from '@/features/table/attachmentsTableSlice';
import { useAttachmentsContext } from '@/context/attachmentsContext';
import { specialChars } from '@/utils/constants';
import validationSchema from './validationSchema';
import { ERROR_MESSAGE } from '../AddFolderSharepointDialog/validationSchema';

const FOLDER_NAME = 'name';

export const RenameFileDialog = () => {
	const { handleRenameFile } = useAttachmentsContext();
	const { selectedRows } = useSelector((state) => state.attachmentsTable);
	const item = selectedRows[0];

	const itemType = item?.isFolder ? 'Folder' : 'File';

	const dispatch = useDispatch();

	const open = useSelector((state) => state.dialog.open['renameFile']);

	const updateFileName = async () => {
		const nameValue = formik.values[FOLDER_NAME];
		// const names = nameValue.split('.');

		// const cleanedName = (nameValue.includes('.') ? names.slice(0, -1).join('') : nameValue)
		// 	.replace('.', '')
		// 	.replace(specialChars, '')
		// 	.trim()
		// 	.slice(0, 250);

		// const type = !item?.isFolder ? `.${item?.type}` : '';
		// const newName = cleanedName.length > 0 ? `${cleanedName}${type}` : item?.name;

		const cleanedName = nameValue.trim().replace(specialChars, '').slice(0, 250);
		const type = !item?.isFolder && !cleanedName.endsWith(`.${item?.type}`) ? `.${item?.type}` : '';
		// const type = !oldRow.isFolder ? `.${item?.type}` : '';
            
		// const newName = cleanedName.length > 0 ? `${cleanedName}${type}` : item?.name;
		const newName = cleanedName.replace(type, '').length === 0 ? item?.name : `${cleanedName}${type}`;
            
		if (newName != item?.name) {
			await handleRenameFile(item, newName);
			return true;
		} else {
			return false;
		}
	};

	const initialValues = useMemo(
		() => ({
			[FOLDER_NAME]: item?.name ?? '',
			[ERROR_MESSAGE]: '',
		}),
		[item?.name]
	);

	const formik = useFormik({
		initialValues,
		validationSchema,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (values, { resetForm, setFieldValue, setSubmitting }) => {
			try {
				const renamed = await updateFileName();
				if (renamed) {
					dispatch(
						showToast({
							message: `${itemType} name updated!`,
							success: true,
						})
					);
					dispatch(triggerRefresh());
				}
				resetForm();
				handleClose();
			} catch (error) {
				console.log('error?.response?.data', error?.response?.data);
				const errorMessageString = error?.response?.data?.error?.message;
				const errorMessage =
					typeof errorMessageString === 'string'
						? errorMessageString
						: error?.response?.data?.error?.message?.value ?? '';
				console.log('🙅 ~ ERROR UPDATING METADATA', error);
				dispatch(
					showToast({
						message: `Failed to update ${itemType} name: ${errorMessage}`,
						error: true,
					})
				);
				setFieldValue(errorMessage);
			} finally {
				setSubmitting(false);
			}
		},
	});

	const handleClose = () => {
		formik.resetForm();
		dispatch(closeDialog('renameFile'));
	};

	const { getError, getErrorMessage } = useFormikHelper(formik);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			hideBackdrop={true}
			sx={{
				padding: '1em',
				'& .MuiDialog-paper': {
					width: '60%',
				},
			}}
		>
			<DialogTitle>{`Rename ${itemType}`}</DialogTitle>
			<DialogContent sx={{ paddingBottom: '2em' }}>
				<FormLabel required>Name</FormLabel>
				<TextField
					fullWidth
					multiline
					name={FOLDER_NAME}
					{...formik.getFieldProps(FOLDER_NAME)}
					error={getError(FOLDER_NAME)}
					helperText={getErrorMessage(FOLDER_NAME)}
					disabled={formik.isSubmitting}
					required
				/>

				{formik.values[ERROR_MESSAGE] && (
					<FormHelperText error>{formik.values[ERROR_MESSAGE]}</FormHelperText>
				)}

				<Typography
					variant='caption'
					sx={{
						display: 'block',
						mt: '1.5em',
						color: 'text.secondary',
					}}
				>
					<span>
						{`${itemType}s cannot contain the following characters:`}
						<span style={{ display: 'block' }}>{'< > : " \' /\\ | ? % *'}</span>
						{'or end in a .'}
					</span>
				</Typography>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
				<Button onClick={handleClose} disabled={formik.isSubmitting} color='primary'>
					Cancel
				</Button>
				<Button
					disabled={formik.values[FOLDER_NAME].length === 0}
					variant='contained'
					color='primary'
					onClick={() => formik.handleSubmit()}
				>
					{formik.isSubmitting ? (
						<CircularProgress size='2em' sx={{ color: '#FFFFFF' }} />
					) : (
						'Rename'
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
