import { specialChars } from '@/utils/constants';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.required('Name is required')
		.test('no-special-characters', 'Name contains invalid characters.', (value) => {
			if (!value) return false;
			return !specialChars.test(value.trim());
		})
		.test(
			'no-end-in-fullstop',
			'Name cannot end in .',
			(value) => {
				if (!value) return false;
				return !value.endsWith('.');
			}
		),
});

export default validationSchema;
