import { useCallback } from 'react';
import { storage } from '@/utils/firebase';
import { ref, uploadBytes } from 'firebase/storage';
import { useLazyGetDealRowQuery, useUpdateDealRowMutation } from '@/features/deals/dealsApi';
import {
	useLazyGetClientRowQuery,
	useUpdateClientRowMutation,
} from '@/features/clients/clientsApi';
import {
	folderStructure,
	getFolderFromPipeline,
	getFolderStructure,
	getPipelineName,
	replaceFolderName,
	formatDate,
} from '@/utils/constants';
import { currentYear, nextYear } from '@/utils/date';
import { useUpdateBrokerMutation } from '@/features/user/userApi';
import { useMsGraph } from './useMsGraph';
import { generatePDF } from '@/utils/pdf';

export const useClientStorageHooks = (userDetails) => {
	const [updateClientRow] = useUpdateClientRowMutation();
	const [updateBroker] = useUpdateBrokerMutation();
	const [updateDeal] = useUpdateDealRowMutation();
	const [triggerGetClientRow, clientRowQuery] = useLazyGetClientRowQuery();
	const [triggerGetDealRow, dealRowQuery] = useLazyGetDealRowQuery();

	const {
		getSharepointLists,
		getSharepointListItems,
		getSharepointListItemChildren,
		getDriveItem,
		getSharepointSiteDrives,
		querySharepointSites,
		getClientFolderDrive,
		getClientMsGraphFolders,
		searchClientMsGraphFolders,
		searchClientMsGraphFoldersByItemId,
		createSharepointFolder,
		deleteSharepointItem,
		moveSharepointItem,
		renameSharepointItem,
		addFileToSharepointMsGraph,
		addFileMsGraphSession,
		addMetadataToFile,
	} = useMsGraph();

	const isValidSharepoint =
		userDetails?.sharepointSite != null &&
		userDetails?.clientSiteSuffix != null &&
		userDetails?.clientFolder != null &&
		userDetails?.clientSite != null;

	const uploadFilesToStorage = async ({
		dealId,
		noteId,
		driveId,
		dealFolder,
		pipelineId,
		files,
	}) => {
		const dealType = getPipelineName(pipelineId);
		const dealDriveId = driveId ?? (await getDealDriveId(dealId));
		console.log('[uploadFilesToStorage]', {
			dealId,
			noteId,
			driveId,
			dealDriveId,
			dealFolder,
			pipelineId,
			files,
		});
		await Promise.all(
			files.map(async (file) => {
				await uploadClientSharepointFile(dealDriveId, file).catch((e) =>
					console.error('Error uploading file via Graph API', file, e)
				);

				const filePath = `/${dealType}Files/${file.name}`;
				const storageRef = ref(storage, filePath);
				const metadata = {
					customMetadata: {
						'Deal ID': dealId.toString(),
						'Note ID': noteId.toString(),
					},
				};
				await uploadBytes(storageRef, file, metadata);
			})
		).then((r) => {
			console.log(`Uploaded ${files.length} files`, files, r);
		});
		// for (const file of files) {
		// 	const filePath = `/${dealType}Files/${file.name}`;
		// 	const storageRef = ref(storage, filePath);
		// 	const metadata = {
		// 		customMetadata: {
		// 			'Deal ID': dealId.toString(),
		// 			'Note ID': noteId.toString(),
		// 		},
		// 	};
		// 	await uploadBytes(storageRef, file, metadata);
		// 	if (isValidSharepoint) {
		// 		await addFileToSharepoint(dealFolder.url, file);
		// 	} else {
		// 		console.log('Error, invalid Sharepoint', userDetails);
		// 	}
		// }
	};

	const getClientFolders = (clientName, dealName, dealId, pipelineFolder, date, yearFolder) => {
		// Normalise and remove special characters
		const replacedCompanyName = replaceFolderName(clientName);
		console.log('get client folders year folder: ', yearFolder);

		return getFolderStructure(
			replacedCompanyName,
			dealName,
			dealId,
			pipelineFolder,
			date,
			yearFolder
		);
	};

	const createFoldersFromNestedObject = async (driveId, rootItemId, folderStructure) => {
		const createdFolders = {}; // To keep track of created folders by their paths

		const createFolderRecursively = async (parentItemId, currentPath, structure) => {
			for (const folderName in structure) {
				try {
					const fullPath = currentPath ? `${currentPath}/${folderName}` : folderName;

					if (Object.keys(createdFolders).includes(fullPath)) {
						continue;
					}

					// Create the folder in SharePoint
					let folder;
					try {
						folder = await createSharepointFolder(
							driveId,
							parentItemId,
							folderName,
							true
						);
					} catch (err) {
						console.log('ERROR creating folder', err);
						continue;
					}

					const itemId = folder.id;
					createdFolders[fullPath] = folder;

					// Recursively create subfolders
					await createFolderRecursively(itemId, fullPath, structure[folderName]);
				} catch (error) {
					console.error(
						`❌ Error creating folder: ${folderName} at path: ${currentPath}`,
						error
					);
					throw error;
				}
			}
		};

		// Start the recursive folder creation from the root
		await createFolderRecursively(rootItemId, '', folderStructure);

		console.log('✅ All folders created successfully!', createdFolders);
		return createdFolders;
	};

	const getDealDriveId = async (dealId) => {
		let driveId;
		let dealRow;
		try {
			dealRow = await triggerGetDealRow(dealId).unwrap();
			console.log('DEAL ROW DATA', dealRow);
			driveId = dealRow?.driveId;
		} catch (e) {
			console.log('Error getting deal row', e);
		}

		if (!driveId) {
			try {
				driveId = await getDealFolderDrive(dealRow, userDetails?.clientFolderDriveId);
			} catch (e) {
				console.log('Error getting deal drive ID', e);
			}
			if (!driveId) {
				const { clientRow } = await getClientDriveId(dealRow?.clientHubspotId);
				const yearFolder = (dealRow?.yearRoot ?? `/${currentYear}-${nextYear}`).split('/');

				const { driveId: dealDriveId } = await createDealFolders({
					dealId,
					dealName: dealRow?.dealName?.trim(),
					clientId: clientRow?.hubspotId,
					clientName: clientRow?.name,
					pipelineId: dealRow?.pipeline,
					yearFolder: yearFolder[yearFolder.length - 1],
				});
				driveId = dealDriveId;
			}
		}
		return { dealRow, driveId };
	};

	const getClientDriveId = async (clientId) => {
		let driveId;
		let clientRow;
		console.log('getClientDriveId', { clientId });
		try {
			clientRow = await triggerGetClientRow(clientId).unwrap();
			console.log('CLIENT ROW DATA', { clientRow, driveId: clientRow?.driveId });
			driveId = clientRow?.driveId;
		} catch (e) {
			console.log('Error getting client row', e);
		}

		if (!driveId) {
			try {
				driveId = await getFolderDrive(clientRow, userDetails?.clientFolderDriveId);
			} catch (e) {
				console.log('Error getting client drive ID', e, clientRow);
			}
			if (!driveId) {
				const item = await createSharepointFolder(
					userDetails?.clientFolderDriveId,
					'root',
					replaceFolderName(clientRow?.name)
				);
				driveId = item.id;
				if (driveId) {
					await updateClientRow({
						clientId: clientRow?.hubspotId,
						docId: clientRow?.docId,
						properties: { driveId },
					}).unwrap();
				}
			}
		}
		return { clientRow, driveId };
	};

	const createDealFolders = async ({
		dealId,
		dealName,
		clientId,
		clientName,
		pipelineId,
		date,
		yearFolder,
	}) => {
		const pipelineFolder = getFolderFromPipeline(pipelineId);
		const { folders, dealFolder, clientSubFolders } = getClientFolders(
			clientName,
			dealName.trim(),
			dealId,
			pipelineFolder,
			date,
			yearFolder
		);

		if (isValidSharepoint) {
			console.log('FOLDERS TO CREATE', clientSubFolders, folders);

			const { driveId, clientRow } = await getClientDriveId(clientId);

			const folderItems = await createFoldersFromNestedObject(
				userDetails?.clientFolderDriveId,
				driveId,
				clientSubFolders
			);

			const dealKey = Object.keys(folderItems).find((f) => f.endsWith(dealId.toString()));
			const dealNotesKey = Object.keys(folderItems).find((f) => f.endsWith(`${dealId}/${folderStructure.notes}`));
			const clientNotesKey = Object.keys(folderItems).find((f) => f.includes(decodeURI(folderStructure.clientNotes.normalize())));
			
			const dealDriveId = folderItems[dealKey]?.id;
			const dealNotesDriveId = folderItems[dealNotesKey]?.id;
			const clientNotesDriveId = folderItems[clientNotesKey]?.id;

			console.log('FOLDER ITEMS', { folderItems, dealDriveId, dealNotesDriveId, clientNotesDriveId });

			const dealFolderUrl = folderItems[dealKey]?.webUrl; //await createFolder(dealFolder.url);

			const doc = await updateDeal({
				dealId,
				properties: {
					...(dealFolderUrl && { dealFolderUrl }),
					dealFolderPath: dealFolder.url,
					yearRoot: dealFolder.yearRoot,
					pipelineFolder: dealFolder.pipelineFolder,
					clientName,
					...(dealDriveId && { driveId: dealDriveId }),
					...(dealNotesDriveId && { notesDriveId: dealNotesDriveId }),
				},
			}).unwrap();

			if (clientNotesDriveId && !clientRow?.notesDriveId) {
				await updateClientRow({
					clientId,
					docId: clientRow?.docId,
					properties: { notesDriveId: clientNotesDriveId },
				}).unwrap().catch(e => console.log('Error updating client row', e));
			}

			return {
				success: 'Created client folders',
				dealFolder,
				dealFolderUrl,
				documentId: doc.id,
				driveId: dealDriveId,
				notesDriveId: dealNotesDriveId,
			};
		} else {
			console.log('Could not create client folders, invalid Sharepoint site', userDetails);
			return {
				error: 'Could not create client folders, invalid Sharepoint site',
				userDetails,
			};
		}
	};

	const setUpDealFilesAndFolders = async ({
		dealId,
		noteId,
		clientId,
		clientName,
		dealName,
		pipelineId,
		files,
		date,
		yearFolder,
		notes,
	}) => {
		console.log('[setUpDealFilesAndFolders] params ', {
			dealId,
			noteId,
			clientId,
			clientName,
			dealName,
			pipelineId,
			files,
			date,
			yearFolder,
			notes,
		});
		const { success, dealFolder, dealFolderUrl, documentId, driveId, notesDriveId } = await createDealFolders(
			{
				dealId,
				dealName: dealName.trim(),
				clientId,
				clientName,
				pipelineId,
				date,
				yearFolder,
			}
		);
		console.log('[setUpDealFilesAndFolders] createDealFolders response', {
			success,
			dealFolder,
			dealFolderUrl,
			documentId,
			driveId,
			notesDriveId
		});
		if ((files ?? []).length > 0) {
			await uploadFilesToStorage({ dealId, noteId, driveId, dealFolder, pipelineId, files });
		}
		if ((notes ?? '').trim().length > 0) {
			try {
				const date = new Date();
				const file = generatePDF(
					notes,
					`Created Note - ${formatDate(date, true).replace(':', '.')}`
				);

				console.log('Creating note', { file, notesDriveId });
				try {
					let noteDriveId = notesDriveId;
					if (!noteDriveId) {
						const path = decodeURI(folderStructure.notes.normalize());
						console.log('Path for notes', path);
						const drives = await searchClientMsGraphFoldersByItemId(
							userDetails?.clientFolderDriveId,
							driveId,
							path
						);
                        
						const drive = drives.find((d) => d?.folder && d?.name == path);
						noteDriveId = drive?.id;
						console.log('[searchClientGraphFolders] notes folders', { drives, drive, noteDriveId });
					}
					
					if (noteDriveId) {
						console.log('[uploadClientSharepointFile]', { noteDriveId, file });
						await uploadClientSharepointFile(noteDriveId, file).catch((e) =>
							console.error(
								'Error uploading generated pdf file via Graph API',
								file,
								e
							)
						);
					}
				} catch (error) {
					console.log('🙅 ~ Error creating file', error);
				}
				// await addFileToSharepoint(`${dealFolder.url}/Notes`, file);
			} catch (error) {
				console.log('🚀 ~ Error creating note pdf', error);
			}
		}
		return { success, dealFolder, dealFolderUrl, documentId, driveId };
	};

	const createClientFolder = useCallback(
		async (dealId, contextValue) => {
			const { deal, client, objectType, yearFolder } = contextValue;
			const date = deal.createdAt;

			console.log('Create client folder hook');

			if (!client || !deal) {
				console.log('Could not create folder: could not locate client/deal', dealId);
				return;
			}

			const dealName = deal.dealname ?? deal.description ?? deal.subject ?? deal.content;
			await setUpDealFilesAndFolders({
				dealId,
				clientName: client?.name,
				clientId: client?.id ?? client?.hs_object_id,
				dealName: dealName.trim(),
				pipelineId: deal.pipeline ?? deal.hs_pipeline,
				date,
				yearFolder,
			});
		},
		[setUpDealFilesAndFolders]
	);

	const getMatchingItem = (list, name) =>
		list.find(
			(listItem) =>
				listItem.name == name ||
				decodeURI((listItem.name ?? '').normalize()) ==
					decodeURI((name ?? '').normalize()) ||
				(listItem?.webUrl ?? '').toLowerCase().includes(name.toLowerCase())
		);

	const getSite = useCallback(
		async (query) => {
			const siteId = await querySharepointSites(query[query.length - 1]);
			console.log('SITE ID', siteId);
			if (siteId) {
				await updateBroker({
					docId: userDetails?.brokerId,
					properties: { sharepointSiteId: siteId },
				}).unwrap();
			}
		},
		[userDetails]
	);

	const getSiteFiles = useCallback(
		async (siteId) => {
			console.log('GET SITE FILES', siteId);
			const drives = await getSharepointSiteDrives(siteId);
			const drive = getMatchingItem(drives, userDetails?.clientFolder);
			console.log(
				'DRIVES',
				drives,
				'DRIVE',
				drive,
				'userDetails?.clientFolder',
				userDetails?.clientFolder
			);

			const lists = await getSharepointLists(userDetails?.sharepointSiteId);
			const list = getMatchingItem(lists, userDetails?.clientFolder);
			console.log('LISTS', lists, 'LIST', list);
			if (drive != null || list != null) {
				await updateBroker({
					docId: userDetails?.brokerId,
					properties: {
						...(drive && { clientFolderDriveId: drive.id }),
						...(list && { clientFolderListId: list.id }),
					},
				}).unwrap();
			}
		},
		[userDetails]
	);

	const updateUserGraphProperties = useCallback(async () => {
		console.log('USER DETAILS', userDetails);
		if (userDetails) {
			const siteQuery = (userDetails?.clientSiteSuffix ?? '').split('/');
			if (siteQuery.length > 0 && !userDetails?.sharepointSiteId) {
				getSite(siteQuery);
			} else if (userDetails?.sharepointSiteId) {
				if (!userDetails?.clientFolderDriveId || !userDetails?.clientFolderListId) {
					getSiteFiles(userDetails?.sharepointSiteId);
				}
			}
		}
	}, [userDetails]);

	const getFolderDrive = async (clientRow, driveId) => {
		const replacedCompanyName = replaceFolderName(clientRow?.name);
		// const filterFunction = (list) => {
		// 	return getMatchingItem(list, replacedCompanyName);
		// };
		const drives = await searchClientMsGraphFolders(driveId, replacedCompanyName);
		// const drives = await getClientFolderDrive(driveId, filterFunction);
		const drive = getMatchingItem(drives, replacedCompanyName);

		console.log(
			'CLIENT DRIVES',
			drives,
			'CLIENT DRIVE',
			drive,
			'FOLDER NAME',
			replacedCompanyName
		);
		if (drive) {
			await updateClientRow({
				clientId: clientRow?.hubspotId,
				docId: clientRow?.docId,
				properties: { driveId: drive.id },
			}).unwrap();
			return drive.id;
		}
	};

	const getDealFolderDrive = async (dealRow, driveId, clientDriveId) => {
		const path = (dealRow?.dealFolderPath ?? '').split('/');
		const pathName = dealRow?.dealFolderPath
			? path[path.length - 1]
			: `${dealRow?.dealName} ${dealRow?.dealId}`;
		console.log('GET DEAL FOLDER DRIVE', path, pathName);
		const drives = await searchClientMsGraphFolders(driveId, dealRow?.dealId);
		console.log('DEAL FOLDER DRIVES', drives);

		const drive = getMatchingItem(drives, pathName);

		console.log('DEAL DRIVE', drive);
		if (drive) {
			// dealDrive = drive;
			await updateDeal({
				dealId: dealRow.dealId,
				docId: dealRow.id,
				properties: { driveId: drive.id },
			}).unwrap();
			return drive.id;
		}
		// else {
		// 	Promise.all(
		// 		drives
		// 			.filter((d) => (d?.folder?.childCount ?? 0) > 0)
		// 			.map(async (d) => {
		// 				if (!dealDrive) {
		// 					await getDealFolderDrive(driveId, d.id);
		// 				}
		// 			})
		// 	);
		// }
	};

	const updateClientGraphProperties = useCallback(
		async (clientRow, dealRow) => {
			console.log('USER DETAILS', userDetails);
			if (userDetails && clientRow) {
				const getFolderList = async (siteId, listId) => {
					const replacedCompanyName = replaceFolderName(clientRow?.name);
					const filterFunction = (list) => {
						const newList = (list ?? []).filter(
							(l) => l.fields.ContentType === 'Folder'
						);
						console.log(
							`Filtered list fields ${replacedCompanyName}`,
							newList,
							newList.map((f) => f.fields?.FileLeafRef)
						);
						return newList.find(
							(l) =>
								l.fields.FileLeafRef == replacedCompanyName ||
								decodeURI(l.fields.FileLeafRef.normalize()) ==
									decodeURI(replacedCompanyName.normalize())
						);
					};
					const lists = await getSharepointListItems(siteId, listId, filterFunction);

					const list = filterFunction(lists);

					console.log('FOLDER LIST', list);
					if (list) {
						await updateClientRow({
							clientId: clientRow?.hubspotId,
							docId: clientRow?.docId,
							properties: { listId: list.id },
						}).unwrap();
					}
				};

				const getDealFolderList = async (siteId, listId) => {
					const path = dealRow?.dealFolderPath?.split('/');
					const filterFunction = (list) => {
						const newList = (list ?? []).filter(
							(l) => l.fields.ContentType === 'Folder'
						);
						console.log(
							`Filtered list fields ${path[path.length - 1]}`,
							newList,
							newList.map((f) => f.fields?.FileLeafRef)
						);
						return newList.find(
							(l) =>
								decodeURI(l.fields.FileLeafRef.normalize()) ==
								decodeURI(path[path.length - 1].normalize())
						);
					};
					const lists = await getSharepointListItems(siteId, listId, filterFunction);

					const list = filterFunction(lists);

					console.log('FOLDER LIST', list);
					if (list) {
						await updateDeal({
							dealId: dealRow.dealId,
							docId: dealRow.id,
							property: 'listId',
							value: list.id,
						}).unwrap();
					}
				};

				if (
					!userDetails?.sharepointSiteId ||
					!userDetails?.clientFolderDriveId ||
					!userDetails?.clientFolderListId
				) {
					updateUserGraphProperties();
				} else {
					if (userDetails?.clientFolderDriveId) {
						if (!clientRow.driveId) {
							getClientDriveId(clientRow?.hubspotId);
						} else if (dealRow && !dealRow.driveId) {
							getDealDriveId(dealRow?.dealId);
						}
						// if (!clientRow.listId) {
						// 	getFolderList(
						// 		userDetails?.sharepointSiteId,
						// 		userDetails?.clientFolderListId
						// 	);
						// }
					}
					// if (userDetails?.clientFolderListId) {
					// 	if (!clientRow.listId) {
					// 		getFolderList(
					// 			userDetails?.sharepointSiteId,
					// 			userDetails?.clientFolderListId
					// 		);
					// 	// } else if (dealRow && !dealRow?.listId && dealRow?.dealFolderPath) {
					// 	// 	getDealFolderList(
					// 	// 		userDetails?.sharepointSiteId,
					// 	// 		userDetails?.clientFolderListId
					// 	// 	);
					// 	}
					// }
				}
			}
		},
		[userDetails]
	);

	const getClientGraphFolderByItemId = useCallback(
		async (driveId, itemId) => {
			console.log('getClientGraphFolderByItemId driveId', driveId, itemId);
			const folders = [];
			const files = [];
			if (userDetails?.clientFolderDriveId != null && driveId != null) {
				const clientDrives = await getClientMsGraphFolders(driveId, itemId);

				console.log('CLIENT DRIVES', clientDrives);
				for (const drive of clientDrives ?? []) {
					Object.keys(drive).includes('file') ? files.push(drive) : folders.push(drive);
				}
			}
			return { folders, files };
		},
		[userDetails]
	);

	const getClientGraphItem = useCallback(
		async (driveId, itemId) => {
			console.log('getClientGraphItem driveId', driveId, itemId);

			if (userDetails?.clientFolderDriveId != null && driveId != null) {
				const item = await getDriveItem(driveId, itemId);
				console.log('GRAPH ITEM', item);
				return item;
			}
		},
		[userDetails]
	);

	const searchClientGraphFolders = useCallback(
		async (driveId, itemId, searchText = '') => {
			console.log('searchClientGraphFolders driveId', driveId, itemId, searchText);
			const folders = [];
			const files = [];
			if (
				userDetails?.clientFolderDriveId != null &&
				driveId != null &&
				searchText.length > 0
			) {
				const clientDrives = await searchClientMsGraphFoldersByItemId(
					driveId,
					itemId,
					searchText
				);

				console.log('SEARCHED CLIENT DRIVES', clientDrives);
				for (const drive of clientDrives ?? []) {
					Object.keys(drive).includes('file') ? files.push(drive) : folders.push(drive);
				}
			}
			return { folders, files };
		},
		[userDetails]
	);

	const getClientGraphFolders = useCallback(
		async (row, recursive = true) => {
			console.log('getClientGraphFolders row', row);

			const folders = [];
			const files = [];
			const getFolders = async (driveId, itemId) => {
				const clientDrives = await getClientMsGraphFolders(driveId, itemId);
				// console.log('CLIENT DRIVES', clientDrives, driveId, itemId);
				const notes = [
					decodeURI(folderStructure.notes.normalize()),
					decodeURI(folderStructure.clientNotes.normalize()),
				];
				// const filteredDrives = (clientDrives ?? []).filter(
				// 	(d) => !notes.includes(decodeURI((d?.name ?? '').normalize()))
				// );
				const filteredDrives = (clientDrives ?? []).filter((d) => {
					const name = (d?.name ?? '').normalize();
					try {
						return Object.keys(d).includes('file') || !notes.includes(decodeURI(name));
					} catch (err) {
						console.log(`Error decoding ${d.name}`, err);
						return Object.keys(d).includes('file') || !notes.includes(name);
					}
				});
				console.log('FILTERED DRIVES', filteredDrives);
				for (const drive of filteredDrives) {
					Object.keys(drive).includes('file') ? files.push(drive) : folders.push(drive);
					if ((drive.folder?.childCount ?? 0) > 0 && recursive) {
						await getFolders(driveId, drive.id);
					}
				}
			};
			if (userDetails?.clientFolderDriveId != null && row.driveId != null) {
				console.log(
					'HERE I GET THE FOLDERS',
					userDetails?.clientFolderDriveId,
					row.driveId
				);
				await getFolders(userDetails?.clientFolderDriveId, row.driveId);
				console.log('FOLDERS', folders, 'FILES', files);
			}
			return { folders, files };
		},
		[userDetails]
	);

	const createClientSharepointFolder = useCallback(
		async (driveId, itemId, folderName) => {
			console.log('createClientSharepointFolder driveId', driveId, itemId, folderName);

			if (userDetails?.clientFolderDriveId != null && driveId != null) {
				const response = await createSharepointFolder(
					userDetails?.clientFolderDriveId,
					itemId,
					folderName
				);
				console.log('createSharepointFolder', response);
				return response;
			}
		},
		[userDetails]
	);

	const deleteClientSharepointItem = useCallback(
		async (driveId, itemId) => {
			console.log('deleteClientSharepointItem driveId', driveId, itemId);

			if (userDetails?.clientFolderDriveId != null && driveId != null) {
				const response = await deleteSharepointItem(
					userDetails?.clientFolderDriveId,
					itemId
				);
				console.log('deleteClientSharepointItem', response);
				return response;
			}
		},
		[userDetails]
	);

	const moveClientSharepointItem = useCallback(
		async (driveId, itemId, newParentId) => {
			console.log('moveClientSharepointItem driveId', driveId, itemId, newParentId);

			if (userDetails?.clientFolderDriveId != null && driveId != null) {
				const response = await moveSharepointItem(
					userDetails?.clientFolderDriveId,
					itemId,
					newParentId
				);
				console.log('moveClientSharepointItem', response);
				return response;
			}
		},
		[userDetails]
	);

	const renameClientSharepointItem = useCallback(
		async (driveId, itemId, newName) => {
			console.log('renameClientSharepointItem driveId', driveId, itemId, newName);

			if (userDetails?.clientFolderDriveId != null && driveId != null) {
				const response = await renameSharepointItem(
					userDetails?.clientFolderDriveId,
					itemId,
					newName
				);
				console.log('renameClientSharepointItem', response);
				return response;
			}
		},
		[userDetails]
	);

	const updateClientSharepointItemMetadata = useCallback(
		async (driveId, itemId, metadata) => {
			console.log('updateClientSharepointItemMetadata ids', driveId, itemId, metadata);
			// userDetails?.clientFolderDriveId;
			if (userDetails?.clientFolderDriveId && driveId) {
				const response = await addMetadataToFile({ itemId, driveId, metadata });
				console.log('updateClientSharepointItemMetadata', response);
				return response;
			}
		},
		[userDetails]
	);

	const uploadClientSharepointFile = useCallback(
		async (driveId, file) => {
			console.log('uploadClientSharepointFile driveId', driveId, file);

			if (userDetails?.clientFolderDriveId != null && driveId != null) {
				const response = await addFileToSharepointMsGraph(
					userDetails?.clientFolderDriveId,
					driveId,
					file
				);

				console.log('uploadClientSharepointFile', response);
				return response;
			}
		},
		[userDetails]
	);

	return {
		createClientFolder,
		createDealFolders,
		uploadFilesToStorage,
		setUpDealFilesAndFolders,
		updateClientGraphProperties,
		getClientGraphFolders,
		getClientGraphFolderByItemId,
		searchClientGraphFolders,
		createClientSharepointFolder,
		deleteClientSharepointItem,
		moveClientSharepointItem,
		renameClientSharepointItem,
		uploadClientSharepointFile,
		updateClientSharepointItemMetadata,
		getClientGraphItem,
		getClientDriveId,
		getDealDriveId,
		updateUserGraphProperties,
	};
};
