/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Typography,
	CircularProgress,
	Box,
} from '@mui/material';

export const FileExistsDialog = ({ open, fileName, onClose, onCreateCopy }) => {
	const [loading, setLoading] = useState(false);

	const handleClose = (event, reason) => {
		if (reason && reason === 'backdropClick') return;
		onClose();
	};

	useEffect(() => {
		if (open) {
			setLoading(false);
		}
	}, [open]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			// hideBackdrop={true}
			sx={{ minWidth: '50%', padding: '1em' }}
		>
			<DialogTitle>File already exists</DialogTitle>
			<DialogContent sx={{ paddingBottom: '2em' }}>
				<Typography>{`A file with this name '${
					fileName ?? ''
				}' already exists. Would you like to create a copy or keep the original?`}</Typography>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: '#EFF0FF', padding: '1.5em' }}>
				<Button
					onClick={() => {
						setLoading(true);
						onCreateCopy();
					}}
					variant='contained'
					color='primary'
					disabled={loading}
				>
					{loading ? <Box sx={{ padding: '0 2em' }}><CircularProgress size='1em' color='white' /></Box> : 'Create Copy'}
				</Button>
				<Button variant='contained' color='primary' onClick={onClose} disabled={loading}>
					Keep Original
				</Button>
			</DialogActions>
		</Dialog>
	);
};
