import jsPDF from 'jspdf';

export const generatePDF = (content, fileName) => {
	const doc = new jsPDF({
		unit: 'mm',
		format: 'a4',
	});

	const pdfWidth = doc.internal.pageSize.width;
	const lineHeight = 6;
	const lines = content.split('\n');
	let yPosition = 15;
	lines.forEach((line, index) => {
		const textLines = doc.splitTextToSize(line, pdfWidth - 30);
		if (index === 0) {
			doc.text(textLines, 15, yPosition);
		} else {
			yPosition += lineHeight;
			doc.text(textLines, 15, yPosition);
		}
		yPosition += (textLines.length - 1) * lineHeight;
	});

	const fileContent = doc.output('arraybuffer');
	const fileBlob = new Blob([fileContent], { type: 'application/pdf' });
	
	const file = {
		name: `${fileName}.pdf`,
		content: fileBlob,
	};

	return file;
};