import React, { useState } from 'react';
import { 
	AppBar, 
	Toolbar, 
	IconButton, 
	Menu, 
	MenuItem, 
	Box, 
	Typography, 
	Avatar, 
	useTheme, 
	useMediaQuery 
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useMsal } from '@azure/msal-react';
import { toggleOpen } from '@/features/sideNav/sideNavSlice';  
import { useMSALHelper } from '@/hooks/useMSALHelper';
import EditProfileDrawer from '@/components/layouts/EditProfileDrawer';

export const AccountDropdownMenu = () => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { handleLogout } = useMSALHelper();
  
	// Redux or MSAL account
	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;

	// Query user details
	const userQuery = useGetUserDetailsQuery(
		{ email },
		{ skip: !email }
	);
	const brokerDetails = userQuery.data?.broker;

	// 2) Local state controlling EditProfileDrawer
	const [editProfileOpen, setEditProfileOpen] = useState(false);

	const handleDrawerToggle = () => {
		dispatch(toggleOpen());
	};

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	// 3) This function will open the EditProfileDrawer
	const handleProfile = () => {
		handleMenuClose();
		setEditProfileOpen(true);
	};

	const handleSignOut = () => {
		handleMenuClose();
		handleLogout();
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				position="static"
				style={{ background: 'white', boxShadow: 'none', borderBottom: '1px solid #D9DCFE' }}
			>
				<Toolbar variant="dense" sx={{ justifyContent: isMobile ? 'space-between' : 'flex-end' }}>
					{isMobile && (
						<IconButton
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{ mr: 2, display: { sm: 'none' } }}
						>
							<MenuIcon />
						</IconButton>
					)}

					{userQuery.data && (
						<div>
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
								{brokerDetails?.imageUrl ? (
									<Avatar
										sx={{ width: '1em', height: '1em', mr: 2, backgroundColor: '#34495E' }}
										src={brokerDetails?.imageUrl ?? ''}
									/>
								) : (
									<AccountCircle />
								)}
								<Typography variant="broker_header">
									{brokerDetails?.name || ' '}
								</Typography>
								<IconButton
									size="small"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleMenuOpen}
								>
									<KeyboardArrowDownIcon />
								</IconButton>
							</Box>

							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
								keepMounted
								transformOrigin={{ vertical: 'top', horizontal: 'right' }}
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}
							>
								{/* <MenuItem onClick={handleProfile}>Profile</MenuItem> */}
								<MenuItem onClick={handleSignOut}>Sign out</MenuItem>
							</Menu>
						</div>
					)}
				</Toolbar>
			</AppBar>
			<EditProfileDrawer
				title="Edit Profile"
				open={editProfileOpen}
				onClose={() => setEditProfileOpen(false)}
			/>
		</Box>
	);
};
