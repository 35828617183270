/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from 'react';
import {
	TextField,
	Skeleton,
	Typography,
	Stack,
	IconButton,
	Box,
	Button,
	Chip,
} from '@mui/material';
import { determineContext } from '@/hooks/determineContext';
import {
	dealsApi,
	useUpdateDealPropertiesMutation,
	useUpdateClientDealRowsMutation,
	useUpdateDealRowMutation,
} from '@/features/deals/dealsApi';
import { useUpdateTicketPropertiesMutation } from '@/features/claims/ticketsApi';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import {
	clientsApi,
	useCheckClientExistsMutation,
	useUpdateClientMutation,
} from '@/features/clients/clientsApi';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { folioBlue, replaceFolderName, specialChars } from '@/utils/constants';
import DoneIcon from '@mui/icons-material/Done';
import DealMenu from '@/components/DealMenu';
import { useGetFeatureFlagQuery } from '@/features/featureFlags/featureFlagsApi';
import { useClientStorageHooks } from '@/hooks/useClientStorageHooks';
import { triggerRefresh } from '@/features/table/attachmentsTableSlice';

export const DealNameUpdateField = ({ title }) => {
	const { deal, objectType, loading, dealRow, client } = determineContext();

	const isDeal = objectType === 'deal';
	const isClient = objectType === 'client';

	const featureFlagQuery = useGetFeatureFlagQuery({ feature: 'prospects' });
	const showProspects = useMemo(() => featureFlagQuery.data, [featureFlagQuery.data]);

	const isProspect = isClient && client?.clientRow?.isProspect;

	const loadingDeal = loading?.deal;
	const dispatch = useDispatch();

	const [newName, setNewName] = useState(deal?.dealname ?? deal?.subject ?? client?.name);
	const [displayClientExists, setDisplayClientExists] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const userQuery = useGetUserDetailsQuery({ email }, { skip: !email });
	const ownerId = useMemo(() => userQuery?.data.hubspotId, [userQuery?.data.hubspotId]);

	const [updateDealProperties, { isLoading: isUpdatingDeal }] = useUpdateDealPropertiesMutation();
	const [updateClientDealRows, { isLoading: isUpdatingDealRows }] =
		useUpdateClientDealRowsMutation();
	const [updateDealRow, { isLoading: isUpdatingDealRow }] = useUpdateDealRowMutation();
	const [updateTicketProperties, { isLoading: isUpdatingTicket }] =
		useUpdateTicketPropertiesMutation();
	const [updateClient, { isLoading: isUpdatingClient }] = useUpdateClientMutation();
	const [checkClientExists] = useCheckClientExistsMutation();

	// Used to invalidate the sharepoint folders
	const invalidateTags = () => {
		dispatch(triggerRefresh());
		setTimeout(async () => {
			try {
				if (deal || objectType === 'ticket') {
					dispatch(
						dealsApi.util.invalidateTags([
							{ type: 'DEAL_ROW', id: deal.hs_object_id ?? deal.id },
						])
					);
				}
				if (client) {
					dispatch(
						clientsApi.util.invalidateTags([
							{ type: 'CLIENT_ROW', id: client.hs_object_id ?? client.id },
						])
					);
				}
			} catch (error) {
				console.error('Error invalidating API:', error);
			}
		}, 5000);
	};

	const { renameClientSharepointItem } = useClientStorageHooks(userQuery.data);

	const handleUpdate = async () => {
		const trimmedName = newName.trim();

		if (title !== trimmedName && (objectType === 'deal' || objectType === 'ticket')) {
			try {
				const newFolderName = replaceFolderName(`${trimmedName} Deal ${dealRow?.dealId}`);
				const res = await renameClientSharepointItem(
					userQuery.data?.clientFolderDriveId,
					dealRow?.driveId,
					newFolderName
				);
				
				const webUrl = decodeURI((res?.webUrl ?? '').normalize());
				const urls = webUrl.split('/');
				const dealFolderPath =
					urls.length > 4 ? urls.slice(urls.length - 4).join('/') : null;
				const properties = {
					dealName: trimmedName,
					...(res?.webUrl && { dealFolderUrl: webUrl }),
					...(dealFolderPath && { dealFolderPath }),
				};
				await updateDealRow({ dealId: dealRow?.dealId, properties }).unwrap();
				const params = {
					[isDeal ? 'dealname' : 'subject']: trimmedName,
					[isDeal ? 'description' : 'content']: trimmedName,
				};
				if (isDeal) {
					await updateDealProperties({
						dealId: deal.hs_object_id,
						body: params,
					}).unwrap();
				} else {
					await updateTicketProperties({
						ticketId: deal.hs_object_id,
						body: params,
					}).unwrap();
				}
				invalidateTags();
			} catch (error) {
				console.error('Failed to update:', error);
			}
		} else if (title !== trimmedName && objectType === 'client') {
			try {
				const newFolderName = replaceFolderName(trimmedName);
				const clientExistsResponse = await checkClientExists({ ownerId, trimmedName });
				if (clientExistsResponse.data === false) {
					const clientId = client.hs_object_id;
					await updateClientDealRows({ clientId, trimmedName });
					const properties = { name: trimmedName };
					await updateClient({ clientId: client.hs_object_id, properties });
					await renameClientSharepointItem(
						userQuery.data?.clientFolderDriveId,
						client?.clientRow?.driveId,
						newFolderName
					);

					invalidateTags();
					setDisplayClientExists(false);
				} else if (clientExistsResponse.data === true) {
					setDisplayClientExists(true);
				}
			} catch (error) {
				console.error('Failed to update client:', error);
			}
		}
	};

	const isLoading =
		isUpdatingDeal || isUpdatingDealRows || isUpdatingTicket || isUpdatingClient || loadingDeal;

	const handleSaveClick = () => {
		handleUpdate();
		setIsEditing(false);
	};

	const handleCancelClick = () => {
		setNewName(deal?.dealname ?? deal?.subject ?? client?.name);
		setIsEditing(false);
	};

	return (
		<Stack direction='row' alignItems='center' spacing={1} width='100%'>
			{isLoading ? (
				<Skeleton animation='wave' width='100%' height='2em' />
			) : isEditing ? (
				<TextField
					variant='standard'
					className='minimal-input'
					value={newName}
					disabled={loadingDeal}
					onChange={(e) => setNewName(e.target.value)}
					fullWidth
					multiline
				/>
			) : (
				<Stack direction='column' spacing={0.5} width='100%'>
					{displayClientExists && (
						<Typography variant='caption' color='error'>
							Client already exists under your brokerage.
						</Typography>
					)}

					{isProspect && showProspects ? (
						<Stack
							direction='row'
							spacing={1}
							sx={{
								alignItems: 'center',
							}}
						>
							<Typography variant='deal_header'>{title}</Typography>
							<Chip
								size='small'
								label='PROSPECT'
								variant='outlined'
								sx={{ color: 'darkgray' }}
							/>
						</Stack>
					) : (
						<Typography variant='deal_header'>{title}</Typography>
					)}
				</Stack>
			)}
			{!isClient && (
				<>
					{isEditing ? (
						<IconButton
							sx={{ color: isEditing ? null : folioBlue, marginLeft: 5 }}
							aria-label='edit'
							disabled={isLoading}
							edge='start'
							onClick={() => setIsEditing(!isEditing)}
						>
							{isEditing ? <CloseIcon /> : <EditIcon />}
						</IconButton>
					) : (
						<DealMenu setIsEditing={setIsEditing} />
					)}
					{/* <IconButton
						sx={{ color: isEditing ? null : folioBlue, marginLeft: 5 }}
						aria-label="edit"
						disabled={isLoading}
						edge="start"
						onClick={() => setIsEditing(!isEditing)}
					>
						{isEditing ? <CloseIcon /> : <EditIcon />}
					</IconButton> */}
					{isEditing && (
						<IconButton
							sx={{ color: folioBlue }}
							onClick={handleSaveClick}
							disabled={loadingDeal}
						>
							<DoneIcon />
						</IconButton>
					)}
				</>
			)}
		</Stack>
	);
};
