/* eslint-disable indent */
import React, { useEffect }  from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { 
	TextField,
	InputAdornment,
	IconButton,
	Box
} from '@mui/material';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';

const SearchField = ({value, onChange, onClick, disabled, onReset, onKeyDown, helperText }) => {

	return (
		<TextField
			id="table-search-field"
			placeholder="Search"
			value={value}
			onChange={onChange}
			onKeyDown={onKeyDown}
			onBlur={onClick}
			disabled={disabled}
			helperText={helperText ?? 'Press enter to search'}
			fullWidth
			size="small"
			FormHelperTextProps={{ style: { color: 'rgba(0, 0, 0, 0.3)' } }}
			InputProps={{
				startAdornment: <InputAdornment position="start">
					<IconButton disabled={disabled} sx={{padding: '0'}} onClick={onClick}>
						<SearchIcon/>
					</IconButton>
				</InputAdornment>,
				endAdornment: <InputAdornment position="end">
					{value && value.length > 0 && <IconButton size="small" disabled={disabled} sx={{padding: '0'}} onClick={onReset}>
						<ClearIcon fontSize='20px'/>
					</IconButton>}
				</InputAdornment>,
			}}
            sx={{
                mt: '4px'
            }}
		/>
	);
};

const InstantSearchField = ({ query, isSearchStalled, refine, disabled }) => {
    const { refresh } = useInstantSearch();

    useEffect(() => {
        refresh();
    }, []);
    
	return (
		<TextField
			id="table-search-field"
			placeholder="Search"
			value={query}
			onChange={(event) => refine(event.currentTarget.value)}
			disabled={disabled}
			// helperText={'Press enter to search'}
			fullWidth
			size="small"
			// FormHelperTextProps={{ style: { color: 'rgba(0, 0, 0, 0.3)' } }}
			InputProps={{
				startAdornment: <InputAdornment position="start">
					<IconButton disabled={disabled} sx={{padding: '0'}}>
						<SearchIcon/>
					</IconButton>
				</InputAdornment>,
				endAdornment: <InputAdornment position="end">
					{query && query.length > 0 && <IconButton size="small" disabled={disabled} sx={{padding: '0'}} onClick={() => refine('')}>
						<ClearIcon fontSize='20px'/>
					</IconButton>}
				</InputAdornment>,
			}}
		/>
	);
};

export const InstantSearchFieldAdaptor = (props) => {
    const searchBoxApi = useSearchBox(props);
    return <InstantSearchField {...searchBoxApi} />;
};

export default SearchField;
