import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useBatchUpdateDealRowsMutation } from '@/features/deals/dealsApi';
import { ConfirmDialog } from '@/components/dialogs/ConfirmDialog';
import { DEAL_STATUS_TYPES } from '@/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '@/features/toast/toastSlice';
import { setSelected } from '@/features/table/dealsTableSlice';

export const ArchiveDealsButton = () => {
	const dispatch = useDispatch();

	const { selected } = useSelector((state) => state.dealTable);

	const [showDialog, setShowDialog] = useState(false);

	const [updateDealRows, { isLoading }] = useBatchUpdateDealRowsMutation();

	const archiveDeals = async () => {
		setShowDialog(false);
		const body = selected.map((d) => ({
			dealId: d.dealId,
			properties: {
				status: DEAL_STATUS_TYPES.archived,
				isRenewable: false,
			},
		}));
		await updateDealRows({ deals: body })
			.unwrap()
			.then((r) => {
				console.log('updateDealRows res', r);
				dispatch(
					showToast({
						message: `Successfully archived ${selected.length} deal${
							selected.length > 1 ? 's' : ''
						}.`,
						success: true,
					})
				);
				dispatch(setSelected([]));
				window.scrollTo(0, 0);
			})
			.catch((e) => {
				console.log('Error archiving deals', e);
				dispatch(
					showToast({
						message: 'Error archiving deals; please try again.',
						error: true,
					})
				);
			});
	};

	return (
		<>
			<Button
				variant='outlined'
				disabled={isLoading}
				sx={{ borderRadius: '15px' }}
				startIcon={<ArchiveIcon />}
				onClick={() => setShowDialog(true)}
			>
				{isLoading ? (
					<CircularProgress size={'1em'} />
				) : (
					<span>
						{'Archive'}
						{selected.length > 0 && (
							<span style={{ marginLeft: '0.25em' }}>{`(${selected.length})`}</span>
						)}
					</span>
				)}
			</Button>
			{showDialog && (
				<ConfirmDialog
					openDialog={showDialog}
					handleClose={() => setShowDialog(false)}
					handleConfirm={archiveDeals}
					actionName={`archive ${selected.length} deal${selected.length > 1 ? 's' : ''}`}
					canBeUndone={true}
					additionalContent={' Archiving a deal removes it from your Renewals list.'}
				/>
			)}
		</>
	);
};
