/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo } from 'react';
import {
	Box,
	Button,
	Divider,
	Grid,
	Grid2,
	Stack,
	Typography,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useGetFirebaseClaimsQuery } from '@/features/claims/ticketsApi';
import { useGetUserDetailsQuery } from '@/features/user/userApi';
import { useSelector } from 'react-redux';
import { useGetBrokerOwnersQuery } from '@/features/user/ownerApi';
import { formatDate } from '@/utils/constants';
import { useGetPipelinePropertiesQuery } from '@/features/pipelines/pipelineApi';
import { useBatchGetDealsTasksQuery } from '@/features/engagements/engagementsApi';

export const ExportButton = ({columns=[], tab}) => {

	const account = useSelector((state) => state.msalAccount.account);
	const email = account?.username;
	const { groupView, hubspotId: groupViewHubspotId } = useSelector((state) => state.userPrefs);
    
	const {
		data: userDetails,
		isLoading,
		isUninitialized,
	} = useGetUserDetailsQuery(
		{ email },
		{
			skip: !email,
		}
	);
	const hubspotId = userDetails?.hubspotId;
	const brokerGroupId = userDetails.brokerGroupArray ?? userDetails?.brokerId;
	console.log('brokerGroupIds', brokerGroupId);
	

	const ownerQuery = useGetBrokerOwnersQuery(hubspotId, {
		skip: !hubspotId,
	});
	
	const { data: claims } = useGetFirebaseClaimsQuery(
		{ brokerGroupId, tab, groupView, hubspotId }, 
		{ skip: !brokerGroupId || !tab || !hubspotId }
	);	

	const pipelineQuery = useGetPipelinePropertiesQuery(process.env.REACT_APP_PIPELINE_CLAIMS, {
		skip: !process.env.REACT_APP_PIPELINE_CLAIMS,
	});
	const stages = pipelineQuery?.data?.stages ?? {};

	const dealIds = useMemo(() => claims?.data.map((h) => h.dealId ?? h.hubspotId ?? h.id), [claims]);
	const tasksQuery = useBatchGetDealsTasksQuery(
		{ dealIds, objectType: 'ticket', nextTaskOnly: true },
		{ skip: !dealIds}
	);

	const handleExport = () => {
		const csvRows = [];
		const headers = columns.map((col) => col.label);
		csvRows.push(headers.join(',')); 
		// return formatDate(row?.dateOfLoss);
		claims.data.forEach((row) => {
			const values = columns.map((col) => {
				let value = '';
				const earliestTask = (tasksQuery.data ?? {})[row.hubspotId ?? row.dealId ?? row.id]?.properties;
				const next_task_date = earliestTask ? new Date(earliestTask?.hs_timestamp) : null;
				console.log('next task date: ', next_task_date);
				if (col.id === 'hubspot_owner_id') {
					const owner = ownerQuery?.data?.find((b) => b.id == row.ownerId);
					value = `${owner?.firstName} ${owner?.lastName}` || ''; 
				} else if (col.id === 'dateOfLoss') {
					value = formatDate(row?.dateOfLoss) || ''; 
				} else if (col.id === 'createDate') {
					value = formatDate(row?.createDate) || ''; 
				} else if (col.id === 'dealStage') {
					value = stages[row.dealStage]?.label;
				} else if (col.id === 'next_task_date') {
					const earliestTask = (tasksQuery.data ?? {})[row.hubspotId ?? row.dealId ?? row.id]?.properties;
					value = earliestTask ? new Date(earliestTask?.hs_timestamp) : '';
				} 
				else {
					value = row[col.id] ?? ''; 
				}
	
			
				return `"${String(value).replace(/"/g, '""')}"`;
			});
	
	
			csvRows.push(values.join(','));
		});
		const csvContent = csvRows.join('\n');
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const link = document.createElement('a');
		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', 'Claims_Data_Extract.csv');
			link.click();
		}
	};
	



	return (
		<Grid2>
			<Button variant='text' endIcon={<GetAppIcon />}  onClick={handleExport}>
								Export
			</Button>
		</Grid2>
	);
};